import React, { useState } from 'react'
import './Template.css'
import { ASSET_PREFIX_URL } from "../../../constants/config";
import { useParams } from 'react-router-dom';

function PersonTemplate({ onUpdate, data, onClose, containsAlphanumeric, fieldNameArray,
    noFieldNameError,defaultFieldsArray,uniqueError,
    startsWithAlphaError,uniqueFieldNameError }) {
    const [fieldName, setFieldName] = useState(data.fieldName);
    const [is_mandatory, setis_mandatory] = useState(data.is_mandatory);
    const [error, setError] = useState('')
    const { templateId } = useParams();
    const filteredFieldNameArray = fieldNameArray.filter((item) => item !== data.fieldName);
    const handleFieldNameChange = (event) => {
        setFieldName(event.target.value);
    };
    const handleCheckboxChange = (event) => {
        // Update the state with the new value of the checkbox
        setis_mandatory(event.target.checked);
    };
    // let containsAlphanumeric = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]+$/.test(fieldName);
    const handleUpdate = () => {


        if (!fieldName) {
            setError(noFieldNameError);

        } else if (!containsAlphanumeric.test(fieldName)) {
            setError(startsWithAlphaError);

        }else if (filteredFieldNameArray.includes(fieldName)) {
            setError(uniqueFieldNameError);
          }else if(defaultFieldsArray.includes(fieldName.toLowerCase()) || defaultFieldsArray.includes(fieldName.toUpperCase())){
            setError(uniqueError)
          } else {
            const updatedData = {
                template_id: templateId,
                inputType: 'person',
                is_default: false,
                is_mandatory:is_mandatory?is_mandatory:false,
                fieldName
            };
            onUpdate({ ...data, ...updatedData })

            onClose()
        }

    };
    const handleCancel = () => {
        onClose()
    }
    const isDisabled = !containsAlphanumeric.test(fieldName) || !fieldName || 
    (data.fieldName?filteredFieldNameArray.includes(fieldName):fieldNameArray.includes(fieldName))||
    defaultFieldsArray.includes(fieldName.toLowerCase()) || defaultFieldsArray.includes(fieldName.toUpperCase()) ;
    const buttonClass = isDisabled ? "btn-sv1" : "btn-sv2";
    return (
        <div className='template-text-box-container'>
            <div className='template-text-box-container-heading'>
                <span>Edit field</span>
            </div>
            <div className='template-text-box-container-mandatory-check'>
                <input type="checkbox" checked={is_mandatory}
                    onChange={handleCheckboxChange} />
                <span>Mandatory Field</span>
            </div>
            <div className='template-text-box-container-texttype'>
                <span>Field Name</span>
                <div className='template-text-box-container-field-input'>
                    <input type='text' placeholder='person field' onChange={handleFieldNameChange} value={fieldName} />
                </div>
                {error && <div className='error-message' style={{ color: 'red' }}>{error}</div>}
            </div>
            <div className='template-text-box-container-text-switches'>
                <span>Field Input</span>
                <div className='template-text-box-container-date'>
                    <img src={`${ASSET_PREFIX_URL}template-person-btn.svg`} alt="" width='24px' height='24px' />
                    <span>Only select person value is accepted</span>
                </div>
            </div>
            <div className="collab-members-btns1">
                <div className="collab-btns1">
                    <button className={buttonClass} onClick={handleUpdate}>
                        Save
                    </button>
                    <button className="btn-cl1" onClick={handleCancel}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default PersonTemplate
