import React, { useState } from 'react';
import Select from "react-select";

function PoNamingForm({ rightSectionItems,selectedValues, setSelectedValues }) {
    

    const handleSelectChange = (selected, meaning) => {
        setSelectedValues(prevValues => ({
            ...prevValues,
            [meaning]: selected.value,
        }));
      
        // You can also perform any additional actions here, such as updating a parent component or making an API call.
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: "292px",
            backgroundColor: "white",
            border: "1px solid #edf2f7",
            boxShadow: "none",
            color: 'black',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
        }),
        option: (provided, state) => ({
            ...provided,
            background: "white",
            color: "black",
            cursor: "pointer",
            ":hover": {
                background: "#610BEE",
                color: "white",
            },
        }),
        singleValue: (provided, state) => ({
            ...provided,
            backgroundColor: "transparent",
            color: "black",
            padding: "5px 10px",
            borderRadius: "4px",
            fontSize: "14px",
        }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: "14px",
            color: "black",
        }),
        menu: (provided) => ({
            ...provided,
            width: "292px",
            backgroundColor: "white",
            color: "black",
        }),
        menuList: (provided) => ({
            ...provided,
            backgroundColor: "white",
        }),
    };

    return (
        <div className='create-part-num-div-form-dynamic'>
            {rightSectionItems.filter(item => item.variable_selected_type === 'Fixed').map((item, index) => (
                <div key={index}>
                    <div className='create-part-num-div-form-label-input'>
                        <span>{item.meaning}*</span>
                        <div className='create-part-num-div-form-input'>
                            <Select
                                styles={customStyles}
                                options={item.variable_meaning.map(variableItem => ({
                                    value: variableItem.variable,
                                    label: variableItem.variable,
                                }))}
                                value={{
                                    value: selectedValues[item.meaning] || item.default_value,
                                    label: selectedValues[item.meaning] || item.default_value
                                }}
                                onChange={selected => handleSelectChange(selected, item.meaning)}
                            />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default PoNamingForm;
