import React from 'react'
import './TemplateConfirmPopUp.css'
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from 'react-router-dom';
import { TKTTEMPLATE } from '../../../constants/config';

function NoTemplatePopUp({onclose}) {
    const handleclose=()=>{
        onclose()
    }
    const nav=useNavigate();
    const handleNavigateTo=()=>{
        nav(TKTTEMPLATE)
    }
  return (

 <div className='default-popup'>
 <div className='default-popup-container'>
     <div className='closing-div'>
     <div onClick={handleclose} className='default-closing'>
         <CloseIcon />
     </div>
     </div>
    
     <div className='default-message'>
         <span>No template has been created yet. Please create ticket template.</span>


     </div>
     <div className='default-btns'>
     <a href='/template'><button >Create Template</button></a> 
     </div>
 </div>
</div>
  )
}

export default NoTemplatePopUp