import React from 'react'
import { useDrag, useDrop } from 'react-dnd';

const DraggableItem = ({ icon, label, item }) => {
    const [{ isDragging }, dragRef] = useDrag({
      type: "FORM_ELEMENT", // Custom item type for form elements
      item,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    });
  
    return (
      <div
        ref={dragRef}
        className="template-field"
        style={{ opacity: isDragging ? 1 : 3 }}
      >
        <div className="template-field-text">
          <img src={icon} alt={label} width="24px" height="24px" />
          <span>{label}</span>
        </div>
      </div>
    );
  };

export default DraggableItem