import React, { useState, useEffect } from "react";
import "../DepartmentPages/Department.css";
import "./TicketList.css";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import axios from "axios";
import { ASSET_PREFIX_URL, BASE_URL, DEVIATIONVIEW, ECTEMPLATE } from "../../constants/config";
import Cookies from "js-cookie";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import DepartmentTicketPopup from "../DepartmentPages/DepartmentTicketPopup";
import Loading from "../Common/Loading/Loading";
import TicketTitlePopUp from "../Common/CommonJsx/TitlePopUp";
import { useTour } from "@reactour/tour";
import NoTemplatePopUp from "../TicketComponent/TemplateConfirm/NoTemplatePopUp";
import NameProfile from "../Common/CommonJsx/NameProfile";
import EcPageForm from "../EcPages/EcPageForm";
import Select from "react-select";
import DateFormateForList from "../Common/CommonJsx/DateFormateForList";
import Pagination from "../Common/CommonJsx/Pagination";
import { debounce } from "@mui/material";
import SearchFilter from "../Common/CommonJsx/SearchFilter";
import { toast } from "react-toastify";

function AllDeviations({ type }) {
  const itemsPerPage = 10;
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [length, setLength] = useState(0);
  const [departmentList, setDepartmentList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupFormOpen, setIsPopupFormOpen] = useState(false);
  const [ticketPopUp, setTicketPopUp] = useState(false);
  const [ticketTitle, setTicketTitle] = useState("");
  const [resetButton, setResetButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noTemplate, setNoTemplate] = useState(false);
  const [hasTemplate, setHasTemplate] = useState(false);
  // Check local storage for tour completion status
  // const isTourCompleted = localStorage.getItem('tourCompleted');
  const { setIsOpen, setSteps, isOpen, setCurrentStep } = useTour();
  useEffect(() => {
    // Reset filters when type changes
    setSelectedStatus(null);
    setSearchTerm("");
  }, [type]);
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleNextClick = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };
  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption.value);
    setResetButton(true)
  };
  const handleReset = async () => {

    // setDepartmentId('')

    setSelectedStatus(null)
    setSearchTerm('')
    // setPartAction('')
    setResetButton(false)


    // setReset(false)
  };
  const handlePreviousClick = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleDone = () => {
    setIsOpen(false);
    Cookies.set("ECTourCompleted", true, { expires: 365 });
  };

  const ticketSteps = [
    {
      selector: '[data-tour="step-8"]',
      content: (
        <div>
          <p>Create your new EC</p>
          {/* <p>
                Create your new ticket by choosing department and fill the fields
              </p> */}
          <div className="tour-btns">
            <button onClick={() => handleNextClick()} className="tour-next">
              Next
            </button>
          </div>
        </div>
      ),
    },
    {
      selector: '[data-tour="step-9"]',
      content: (
        <div>
          <p>Click to view EC</p>
          {/* <p>Click to view tickets assigned to you</p> */}
          <div className="tour-btns">
            <button onClick={() => handlePreviousClick()} className="tour-back">
              Back
            </button>
            <button onClick={() => handleDone()} className="tour-next">
              Done
            </button>
          </div>
        </div>
      ),
    },
  ];
  const emptyTicketSteps = [
    {
      selector: '[data-tour="step-8"]',
      content: (
        <div>
          <p>Create your new EC</p>

          {/* <p>
                Create your new ticket by choosing department and fill the fields
              </p> */}
          <div className="tour-btns">
            <button onClick={() => handlePreviousClick()} className="tour-back">
              Back
            </button>
            <button onClick={() => handleDone()} className="tour-next">
              Done
            </button>
          </div>
        </div>
      ),
    },
  ];

  const [hasOpened, setHasOpened] = useState(false);
  // const {member_id}=useParams();

  const nav = useNavigate();
  const { id } = useParams();

  const handeleclose = () => {
    setNoTemplate(false);
  };
  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setIsPopupFormOpen(false);
  };
  const handleTitleClick = (event, department) => {
    event.stopPropagation();
    setTicketPopUp(true);
    setTicketTitle(department.data.defaultFieldsData.deviation_title.value);
  };

  const HandleTitleClose = () => {
    setTicketPopUp(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setResetButton(true)
  };

  const allTickets = async () => {

    try {
      setLoading(true);
      const headers = {
        "x-auth-token": localStorage.getItem("token"),
      };
      let response,
        endPoint;

      if (type === "ec-template") {
        endPoint = "ec-template";
      } if (type === "ec-deviation") {
        endPoint = "ec-deviations";
      }
      response = await axios.get(
        `${BASE_URL}/v1/${endPoint}/get-dev-ticket`,

        {
          params: {
            page: currentPage,
            limit: itemsPerPage,
            search: searchTerm,
            selected_status: selectedStatus,
          },
          headers: headers,
        }
      );
      const details = response.data.data.ticket_details;
      console.log(response.data.data.ticket_details)
      const paginationDetails = response.data.data.pagination;
      console.log(details);
      setHasTemplate(response.data.data.eng_change_template)
      setLength(details.length);

      setDepartmentList(details);
      setTotalPages(paginationDetails.totalPages);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleOpenEcForm = () => {
    if (!hasTemplate) {
      toast.info('Please create template for EC')
      nav(ECTEMPLATE)

    } else {
      setIsPopupFormOpen(!isPopupFormOpen);
    }

  };

  useEffect(() => {
    if (searchTerm !== "" || selectedStatus) {
      debounce(allTickets())
    } else {
      allTickets();
    }
  }, [currentPage, itemsPerPage, type, searchTerm, selectedStatus]);



  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleTicketView = (itemId) => {

    nav(DEVIATIONVIEW(itemId));

  };

  const handleHoverTour = () => {
    const tourCompleted = localStorage.getItem("ECTourCompleted");
    if (!tourCompleted) {
      setIsOpen(true);
      setSteps(length > 0 ? ticketSteps : emptyTicketSteps);

      setHasOpened(true);
    }
    if (tourCompleted === true) {
      setIsOpen(false);
    }

    // Check if the tour is closing after being opened
    if (!isOpen && hasOpened) {
      setIsOpen(false);

      Cookies.set("ECTourCompleted", true, { expires: 365 });
    }
  };

  const statusOptions = ['Draft', 'Pending Approval ', 'Released'];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "30px",
      width: "200px",
      border: "1px solid #EDF2F7",
      padding: "0px 15px",
      boxSizing: "border-box",
      height: "40px",
    }),
  };

  return (
    <>
      {/* <TicketTemplateTopNav/> */}

      <>
        <>
          <div
            className="depart-main"
            style={{ marginTop: "80px" }}
            onMouseEnter={handleHoverTour}
          >
            <>
              {" "}
              <div className="spn-depart">
                {id ? (
                  <>
                    <div className="no-ticket-view-right">
                      {/* <ArrowBackIcon onClick={handleBack} style={{ cursor: 'pointer' }} />
                            <span>Programs / </span> */}
                      <span style={{ color: "#610BEE" }}>EC</span>
                      <span> Ticket Progress</span>
                    </div>
                    {/* <div className='no-ticket-progress'>
                    Ticket Progress
                  </div> */}
                  </>
                ) : (
                  <>
                    <span>
                      {type === "ec-template" && "ECs"}
                      {type === "ec-deviation" && "Deviations"}
                    </span>
                  </>
                )}

                {/* {length < 1 ? (
                        ""
                      ) : (
                        <> */}

                <button
                  className="tkt-btn"
                  onClick={handleOpenEcForm}
                  data-tour="step-8"
                >
                  <AddOutlinedIcon />

                  {type === "ec-template" && "New EC"}
                  {type === "ec-deviation" && "New deviation"}
                  {/* New Ticket */}
                  {/* {type === 'template' ? 'New Ticket' : 'New EC Ticket'} */}
                </button>
                {/* </>
                      )} */}
              </div>
            </>

            <div className="tck-fltr">
              {/* {length < 1 ? (
                  ""
                ) : (
                  <> */}

              <SearchFilter handleSearchChange={handleSearchChange} searchTerm={searchTerm} listType='deviation' />
              <Select
                styles={customStyles}
                placeholder={selectedStatus === null ? "status" : ""}
                options={statusOptions.map((option) => ({
                  value: option,
                  label: option,
                }))}
                onChange={handleStatusChange}
                value={selectedStatus ? { value: selectedStatus, label: selectedStatus } : null}
              // onChange={(selectedOption) => setSelectedDepartment(selectedOption)}
              />
              {resetButton && <button
                className="reset-btn"
                onClick={handleReset}
                data-tour="step-11"
              >
                reset <TuneOutlinedIcon />
              </button>}
              {/* </>
                )} */}

              {/* {id ? '' : <> */}
            </div>

            <div className="department-table">
              <table className="department-table-data">
                <thead>
                  <tr>
                    <th className="id-header">ID</th>
                    <th className="depart-header">Title </th>
                    {/* <th className="lead-header">owner</th> */}
                    <th className="tck-header">Assignee</th>
                    {/* <th className="tck-header">Created on</th> */}
                    <th className="tck-header">Due Date</th>
                    <th className="tck-header">Approval status</th>
                  </tr>
                </thead>
                {loading ? <Loading /> : <tbody>
                  {departmentList.map((department, item) => (
                    <tr
                      key={item}
                      onClick={() =>
                        handleTicketView(department.sequence_id)
                      }
                      style={{ cursor: "pointer" }}
                      data-tour="step-9"
                    >
                      <td>{department.sequence_id}</td>

                      <td title={department.data.defaultFieldsData.deviation_title.value}>
                        {department.data.defaultFieldsData.deviation_title.value.length > 30 ? (
                          <>
                            {department.data.defaultFieldsData.deviation_title.value.slice(0, 30)}
                            ...
                            <button className="view-more-text" onClick={(event) => handleTitleClick(event, department)}>
                              view
                            </button>
                          </>
                        ) : (
                          department.data.defaultFieldsData.deviation_title.value
                        )}
                      </td>





                      <td>
                        <div
                          className="member-option"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <NameProfile
                            userName={
                              department.data.defaultFieldsData.assignee
                                .value.fullName
                            }
                            memberPhoto={
                              department.data.defaultFieldsData.assignee
                                .value.photo
                            }
                            width="26px"
                            fontweight="500"
                          />

                          <span className="member-fullname">
                            {
                              department.data.defaultFieldsData.assignee
                                .value.fullName
                            }
                          </span>
                        </div>
                      </td>


                      <td>
                        <div
                          className="member-option"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <span className="member-fullname">

                            <DateFormateForList formateTime={department.data.defaultFieldsData.due_date.value} />
                          </span>
                        </div>
                      </td>
                      <td>
                        <div
                          className="member-option"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <span className="security-icon1">
                            {/* {department.approval_status === 'reject' && <img src={`${ASSET_PREFIX_URL}rejected.png`} />} */}
                            {department.approval_status ===
                              "Released" && (
                                <img
                                  src={`${ASSET_PREFIX_URL}approved.png`}
                                />
                              )}
                            {department.approval_status ===
                              "Draft" && (
                                <span> Draft </span>
                              )}
                            {department.approval_status === "Pending Approval" && (
                              <img
                                src={`${ASSET_PREFIX_URL}pending.png`}
                              />
                            )}
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>}

              </table>
            </div>



            {(totalPages > 1 && !loading)  && (
              <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
            )}

          </div>
          {isPopupOpen && (
            <DepartmentTicketPopup onClose={handleClosePopup} type={type} />
          )}

          {ticketPopUp && (
            <TicketTitlePopUp
              header='Deviation title' onClose={HandleTitleClose}
              ticketTitle={ticketTitle}
            />
          )}
          {isPopupFormOpen &&

            <EcPageForm onClose={handleOpenEcForm} templateType={type} />
          }
          {noTemplate && <NoTemplatePopUp onclose={handeleclose} />}
        </>
      </>
    </>
  );
}
export default AllDeviations