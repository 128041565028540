import React, { useState, useEffect } from "react";
import "./Edit.css";
import CloseIcon from "@mui/icons-material/Close";
import Select from "react-select";
import axios from "axios";
import { BASE_URL, PHOTO_LINK, ASSET_PREFIX_URL, HEADERS } from "../../../constants/config";
import Cookies from "js-cookie";
import NameProfile from "../CommonJsx/NameProfile";

function AddMember({ activeNode, setAction, action,setUpdatedData }) {
  console.log(action)
  const [close, setClose] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [jobTitle, setJobTitle] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});


  const handleOnchange = (selected) => {
    setJobTitle(selected.designation);
    setSelectedOption(selected)
    // setSelectedEntityId(selected.designation)
  }

  useEffect(() => {
    fetchData();
  }, [selectedOption]);



  const fetchData = async () => {
    try {
      const headers = {
        'x-auth-token': localStorage.getItem("token")
      };
      const response = await axios.get(BASE_URL + "/v1/org/getmember-details",
        { headers: headers });
      const data = response.data.data.arr;
      console.log(data.map((i) => i.designation));
      data.sort((a, b) => a.fullName.localeCompare(b.fullName));
      setOptions(data);
    } catch (error) {
      console.error("Error fetching options data:", error);
    }
  };

  const handleClose = () => {
    setClose(true);
    setAction(false)
  };

  const handleAddMember = async () => {
    // Reset form submission status and validation errors
    setFormSubmitted(true);
    setValidationErrors({});

    // Validate inputs
    if (!jobTitle.trim()) {
      setValidationErrors(prevErrors => ({ ...prevErrors, jobTitle: "Job Title is required." }));
      return;
    }
    if (!selectedOption) {
      setValidationErrors(prevErrors => ({ ...prevErrors, selectedOption: "Please select an employee." }));
      return;
    }

    try {
      const selectedEntityId = selectedOption ? selectedOption._id : "";
      await axios.post(BASE_URL + "/v1/org/update-hierarchy", {
        entity_id: selectedEntityId,
        parent_entity_id: activeNode.entity_id,
        is_sibling: true,
        job_title: jobTitle,
        entity_type: action === 'add_mem' ? "member" : "assistant",
        action: 'add',
      },
        {
          headers: HEADERS
        });
        setUpdatedData(selectedEntityId)
        setAction(false)
    } catch (error) {
      console.error(error.message);

    }
  };

  const filterOptions = (candidate, input) => {
    if (!input) {
      return true;
    }

    const inputValue = input.toLowerCase().trim();
    const fullName = candidate.data.fullName.toLowerCase();
    return fullName.includes(inputValue);
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,

      overflowY: "auto",
      overflow: "hidden", // Disable the default browser scrollbar
    }),
    menuList: (provided) => ({
      ...provided,
      "::-webkit-scrollbar": "auto",
      "::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "8px",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? "transparent" : "white", // Set the background color
      color: state.isSelected ? "black" : "inherit", // Set the text color
    }),
  };

  return (
    <div className="editRole" style={{ display: close ? "none" : "block" }}>
      <div className="upper-part">
        <div className="cancel">
          <span>Add  {action === 'add_mem' ? "member" : "assistant"}</span>
          <CloseIcon onClick={handleClose} />
        </div>


        <div className="emp">
          <span>Employee</span>
          <Select
            id="mySelect1"
            options={options}
            menuPlacement="auto"
            styles={customStyles}
            getOptionLabel={(option) => (
              <div className="select-photo">

                <NameProfile userName={option.fullName} padding='5px ' width='25px' memberPhoto={option.photo} />



                {option.fullName}&nbsp;
                ({option.email.length > 12 ? `${option.email.slice(0, 18)}...` : option.email})
                {/* ({option.email}) */}

              </div>
            )}
            onChange={(selected) => handleOnchange(selected)}
            filterOption={filterOptions} // Update the selected option
          />
          {formSubmitted && validationErrors.selectedOption && (
            <div className="department-error"><img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />&nbsp;&nbsp;&nbsp;{validationErrors.selectedOption}</div>
          )}
        </div>
        <div className="editJob">
          <span>Job title</span>
          <input
            type="text"
            placeholder="Enter Title"
            onChange={(e) => setJobTitle(e.target.value)}
            value={jobTitle}
          />
          {/* Display validation error for jobTitle */}
          {formSubmitted && validationErrors.jobTitle && (
            <div className="department-error"><img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />&nbsp;&nbsp;&nbsp;{validationErrors.jobTitle}</div>
          )}
        </div>
        {/*<div className="emp-sibling">*/}
        {/*  <span>Sibiling</span>*/}
        {/*  <Select*/}
        {/*    id="#mySelect"*/}
        {/*    options={SibilingOptions}*/}
        {/*    value={sibling !== "" ? SibilingOptions.find(option => option.value === sibling) : null}*/}
        {/*    onChange={handleSelectChange}*/}
        {/*  />*/}
        {/*  /!* Display validation error for sibling *!/*/}
        {/*  {formSubmitted && validationErrors.sibling && (*/}
        {/*    <div className="department-error"><img src={`${ASSET_PREFIX_URL}warning.svg`} alt=""/>&nbsp;&nbsp;&nbsp;{validationErrors.sibling}</div>*/}
        {/*  )}*/}
        {/*</div>*/}
      </div>

      <div className="btn-bottom">
        {(!jobTitle || !selectedOption) ? <button onClick={handleAddMember} className="submit-edit-errorbutton">Save</button> :
          <button onClick={handleAddMember} className="submit-edit-button">Save</button>}

        <button onClick={handleClose} className="cancel-edit-button">Cancel</button>
      </div>
    </div>
  );
}

export default AddMember;
