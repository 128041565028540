import React, { useEffect, useState, useRef,useCallback } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import "./PartNumberView.css";
import {

    BASE_URL,
    PARTVIEW
} from "../../constants/config";
import Loading from "../Common/Loading/Loading";
import { textLettersLimit } from "../../constants/helper";
import SearchFilter from "../Common/CommonJsx/SearchFilter";
import { debounce } from 'lodash';

function PartNumbersTabPage({ partNumber, shouldEdit,updatedDetails,
    setUpdatedDetails }) {
    const [hasMore, setHasMore] = useState(true);
    const [fetching, setFetching] = useState(false);
    const [partOptions, setPartOptions] = useState([])
    const [search, setSearch] = useState('');
    const [selectedParts, setSelectedParts] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [error, setError] = useState('');
    const [quantityUpdate, setQuantityUpdate] = useState(false);
    const containerRef = useRef(null);
    const LIMIT = 20;

    
    const debouncedSearch = useCallback(
        debounce((value) => {
          setSearch(value);
        }, 10),
        []
      );
    const handleSearch = (e) => {
      
        debouncedSearch(e.target.value);
        if (e.target.value !== '') {
            setFetching(true)
            setPartOptions([])

            // setActivePage(1); // Reset active page to 1 when searching
            // Call the function to fetch data based on search query
            setActivePage(1);
            //   getPartNumberChildList() 

        } else {
            setFetching(true)
            setPartOptions([])

            //   getPartNumberChildParts()
        }
    };
    const getPartNumberChildList = async () => {
        try {
            setFetching(true)
            const headers = {
                "x-auth-token": localStorage.getItem("token"),
            };
            const listofPartNumber = await axios.get(
                `${BASE_URL}/v1/part-nomen/get-list`,
                {
                    params:
                        { search: search, size: LIMIT, page: activePage, partNumber },
                    headers: headers,
                }
            );
            // setPartOptions(listofPartNumber.data.data.part_number_list);
            // if (listofPartNumber.data.data.non_existing_parts.length > LIMIT) {
            //     setPartOptions((prev) => {
            //         return [
            //             ...new Set([...prev, ...listofPartNumber.data.data.non_existing_parts]),
            //         ];
            //     }); 
            // } else {
            //     setPartOptions(listofPartNumber.data.data.non_existing_parts);
            // }
            if (activePage !== 1) {
                setPartOptions((prev) => {
                    return [
                        ...new Set([...prev, ...listofPartNumber.data.data.non_existing_parts]),
                    ];
                });
            } else {
                setPartOptions((prev) => {
                    // Filter out duplicate child parts before adding
                    const newParts = listofPartNumber.data.data.non_existing_parts.filter(
                        (newPart) => !prev.some((existingPart) => existingPart.part_number === newPart.part_number)
                    );
                    return [...prev, ...newParts];
                });
            }

            // setPartOptions(listofPartNumber.data.data.all_child_parts);
            setHasMore(listofPartNumber.data.data.non_existing_parts.length === LIMIT);
            //   console.log(listofPartNumber.data.data.part_number_list);
            // setOptionsCount(listofPartNumber.data.data.count_part_list)
            //   setPartNumbers(listofPartNumber.data.data.part_number_list)

            //   setTotalPages(listofPartNumber.data.data.total_pages);
            // setActivePage(search !== '' && activePage + 1);
            setFetching(false)
        } catch (error) {
            console.log(error);
        }
    };
    const getPartNumberChildParts = async () => {
        try {
            setFetching(true)
            const headers = {
                "x-auth-token": localStorage.getItem("token"),
            };
            const listofPartNumber = await axios.get(
                `${BASE_URL}/v1/part-nomen/get-all-children`,
                {
                    params: {
                        partNumber: partNumber,
                        page: activePage, // Pass activePage here
                        size: LIMIT,
                    },
                    headers: headers,
                }
            );
            // setPartOptions((prev) => {
            //   return [
            //     ...new Set([...prev, ...listofPartNumber.data.data.all_child_parts]),
            //   ];
            // });
            if (activePage !== 1) {
                setPartOptions((prev) => {
                    return [
                        ...new Set([...prev, ...listofPartNumber.data.data.all_child_parts]),
                    ];
                });
            } else {
                setPartOptions((prev) => {
                    // Filter out duplicate child parts before adding
                    const newParts = listofPartNumber.data.data.all_child_parts.filter(
                        (newPart) => !prev.some((existingPart) => existingPart.part_number === newPart.part_number)
                    );
                    return [...prev, ...newParts];
                });
            }

            // setPartOptions(listofPartNumber.data.data.all_child_parts);
            // setPartOptions(listofPartNumber.data.data.all_child_parts);
            setHasMore(listofPartNumber.data.data.all_child_parts.length === LIMIT);
            // Increment activePage only after successful response
            setFetching(false)
        } catch (error) {
            console.log(error);
        }
    };
    // Update parts when the active page or search query changes
    useEffect(() => {
        if (search !== '') {
           
            getPartNumberChildList()
        } else {
            getPartNumberChildParts();
        }
    }, [activePage, search,updatedDetails]);

    // const debounce = (func, delay) => {
    //     let timeoutId;
    //     return (...args) => {
    //         clearTimeout(timeoutId);
    //         timeoutId = setTimeout(() => {
    //             func.apply(this, args);
    //         }, delay);
    //     };
    // };
    const handleScroll = debounce(() => {
        const container = containerRef.current;
        if (!hasMore || fetching) {

            return;
        }


        if (container.scrollTop + container.clientHeight >= container.scrollHeight - 20) {
            setActivePage(prevPage => prevPage + 1);

        }
    }, 500);

    useEffect(() => {
        const container = containerRef.current;

        if (container) {
            container.addEventListener('scroll', handleScroll);

            return () => {
                container.removeEventListener('scroll', handleScroll);
            };
        }
    }, [containerRef, handleScroll]);


    const handleUpdateDeleteParts = async (action) => {
        const updatedParts = selectedParts.map(({ part_number, quantity, part_title }) => ({
            part_number,
            quantity,
            part_title
        }));

        console.log(updatedParts);
        console.log(action)
        try {
            const headers = {
                "x-auth-token": localStorage.getItem("token"),
            };
            const getpartsDetails = await axios.post(
                `${BASE_URL}/v1/part-nomen/update-child-parts`,
                {
                    part_number: partNumber,
                    part_data: updatedParts, // Pass selected ECs here
                    action
                },
                { headers: headers }
            );
          
           
      
                setUpdatedDetails(updatedParts)

            // Handle response or do something with getpartsDetails if needed
        } catch (error) {
            console.log(error);
        }
    };

    const handleQuantityChange = (e, partId) => {

        setQuantityUpdate(true)
        const newQuantity = e.target.value;
        // Update the partOptions array with the new quantity
        setPartOptions((prevPartOptions) =>
            prevPartOptions.map((part) =>
                part.part_number === partId ? { ...part, quantity: newQuantity } : part
            )
        );
        // setError('')
    };
    return (
        <div style={{ width: '100%', boxSizing: 'border-box' }} >
            {shouldEdit &&
             
             <SearchFilter searchTerm={search} handleSearchChange={handleSearch} listType='part number to add...'/>
            }

            {/* {fetching ? <Loading smallScreen={true} /> : */}
                <>

                    <div className="part-number-parts-divs" ref={containerRef} onScroll={handleScroll}>
                        {partOptions.length === 0 ? <div className='data-not-found-img'>
                            <span>Parts not found!</span>
                        </div> : <>
                            {partOptions.map((item, index) => (
                                <div key={item.part_id} className="part-numbers-views">
                                    <div className="part-numbers-views-details">
                                        <div style={{ display: 'flex', gap: '15px' }}>
                                            {shouldEdit && (
                                                <div className="part-numbers-views-checkbox">
                                                   <input
                                                    type="checkbox"
                                                    checked={selectedParts.some((part) => part.part_number === item.part_number)}
                                                    onChange={(e) => {
                                                        const isChecked = e.target.checked;
                                                        if (item.quantity) {
                                                            if (isChecked) {
                                                                setSelectedParts([...selectedParts, item]);
                                                            } else {
                                                                setSelectedParts(selectedParts.filter((part) => part.part_number !== item.part_number));
                                                            }
                                                            setError('');  // Clear error if present
                                                        } else {
                                                            setError("Please enter quantity for the part before selecting.");
                                                            e.target.checked = false;  // Prevent selecting without quantity
                                                        }
                                                    }}
                                                />
                                                </div>
                                            )}



                                            <div className="part-numbers-views-content">
                                                <a href={PARTVIEW(item.part_number)} target='_blank' className='link-to-view-ec'>
                                                {textLettersLimit(item.part_number,24)}</a>
                                                {/* <span className="part-number-view-number">{item.part_number}</span> */}
                                                <span className="part-number-view-title">
                                                    {textLettersLimit(item.part_title,30)}
                                                </span>
                                            </div>
                                        </div>

                                        <div>
                                            <input type="number" value={item.quantity}
                                                placeholder="+Qty"
                                                onChange={(e) => handleQuantityChange(e, item.part_number)}
                                                disabled={shouldEdit && selectedParts.some(part => part.part_number === item.part_number)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>}



                    </div>
                    <>
                        {error !== '' && <span className="error-message">{error}</span>}
                        {shouldEdit && (
                            <>
                                {search !== '' && (
                                    <button
                                        className="part-view-delete-parts"
                                        onClick={() => handleUpdateDeleteParts('update')}
                                        disabled={selectedParts.length < 1}
                                    >
                                        Add
                                    </button>
                                )}
                                {(search === '' && !quantityUpdate) && (
                                    <button
                                        className="part-view-delete-parts"
                                        onClick={() => handleUpdateDeleteParts('remove')}
                                        disabled={selectedParts.length < 1}
                                    >
                                        Delete
                                    </button>
                                )}
                                {search === '' && quantityUpdate && (
                                    <button
                                        className="part-view-delete-parts"
                                        onClick={() => handleUpdateDeleteParts('quantity')}
                                        disabled={selectedParts.length < 1}
                                    >
                                        Update quantity
                                    </button>
                                )}
                                <div>{/* Content here */}</div>
                            </>
                        )}
                    </>

                </>

            {/* } */}


        </div>
    )
}

export default PartNumbersTabPage