import React from 'react'
import CallMadeOutlinedIcon from '@mui/icons-material/CallMadeOutlined';
import { ASSET_PREFIX_URL, ORGHOME } from './../../constants/config';

function DepartmentHome() {
  return (
    <>
    <div className="create-tkt">
      <div className="tkt">
        <img src={`${ASSET_PREFIX_URL}department.svg`} alt="ticket-tempalte" />
        <span>
        No Department added!
          <br /> Add new departments in org charts
        </span>
      </div>
      <div>
        <a href={ORGHOME} style={{textDecoration: 'none'}}>
        <button className="tkt-btn" >
          Go to org charts
          <CallMadeOutlinedIcon />
        </button>
        </a>

      </div>
    </div>
  </>
  )
}

export default DepartmentHome
