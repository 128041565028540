
import axios from "axios";
import moment from 'moment-timezone';
import { BUCKET, BASE_URL } from "./config";
// debounce.js
export const debounce = (func, delay = 500) => {
  let debounceTimer;

  return function (...args) {
    const context = this;

    // Clear the timer if it's already running
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    // Set a new timer
    debounceTimer = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
};




export const debounceScroll = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};


export const makeByCodeOptions = [
  { value: 'in_house_production', label: 'In-house production' },
  { value: 'subcontract', label: 'Subcontract' },
  { value: 'purchased', label: 'Purchased' },
  { value: 'phantom_kit', label: 'Phantom / Kit' },
  { value: 'software_firmware', label: 'Software / Firmware' },
];


export const textLettersLimit = (text, limitType) => {

  if (typeof text !== 'string' || typeof limitType !== 'number') {
    return text;
  }

  if (text.length > limitType) {
    return `${text.substring(0, limitType)}...`;
  }

  return text;
};


export async function simpleUpload(data, file) {
  const result = await axios.put(data.url, file, {
    headers: {
      "Content-Type": file.type,
    },
  });
}

export const formatDateToIST = (dateString) => {
  return moment.utc(dateString)  // Parse input as UTC
    .tz('Asia/Kolkata')          // Convert to IST
    .format('DD MMM YYYY, hh:mm A');  // Format as "22 Oct 2024, 11:38 AM"
};


export async function multiUpload(data, file, headers) {
  const parts = [];

  for (let i = 0; i < data.total_parts; i++) {
    const start = i * data.part_size;
    const end = Math.min(start + data.part_size, file.size);
    const part = file.slice(start, end);

    console.log(`Uploading part ${i + 1}/${data.total_parts}`);
    console.log('Part size:', part.size);

    parts.push(uploadPart(i, part, data, file));
  }

  try {
    // Wait for all parts to upload
    const uploadedParts = await Promise.all(parts);
    console.log(uploadedParts);
    await completeMultipartUpload(data, uploadedParts, headers);
    console.log('All parts uploaded successfully');
  } catch (error) {
    console.error('Error uploading parts:', error);
    throw error;
  }
}

const completeMultipartUpload = async (data, parts, headers) => {
  try {
    const file = {
      key: data.key,
      upload_id: data.upload_id,
      parts: parts
    }
    const preSignedURL = await axios.post(
      `${BASE_URL}/v1/member/get-presigned-url`,
      { bucket_name: BUCKET, file, category: 'complete_mutipart' },
      { headers: headers }
    );
    if (
      preSignedURL.data.meta.code === 200 &&
      preSignedURL.data.meta.message === "SUCCESS"
    ) {
      return true;
    }
  } catch (error) {
    console.error('Error completing multipart upload:', error);
  }
};


// Upload each part using pre-signed URLs
const uploadPart = async (partNumber, part, data, file) => {
  try {
    const { url } = data.url[partNumber]; // Use partNumber to get the correct URL
    console.log(`Uploading part ${partNumber + 1} to ${url}`);

    const result = await axios.put(url, part, {
      headers: {
        "Content-Type": file.type,
      },
    });
    console.log('Response Headers:', result.headers);
    const etag = result.headers.get('etag') || result.headers.get('ETag');
    console.log(`Part ${partNumber + 1} uploaded successfully`, etag);
    return { ETag: etag, PartNumber: partNumber + 1 };
  } catch (error) {
    console.error(`Error uploading part ${partNumber + 1}:`, error);
    throw error;
  }
};
