import React, { useState } from 'react'
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import "./PopUp.css";
import { BASE_URL, HEADERS, LOGIN } from "../../../../constants/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

function ChangePassPopUp({toastStyle}) {
  const [close, setClose] = useState(false);
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewpassword, setConfirmNewPassword] = useState('')
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [ShowNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const nav = useNavigate()

 

  const togglePasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!ShowNewPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword);
  };
  const handleClose = () => {
    setClose(true);
  };

  const handleOldPassword = (e) => {
    setOldPassword(e.target.value)
  }
  const handlenewPassword = (e) => {
    setNewPassword(e.target.value)
  }
  const handleConfirmNewPassword = (e) => {
    setConfirmNewPassword(e.target.value)
  }


  const HandleChangePassword = async (item) => {

    try {
      const headers = HEADERS
      const response = await axios.post(BASE_URL + "/v1/setting/change-password", {
        oldPassword: oldPassword,
        newPassword: newPassword,
        confirmNewPassword: confirmNewpassword,
      },
        { headers: headers });
      if (response.data.meta.success === false) {
        // console.log(response.data.meta.message)
        toast.error(response.data.meta.message, toastStyle);
      } else {
        Cookies.remove('token');
        localStorage.clear();
        window.location.href = LOGIN;

        // window.location.reload();
      }
      // console.log(response)
      // nav('/login')

    } catch (error) {
      console.error("Error fetching options data:", error);
      alert(error)
    }
  };


  return (
    <div className='change-pass-page' style={{ display: close ? "none" : "block" }}>
      <div className='change-pass-container'>
        <div className='change-pass-head'>
          <span>Change password</span>
        </div>
        <div className='change-pass-input'>
          <div className='change-pass-label'>
            <span>Old password</span>
          </div>
          <div className='change-pass-input-cont'>
            <input placeholder='Enter password' type={showOldPassword ? 'text' : 'password'} onChange={handleOldPassword} />
            <div onClick={togglePasswordVisibility}>
              {showOldPassword ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
            </div>

          </div>
        </div>
        <div className='change-new-pass-input'>
          <div className='change-pass-label'>
            <span>New password</span>
          </div>
          <div className='change-pass-input-cont'>
            <input placeholder='Enter password' type={ShowNewPassword ? 'text' : 'password'} onChange={handlenewPassword} />
            <div onClick={toggleNewPasswordVisibility}>
              {ShowNewPassword ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
            </div>
          </div>
        </div>
        <div className='change-new-pass-1-input'>
          <div className='change-pass-label'>
            <span>Confirm new password</span>
          </div>
          <div className='change-pass-input-cont'>
            <input placeholder='Enter password' type={showConfirmNewPassword ? 'text' : 'password'} onChange={handleConfirmNewPassword} />
            <div onClick={toggleConfirmPasswordVisibility}>
              {showConfirmNewPassword ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
            </div>
          </div>
        </div>

        <div className='change-pass-btns'>
          <button className='chnage-pass-update' onClick={HandleChangePassword}>Update</button>
          <button className='change-pass-cancel' onClick={handleClose}>Cancel</button>
        </div>
      </div>
    </div>
  )
}

export default ChangePassPopUp
