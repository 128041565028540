import React, { useState } from 'react';
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import BreadcrumbsNav from '../Common/BreadcrumbsNav';
import {  LOCATIONLIST } from '../../constants/config';
import CustomTabs from '../Common/CustomTabs';
import { useParams } from 'react-router-dom';
import CreateLocation from '../LocationPages/CreateLocation'
import LocationParts from './LocationParts';
import LocationDetails from './LocationDetails';

function LocationView() {
    const [selectedSection, setSelectedSection] = useState(0);
    const [openCreateLocation, setOpenCreateLocation] = useState(false)
    const { sequence_id } = useParams()

   
    return (
        <>
            <div className="depart-main" style={{ marginTop: "80px" }}>
                <div className="part-number-list-title">
                    <div>
                        <BreadcrumbsNav
                            links={[
                                { label: "Locations", href: LOCATIONLIST },
                            ]}
                            current={sequence_id}
                        />
                    </div>
                    <div className="part-number-list-btns">
                        <button onClick={() => setOpenCreateLocation(!openCreateLocation)}>
                            <AddOutlinedIcon />
                            Create new location
                        </button>
                    </div>
                </div>

              
                <div className='supplier-page-bottom-page' style={{ padding: '8px' }}>
                    <div className='workspace-detail-tab'>
                        <CustomTabs
                            tabLabels={[ "Parts", "Location details"]}
                            currentTab={selectedSection}
                            setCurrentTab={setSelectedSection}
                            panels={[
                                
                                () => <LocationParts  sequence_id={sequence_id}/>,
                                () => <LocationDetails sequence_id={sequence_id}/>,
                            ]}
                        />
                    </div>
                </div>
            </div>
            {openCreateLocation && <CreateLocation handleClose={() => setOpenCreateLocation(!openCreateLocation)}/>}
        </>

    );
}

export default LocationView;
