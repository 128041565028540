import React, { useRef } from "react";
import "./Reviews.css";
import { ASSET_PREFIX_URL } from "../../../constants/config";

function Reviews() {
  const scrollRef = useRef(null);
  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft -= 300; // Adjust the scroll distance as needed
    }
  };
  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += 300; // Adjust the scroll distance as needed
    }
  };
  const array = [
    {
      photo: "Marathon",
      name: "Marathon",
      role: "Marathon",
      company: "Marathon",
      description: "Marathon",
    },
    {
      photo: "Marathon",
      name: "Marathon",
      role: "Marathon",
      company: "Marathon",
      description: "Marathon",
    },
    {
      photo: "Marathon",
      name: "Marathon",
      role: "Marathon",
      company: "Marathon",
      description: "Marathon",
    },
    {
      photo: "Marathon",
      name: "Marathon",
      role: "Marathon",
      company: "Marathon",
      description: "Marathon",
    },
    {
      photo: "Marathon",
      name: "Marathon",
      role: "Marathon",
      company: "Marathon",
      description: "Marathon",
    },
    {
      photo: "Marathon",
      name: "Marathon",
      role: "Marathon",
      company: "Marathon",
      description: "Marathon",
    },
  ];
  return (
    <div className="reviews-page">
      <div className="reviews-page-title">
        <span>Professionals use Marathon to get more done, faster.</span>
        <div className="reviews-page-btns">
          <div onClick={scrollLeft}>
            
            <img src={`${ASSET_PREFIX_URL}left-review.webp`} alt="" />
          </div>
          <div onClick={scrollRight}>
            <img src={`${ASSET_PREFIX_URL}right-review.webp`} alt="" />
          </div>
        </div>
      </div>
      <div className="reviews-page-cards" ref={scrollRef}>
        {array.map((i) => (
          <div className="reviews-cards-container">
            <div className="reviews-cards-top">
              <span className="reviews-cards-top-1">“</span>
              <span className="reviews-cards-top-2">
                I’m very optimistic about Rewind’s approach to personalized AI.
              </span>
            </div>
            <div className="reviews-cards-bottom">
              <span className="reviews-cards-bottom1">Sam Altman</span>
              <span className="reviews-cards-bottom2"> CEO, OpenAI</span>
            </div>
            <div className="reviews-cards-img"></div>
          </div>
        ))}
      </div>
      <div className="reviews-page-bottom">
        <button>As seen in</button>
        <img src={`${ASSET_PREFIX_URL}reviews1.webp`} alt="" className="review1"/>
        <img src={`${ASSET_PREFIX_URL}review2.webp`} alt="" className="review2"/>
        <img src={`${ASSET_PREFIX_URL}review3.webp`} alt="" className="review3"/>
      </div>
    </div>
  );
}

export default Reviews;
