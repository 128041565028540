import React, { useState } from 'react';
import Select from "react-select";
import axios from "axios";
import NameProfile from './CommonJsx/NameProfile';
import { BASE_URL, HEADERS, PHOTO_LINK } from '../../constants/config';

function MemberDropdown({ field, handlePersonSelectChange, formData, disable }) {
    const [allMembers, setAllMembers] = useState([])
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);

    const fetchMembers = async (pageNumber = 1) => {
        if (loading || !hasMore) return;
        setLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/v1/setting/get-mem`, {
                headers: HEADERS,
                params: {
                    page: pageNumber,
                    limit: 10
                }
            });

            const newMembers = response.data.data.memberList;

            if (newMembers.length === 0) {
                setHasMore(false);
            } else {
                setAllMembers(prevMembers => {
                    const existingMemberIds = new Set(prevMembers.map(member => member._id));
                    const filteredNewMembers = newMembers.filter(member => !existingMemberIds.has(member._id));
                    return [...prevMembers, ...filteredNewMembers];
                });
                setPage(prevPage => prevPage + 1);
            }
        } catch (error) {
            console.error("Error fetching members:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleMenuScrollToBottom = () => {
        if (hasMore) {
            fetchMembers(page);
        }
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            maxWidth: "369px",
            minWidth: "150px",
            backgroundColor: "transparent",
            border: "1px solid #EDF2F7",
            boxShadow: "none",
        }),
        menu: (provided) => ({
            ...provided,
            maxWidth: "369px",
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
        }),
        option: (provided, state) => ({
            ...provided,
            background: state.isSelected ? "transparent" : "white",
            color: state.isSelected ? "black" : "inherit",
            cursor: "pointer",
            ":hover": {
                background: "lightgray",
            },
        }),
    };

    let selectedValue;

    if (field.value) {
        if (field.fieldName === "Watchers" || field.inputType === 'multi_person') {
            selectedValue = (field.value || formData[field.fieldName.replace(/\s+/g, '_').toLowerCase()] && formData[field.fieldName.replace(/\s+/g, '_').toLowerCase()]).map((option) => ({
                value: option.member_id,
                label: (
                    <div style={{ display: 'flex', alignItems: 'center' }} className="option-label">
                        {option.photo ? (
                            <img
                                src={`${PHOTO_LINK}${option.photo}`}
                                alt="options"
                                className="owner-image"
                            />
                        ) : (
                            <div className="owner-image-span">
                                <NameProfile userName={option.fullName} />
                            </div>
                        )}
                        {option.fullName.length < 15 ? option.fullName : `${option.fullName.slice(0, 12)}...`}
                        <span style={{ display: 'none' }}>{option.member_id}</span>
                    </div>
                ),
            }));
        } else {
            const selectedOption = (field.value || (formData[field.fieldName.replace(/\s+/g, '_').toLowerCase()] && formData[field.fieldName.replace(/\s+/g, '_').toLowerCase()]));
            selectedValue = {
                value: selectedOption.member_id,
                label: (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                        }}
                    >
                        {selectedOption.photo ? (
                            <img
                                src={`${PHOTO_LINK}${selectedOption.photo}`}
                                alt="options"
                                className="owner-image"
                            />
                        ) : (
                            <div className="owner-image-span">
                                <NameProfile userName={selectedOption.fullName} />
                            </div>
                        )}
                        {selectedOption.fullName.length < 15
                            ? selectedOption.fullName
                            : `${selectedOption.fullName.slice(0, 15)}...`}
                    </div>
                ),
            };
        }
    } else {
        selectedValue = formData[field.fieldName] && formData[field.fieldName]._id;
    }




    return (
        <Select
            placeholder={field.placeholder}
            styles={customStyles}
            isMulti={field.fieldName === "Watchers" || field.inputType === 'multi_person'}
            options={allMembers.map((member) => ({
                value: member._id,
                label: (
                    <div
                        className="member-option"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                        }}
                    >
                        <NameProfile userName={member.fullName} width='26px' memberPhoto={member.photo} fontweight='500' />
                        {member.fullName}
                    </div>
                ),
            }))}
            onChange={(selectedOption) => {

                handlePersonSelectChange(field.value ? field.fieldName.replace(/\s+/g, '_').toLowerCase() : field.fieldName, selectedOption)

            }}
            value={selectedValue}
            onFocus={() => fetchMembers(1)}
            onMenuScrollToBottom={handleMenuScrollToBottom}
            isDisabled={disable}
            isLoading={loading}
        />
    );
}

export default MemberDropdown;
