import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './CreateGRN.css';
import axios from 'axios';
import { BASE_URL, GRNLIST, HEADERS } from '../../constants/config';
import PopupCloseButton from '../Common/PopupCloseButton/PopupCloseButton';
import { useNavigate } from 'react-router-dom';
import { textLettersLimit } from '../../constants/helper';
import { toast } from 'react-toastify';

const options = [
    { value: 'shortfall', label: 'Shortfall' },
    { value: 'rejection', label: 'Rejection' },
];

const CreateGRN = ({ handleClose, sequence_id, warehouse }) => {
    const [defaultDataView, setDefaultDataView] = useState([]);
    const [inputValues, setInputValues] = useState({});
    const [location, setLocation] = useState('');

    const [selectValues, setSelectValues] = useState({});
    const [remarksValues, setRemarksValues] = useState({});
    const [changedRows, setChangedRows] = useState(new Set());
    const [errorMessage, setErrorMessage] = useState('');
    const [locationList, setLocationList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const nav = useNavigate();

    useEffect(() => {
        fetchPoDetails();
    }, []);

    const fetchPoDetails = async () => {
        try {
            const headers = {
                "x-auth-token": localStorage.getItem("token"),
            };

            const grnresponse = await axios.get(`${BASE_URL}/v1/goods-receipts/get-grn-popup-details`, {
                headers, params:
                    { po_number: sequence_id }
            });
            // setDefaultDataView(response.data.data.po_details.data.tableDetails);
            setDefaultDataView(grnresponse.data.data.details)
            setDefaultDataView(grnresponse.data.data.details)


        } catch (error) {
            console.error("Error fetching options data:", error);
        }
    };
    const getWarehouseList = async (page) => {
        if (loading || currentPage > totalPages) return; // Prevent multiple calls
        setLoading(true); // Set loading before fetching
        try {
           
            const response = await axios.get(`${BASE_URL}/v1/warehouse/get-location-list`, {
                params: {
                    warehouse_sequence_id: warehouse,
                    page: page,
                },
                headers: HEADERS,
            });

            const { location_list, pagination } = response.data.data;

            setLocationList((prevList) => [...prevList, ...location_list]); // Append new data to the existing list
            setTotalPages(pagination.total_pages);
            setLoading(false); // Done loading
        } catch (error) {
            console.log(error);
            setLoading(false); // Reset loading in case of error
        }
    };
    const handleFocus = () => {
        if (locationList.length === 0) {
            getWarehouseList(1); // Initial fetch when dropdown opens
        }
    };

    // Handle pagination when scrolled to the bottom
    const handleMenuScrollToBottom = () => {
        if (currentPage < totalPages) {
            getWarehouseList(currentPage + 1); // Load next page
        }
    };
    const handleInputChange = (index, value) => {
        setInputValues({
            ...inputValues,
            [index]: parseInt(value, 10) || 0
        });
        setChangedRows(new Set(changedRows).add(index));
    };

    const handleSelectChange = (index, selectedOption) => {
        setSelectValues({
            ...selectValues,
            [index]: selectedOption.value
        });
        setChangedRows(new Set(changedRows).add(index));
    };

    const handleRemarksChange = (index, value) => {
        setRemarksValues({
            ...remarksValues,
            [index]: value
        });
        setChangedRows(new Set(changedRows).add(index));
    };

    const handleSave = async () => {
        // Validation: Check if received quantities exceed PO quantities, remaining quantities are negative, or received quantities are zero
        const invalidRows = defaultDataView.filter((row, index) => {
            const receivedQty = Number(inputValues[index]);
            const remainingQty = row['quantity'] - (Number(row['total_grn_received_qty'])) - receivedQty;
            return receivedQty > row['quantity'] || !remainingQty ;
        });

        if (invalidRows.length > 0) {
            setErrorMessage('Received quantity should not be greater than PO quantity and must be greater than zero.');
            return;
        } else if (!location) {
            setErrorMessage('Select location to create grn');
            return
        } else {
            setErrorMessage('');

        }
        try {
            setLoading(true);
            const tableDataToSave = defaultDataView.map((row, index) => ({
                part_number: row['part_number'],
                part_title: row['part_title'],
                purchase_order_qty: row['quantity'],
                received_qty: Number(inputValues[index]) || 0,
                remaining_qty: row['quantity'] - (Number(inputValues[index]) || 0),
                grn_status: options.find(opt => opt.value === (selectValues[index] || options[0].value)).label,
                remarks: remarksValues[index] || '',
            }));

            // Filter changed rows
            const changedRowsData = Array.from(changedRows).map(index => ({
                ...tableDataToSave[index]
            }));
            console.log("Changed Rows:", changedRowsData);

            const headers = {
                "x-auth-token": localStorage.getItem("token"),
            };
            const payload = {
                data: JSON.stringify(changedRowsData),
                purchase_order_number: sequence_id,
                location_sequence_id: location
            };
            console.log(payload);
            const response = await axios.post(
                `${BASE_URL}/v1/goods-receipts/create-grn`,
                payload,
                { headers: headers }
            );
            if (response.data.meta.success) {
                nav(GRNLIST);
            } else {
                toast.error(response.data.meta.message)
            }


        } catch (error) {
            console.error("Error saving data:", error);
        } finally {
            setLoading(false);  // End loading
        }

    };

    const handleselectLocation = (selected) => {
        console.log(selected)
        setLocation(selected.value)
    }


    return (
        <div className='create-grn-popup'>
            <div className="create-grn-div">
                <div className="modal-content">
                    <div className="modal-header">
                        <span>Create GRN</span>
                        <PopupCloseButton handleClose={handleClose} />
                    </div>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '10px',marginBottom:'18px' }}>
                        <span>Select Location</span>
                        <div style={{ width: '25%' }}>
                            <Select
                                options={locationList.map((location) => ({
                                    value: location.location_sequence_id,
                                    label: location.location_default_data.location_name.value, // Adjust based on your data structure
                                }))}
                                placeholder='Select location'
                                onChange={(selected) => handleselectLocation(selected)}
                                onFocus={handleFocus} // Trigger onFocus
                                onMenuScrollToBottom={handleMenuScrollToBottom} // Trigger on scroll to bottom
                                isLoading={loading} // Show loading indicator when fetching
                            // Optional: Allow clearing the selection
                            />
                        </div>

                    </div>

                    <div className="create-grn-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Sr. no.</th>
                                    <th>Part number</th>
                                    <th>Description</th>
                                    {/* <th> PO qty</th> */}
                                    <th>Received qty</th>
                                    <th>Remaining qty</th>
                                    <th>Total received qty / PO qty</th>
                                    <th>Shortfall/ Rejection</th>
                                    <th>Remarks</th>
                                </tr>
                            </thead>
                            <tbody>
                                {defaultDataView.map((row, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{row['part_number']}</td>
                                        <td title={row['part_title']}>{textLettersLimit(row['part_title'], 40)}</td>
                                        {/* <td>{row['quantity']}</td> */}
                                        <td>
                                            <input
                                                type="number"
                                                // max={row['quantity'] -  (Number(row['total_grn_received_qty']) || 0) -
                                                //         (Number(inputValues[index]) || 0)===0}
                                                // disabled={row['quantity'] -  (Number(row['total_grn_received_qty']) || 0) -
                                                //     (Number(inputValues[index]) || 0)===0}
                                                placeholder='Enter qty'
                                                className="create-grn-table-input"
                                                onChange={(e) => handleInputChange(index, e.target.value)}
                                            />
                                        </td>
                                        <td>{row['quantity'] - (Number(row['total_grn_received_qty']) || 0) -
                                            (Number(inputValues[index]) || 0)}</td>
                                        {/* <td>{inputValues[index]||0}/{row['quantity']}</td> */}
                                        <td>
                                            {(
                                                (Number(row['total_grn_received_qty']) || 0) +
                                                (Number(inputValues[index]) || 0)
                                            )}/{Number(row['quantity']) || 'N/A'}
                                        </td>

                                        <td>
                                            <Select
                                                options={options}
                                                // defaultValue={options[0]}
                                                onChange={(selectedOption) => handleSelectChange(index, selectedOption)}
                                                // isDisabled={(inputValues[index] || 0) === 0}
                                                isDisabled={defaultDataView[index]['quantity'] - (Number(row['total_grn_received_qty']) || 0) -
                                                    (Number(inputValues[index]) || 0) === 0}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                className='create-grn-table-remarks'
                                                placeholder="Enter remarks"
                                                onChange={(e) => handleRemarksChange(index, e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div
                        className="error-message"
                        style={{ visibility: errorMessage ? 'visible' : 'hidden' }}
                    >
                        {errorMessage}
                    </div>


                    <div className="modal-footer">
                        <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                            <button className="btn go-back" onClick={handleClose}>Go back</button>
                            <button className="btn partial-receive" disabled={loading} onClick={handleSave}>Partial Receive</button>
                        </div>
                        {/* <button className="btn close-po" onClick={()=>handleClosePo(sequence_id)}>Close PO</button> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateGRN;
