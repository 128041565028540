import React, { useState, useEffect, useCallback } from "react";
import "./TicketList.css";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ASSET_PREFIX_URL, BASE_URL, GRNVIEW, INVENTORY, POLIST, POVIEW, PRODUCTIONLIST } from '../../constants/config';
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import Loading from "../Common/Loading/Loading";
import NameProfile from "../Common/CommonJsx/NameProfile";
import InventoryPopUps from "../PopUpsFolder/InventoryPopUps";

const InventoryView = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const [resetButton, setResetButton] = useState(false);
  const [inventoryData, setInventoryData] = useState([]);
  const [activePos, setActivePos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [inventryQuantity, setInventryQuantity] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const nav = useNavigate();
  const { part_number } = useParams();

  const handleReset = () => {
    setResetButton(false);
  };

    const toggleDropdown = (e, action) => {
        e.stopPropagation();
        setIsDropdownVisible(action);
    };

   
    const handleClick = (action, id) => {
        console.log(id)
        if (action === 'back') {
            nav(INVENTORY);
        } else if (action === 'po-list') {
            nav(POLIST)
        } else if (action === 'po-view') {
            nav(POVIEW(id));

        } else if (action === 'GRN') {
            nav(GRNVIEW(id));
        }else if (action === 'PRODUCTION_SCHEDULE') {
            nav(PRODUCTIONLIST);
        }
    }




    const fetchInventoryLogs = useCallback(async (pageNumber) => {
        try {
            setLoading(true);
            const headers = {
                "x-auth-token": localStorage.getItem("token"),
            };
            const response = await axios.get(`${BASE_URL}/v1/inventory/inventory-logs`, {
                params: {
                    part_number,
                    page: pageNumber,
                    limit: 20,  // Adjust the limit as needed
                },
                headers: headers,
            });
            setInventryQuantity(response.data.data.inventory_qty.quantity)
            const logs = response.data.data.activity_logs;
            const activePOs = await axios.get(
                `${BASE_URL}/v1/inventory/get-active-po`,

          {
            params: {
              part_number,

              // page: currentPage,
              // limit: itemsPerPage,
              // search: searchTerm,
            },
            headers: headers,
          }
        );
        console.log(activePOs.data.data.po_list);
        setActivePos(activePOs.data.data.po_list);
        setInventoryData((prevData) => [...prevData, ...logs]);
        setHasMore(logs.length > 0);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    [part_number]
  );

  useEffect(() => {
    fetchInventoryLogs(page);
  }, [fetchInventoryLogs, page]);

  const handleScroll = useCallback(() => {
    if (loading || !hasMore) return;
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      console.log("Reached end of page");
      setPage((prevPage) => prevPage + 1);
    }
  }, [loading, hasMore]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  // Example of defining actionOptions within the component
  const actionOptions = [
    { action: "INVENTORY_MANUAL" },
    { action: "MISSING" },
    // Add more options as needed
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "short", day: "numeric", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  const [selectedOption, setSelectedOption] = useState(null);
  const [openInventory, setOpenInventory] = useState(null);

  const handleOpenPopUp = (action) => {
    setSelectedOption(action);
    setOpenInventory(!openInventory);

    // Perform any action with the selected option
  };
  const handleCloseInventory = () => {
    setOpenInventory(!openInventory);
    setSelectedOption(null);
  };
  return (
    <>
      {loading && page === 1 ? (
        <Loading />
      ) : (
        <div
          className="inventory-list"
          onClick={() => setIsDropdownVisible(false)}
          onScroll={handleScroll}
        >
          <div className="inventory-header">
            <div
              className="spn-depart"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                justifyContent: "left",
              }}
            >
              <img
                style={{ cursor: "pointer" }}
                src={`${ASSET_PREFIX_URL}template-back-btn.svg`}
                alt=""
                width="24px"
                height="24px"
                onClick={() => handleClick("back")}
              />
              <span className="inventory-title">Inventory / </span>
              <span style={{ color: "#610bee" }}>{part_number}</span>
            </div>
            <div className="inventory-view-top-btns">
              <div className="view-grns-page-div-relative">
                <button
                  className="create-grn-btn-po"
                  onClick={(e) => toggleDropdown(e, "po")}
                >
                  {} Active PO
                  <img
                    style={{ width: "20px", height: "20px" }}
                    src={`${ASSET_PREFIX_URL}grn-dropdown.png`}
                    alt=""
                  />
                </button>
                {isDropdownVisible === "po" && (
                  <div
                    className="view-grns-page-div-absolute"
                    style={{ width: "240px", right: "40px" }}
                  >
                    {activePos.slice(0, 2).map((po, index) => (
                      <div key={index}>
                        <div
                          className="grn-item"
                          onClick={() =>
                            handleClick(
                              "po-view",
                              po.purchase_order_nomenclature
                            )
                          }
                        >
                          <span className="grn-code link-po">
                            {po.purchase_order_nomenclature}
                          </span>
                          {/* <span className="grn-date">{po.purchase_order_nomenclature}</span> */}
                        </div>
                        <div
                          className="horizontal-line-po"
                          style={{ margin: "0px" }}
                        ></div>
                      </div>
                    ))}
                    {activePos.length > 2 && (
                      <div className="view-all-btn-grn">
                        <span
                          className="view-all-link"
                          onClick={() => handleClick("po-list")}
                        >
                          View All
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="view-grns-page-div-relative">
                <button
                  className="create-grn-btn-po"
                  style={{ background: "#610bee", color: "white" }}
                  onClick={(e) => toggleDropdown(e, "grn")}
                >
                  View action{" "}
                  <img
                    style={{ width: "20px", height: "20px" }}
                    src={`${ASSET_PREFIX_URL}drop-down-inv.png`}
                    alt=""
                  />
                </button>
                {isDropdownVisible === "grn" && (
                  <div
                    className="view-grns-page-div-absolute"
                    style={{ width: "230px", right: "20px" }}
                  >
                    {/* Replace actionOptions with your actual data */}
                    {actionOptions.slice(0, 2).map((item, index) => (
                      <div key={index}>
                        <div
                          className="grn-item"
                          onClick={() => handleOpenPopUp(item.action)}
                        >
                          <span className="grn-code">{item.action}</span>
                        </div>
                        <div
                          className="horizontal-line-po"
                          style={{ margin: "0px" }}
                        ></div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="inventory-quantity">
            <span>Inventory quantity</span>
            <span className="inventory-quantity-qty">
              {/* {inventoryData[0].inventory_details.quantity} */}
              {inventryQuantity} Units
            </span>
          </div>
          <div className="tck-fltr">
            {resetButton && (
              <button
                className="reset-btn"
                onClick={handleReset}
                data-tour="step-11"
              >
                reset <TuneOutlinedIcon />
              </button>
            )}
          </div>
          <div className="department-table">
            <table className="department-table-data" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th style={{ width: "15%" }}>Date</th>
                  <th style={{ width: "20%" }}>Action</th>
                  <th style={{ width: "15%" }}>Activity by</th>
                  <th style={{ width: "15%" }}>Part number</th>
                  <th style={{ width: "10%" }}>Qty update</th>
                  <th style={{ width: "35%" }}>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {inventoryData.map((item, index) => (
                  <tr key={index}>
                    <td> {formatDate(item.updatedAt)}</td>
                    <td>
                      {item.action_type === "INVENTORY_MANUAL" ||
                      item.action_type === "MISSING" ? (
                        <span>{item.action_type}</span>
                      ) : (
                        <span
                          style={{ color: "#610bee" }}
                          onClick={() =>
                            handleClick(item.action_type, item.action_id)
                          }
                        >
                          {item.action_type}
                        </span>
                      )}
                    </td>
                    <td>
                      <div
                        className="member-option"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <NameProfile
                          userName={item.member_details.fullName}
                          memberPhoto={item.member_details.photo}
                          width="26px"
                          fontweight="500"
                        />

                        <span className="member-fullname">
                          {item.member_details.fullName}
                        </span>
                      </div>
                    </td>
                    <td>{item.part_number}</td>
                    <td
                      style={{
                        color:
                          item.action_type === "INVENTORY_MANUAL"
                            ? "red"
                            : "green",
                      }}
                    >
                      {item.action_type === "INVENTORY_MANUAL" ? "-" : " "}
                      {item.quantity}
                    </td>
                    <td>{item.reason}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {openInventory && (
        <InventoryPopUps
          selectedOption={selectedOption}
          handleCloseInventory={handleCloseInventory}
          data={{ quantity: inventryQuantity, part_number: part_number }}
        />
      )}
    </>
  );
};

export default InventoryView;
