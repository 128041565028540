import React, { useState, useEffect } from 'react';
import { ASSET_PREFIX_URL, BASE_URL, HEADERS, SUPLIST } from '../../constants/config';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './SupplierPage.css';

function AddressField({ addresses, onAddAddress, onEditAddress, onDeleteAddress }) {
    return (
        <div className='address-field-container'>
            {addresses.map((address, index) => (
                <div key={index} className='address-field'>
                    <textarea
                        className='supply-textarea'
                        placeholder='Enter address'
                        value={address}
                        onChange={(e) => onEditAddress(index, e.target.value)}
                    />
                    {/* <button > */}
                    {/* attri-delete.png */}
                    <img onClick={() => onDeleteAddress(index)}
                        src={`${ASSET_PREFIX_URL}attri-delete.png`}
                        alt="delete" style={{ cursor: 'pointer' }} />
                    {/* </button> */}
                </div>
            ))}
            <button onClick={onAddAddress} className="add-alternate-address-btn">
                Add more Address
            </button>
            {/* <button onClick={onAddAddress}>Add Address</button> */}
        </div>
    );
}

function SupplierPageView() {
    const nav = useNavigate();
    const { sequence_id } = useParams();
    const [data, setData] = useState({});
    const [formData, setFormData] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [editedField, setEditedField] = useState(null);

    useEffect(() => {
        fetchSupplierDetails();
    }, []);

    const fetchSupplierDetails = async () => {
        try {
            const headers = HEADERS
            const response = await axios.get(`${BASE_URL}/v1/supply-chain/get-supplier-details`, {
                headers,
                params: { sequence_id: sequence_id }
            });

            setData(response.data.data.supplier_details.data);
            setFormData(response.data.data.supplier_details.data);

        } catch (error) {
            console.error('Error fetching options data:', error);
        }
    };

    const handleBack = () => {
        nav(SUPLIST);
    };

    const handleChange = (fieldKey, value) => {
        setFormData(prevState => ({
            ...prevState,
            [fieldKey]: value
        }));
        console.log(fieldKey)
    };


    const handleEdit = (fieldKey) => {
        setEditedField(fieldKey);
    };

    const handleCancelEdit = () => {
        setEditedField(null);
    };

    const handleSaveChanges = async (fieldKey) => {
        console.log(fieldKey)
        const isDefaultField = data.defaultFieldsData.hasOwnProperty(fieldKey);
        const previousData = {
            [fieldKey]: isDefaultField
                ? { ...data.defaultFieldsData[fieldKey] }
                : { ...data.customFieldsData[fieldKey] }
        };

        const updatedData = {
            [fieldKey]: isDefaultField
                ? { ...data.defaultFieldsData[fieldKey], value: formData[fieldKey] }
                : { ...data.customFieldsData[fieldKey], value: formData[fieldKey] }
        };

        if (previousData[fieldKey].inputType === 'date_range') {
            previousData[fieldKey].value = {
                start: data.defaultFieldsData[fieldKey]?.value.start || data.customFieldsData[fieldKey]?.value.start,
                end: data.defaultFieldsData[fieldKey]?.value.end || data.customFieldsData[fieldKey]?.value.end
            };
        }

        if (updatedData[fieldKey].inputType === 'date_range') {
            updatedData[fieldKey].value = {
                start: formData[fieldKey]?.start || '',
                end: formData[fieldKey]?.end || ''
            };
        }

        console.log('Previous Data:', previousData);
        console.log('Updated Data:', updatedData);

        setEditedField(null);
        setEditMode(false);

        try {
            const headers = {
                "x-auth-token": localStorage.getItem("token"),
            };

            await axios.post(
                `${BASE_URL}/v1/supply-chain/edit-supplier-details`,
                {
                    sequence_id: sequence_id,
                    prev_obj: JSON.stringify(previousData),
                    new_obj: JSON.stringify(updatedData),
                },
                { headers }
            );

        } catch (error) {
            console.log(error);
        }
    };


    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: "292px",
            backgroundColor: "white",
            border: "1px solid #edf2f7",
            boxShadow: "none",
            color: 'black',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
        }),
        option: (provided, state) => ({
            ...provided,
            background: "white",
            color: "black",
            cursor: "pointer",
            ":hover": {
                background: "#610BEE",
                color: "white",
            },
        }),
        singleValue: (provided, state) => ({
            ...provided,
            backgroundColor: "transparent",
            color: "black",
            padding: "5px 10px",
            borderRadius: "4px",
            fontSize: "14px",
        }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: "14px",
            color: "black",
        }),
        menu: (provided) => ({
            ...provided,
            width: "292px",
            backgroundColor: "white",
            color: "black",
        }),
        menuList: (provided) => ({
            ...provided,
            backgroundColor: "white",
        }),
    };

    const renderFields = (fields) => {
        if (!fields) return null;

        return Object.keys(fields).map((fieldKey) => {
            const field = fields[fieldKey];
            if (!field.value) return null;

            return (
                <div key={fieldKey} className='supplier-mandatory-field-container'>
                    <span className='supply-custom-label-span'>{field.fieldName}:</span>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
                        {editedField === fieldKey ? (
                            <>
                                {(field.inputType === 'text' || field.inputType === 'email' || field.inputType === 'gst' ||
                                    field.inputType === 'team' || field.inputType === 'pincode') && (
                                        <>
                                            <input
                                                type='text'
                                                placeholder={field.placeholder || ''}
                                                value={formData[fieldKey] || field.value || ''}
                                                onChange={(e) => handleChange(fieldKey, e.target.value)}
                                            />
                                            <img onClick={() => handleSaveChanges(fieldKey)} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                            <img onClick={handleCancelEdit} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                        </>
                                    )}
                                {(field.inputType === 'address') && (
                                    <>
                                        <AddressField
                                            addresses={formData[fieldKey] || field.value || []}
                                            onAddAddress={() => handleChange(fieldKey, [...(formData[fieldKey] || field.value || []), ''])}
                                            onEditAddress={(index, value) => {
                                                const updatedAddresses = [...(formData[fieldKey] || field.value || [])];
                                                updatedAddresses[index] = value;
                                                handleChange(fieldKey, updatedAddresses);
                                            }}
                                            onDeleteAddress={(index) => {
                                                const updatedAddresses = [...(formData[fieldKey] || field.value || [])];
                                                updatedAddresses.splice(index, 1);
                                                handleChange(fieldKey, updatedAddresses);
                                            }}
                                        />
                                        <img onClick={() => handleSaveChanges(fieldKey)} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                        <img onClick={handleCancelEdit} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                    </>
                                )}
                                {(field.inputType === 'paragraph') && (
                                    <>
                                        <textarea
                                            className='supply-textarea'
                                            placeholder={field.placeholder || ''}
                                            value={formData[fieldKey] || field.value || ''}
                                            onChange={(e) => handleChange(fieldKey, e.target.value)}
                                        />
                                        <img onClick={() => handleSaveChanges(fieldKey)} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                        <img onClick={handleCancelEdit} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                    </>
                                )}
                                {field.inputType === 'PhoneNumber' && (
                                    <>
                                        <PhoneInput
                                            style={{ width: '300px' }}
                                            placeholder={field.placeholder || ''}
                                            value={formData[fieldKey] || field.value || ''}
                                            onChange={(value) => handleChange(fieldKey, value)}
                                        />
                                        <img onClick={() => handleSaveChanges(fieldKey)} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                        <img onClick={handleCancelEdit} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                    </>
                                )}
                                {field.inputType === 'single_select' && (
                                    <>
                                        <Select
                                            styles={customStyles}
                                            options={field.option.map(option => ({ value: option, label: option }))}
                                            value={{ value: formData[fieldKey] || field.value, label: formData[fieldKey] || field.value }}
                                            onChange={(selectedOption) => handleChange(fieldKey, selectedOption.value)}
                                        />
                                        <img onClick={() => handleSaveChanges(fieldKey)} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                        <img onClick={handleCancelEdit} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                    </>
                                )}
                                {field.inputType === 'date' && (
                                    <>
                                        <input
                                            type='date'
                                            placeholder={field.placeholder || ''}
                                            value={formData[fieldKey] || field.value || ''}
                                            onChange={(e) => handleChange(fieldKey, e.target.value)}
                                        />
                                        <img onClick={() => handleSaveChanges(fieldKey)} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                        <img onClick={handleCancelEdit} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                    </>
                                )}
                                {field.inputType === 'date_range' && (
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', width: '500px' }}>
                                        <div className="type-texts" style={{ width: '290px' }}>
                                            <div style={{ width: '200px' }}>
                                                <span>Start Date</span>
                                                <input
                                                    placeholder={field.placeholder}
                                                    type="date"
                                                    style={{ width: '100%' }}
                                                    value={formData[fieldKey]?.start || field.value?.start || ''}
                                                    // value={formData[field.fieldName]?.start || field.value.start}
                                                    min={new Date().toISOString().split("T")[0]}
                                                    onChange={(e) =>
                                                        handleChange(fieldKey, {
                                                            ...formData[field.fieldName],
                                                            start: e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div style={{ width: '200px' }}>
                                                <span>End Date</span>
                                                <input
                                                    type="date"
                                                    style={{ width: '100%' }}
                                                    value={formData[fieldKey]?.end || field.value?.end || ''}
                                                    min={formData[field.fieldName]?.start || field.value?.start}
                                                    onChange={(e) =>
                                                        handleChange(fieldKey, {
                                                            ...formData[field.fieldName],
                                                            end: e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            <img onClick={() => handleSaveChanges(fieldKey)} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                            <img onClick={handleCancelEdit} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                        </div>
                                    </div>
                                )}

                                {field.inputType === 'number' && (
                                    <>
                                        <input
                                            type='number'
                                            placeholder={field.placeholder || ''}
                                            value={formData[fieldKey] || field.value || ''}
                                            onChange={(e) => handleChange(fieldKey, e.target.value)}
                                        />
                                        <img onClick={() => handleSaveChanges(fieldKey)} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                        <img onClick={handleCancelEdit} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                    </>
                                )}
                                {field.inputType === 'link' && (
                                    <>
                                        <input
                                            type='text'
                                            placeholder={field.placeholder || ''}
                                            value={formData[fieldKey] || field.value || ''}
                                            onChange={(e) => handleChange(fieldKey, e.target.value)}
                                        />
                                        <img onClick={() => handleSaveChanges(fieldKey)} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                        <img onClick={handleCancelEdit} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                    </>
                                )}
                                {field.inputType === 'multi_select' && (
                                    <>
                                        <Select
                                            styles={customStyles}
                                            isMulti
                                            options={field.option.map(option => ({ value: option, label: option }))}
                                            value={(formData[fieldKey] || field.value || []).map(val => ({ value: val, label: val }))}
                                            onChange={(selectedOptions) => handleChange(fieldKey, selectedOptions.map(option => option.value))}
                                        />
                                        <img onClick={() => handleSaveChanges(fieldKey)} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                        <img onClick={handleCancelEdit} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                {field.inputType === 'link' ? (
                                    <a
                                        href={field.value.includes('http') ? field.value : `https://${field.value}`}
                                        target="_blank"
                                        className="form-link"
                                    >
                                        {field.value}
                                    </a>
                                ) : field.inputType === 'date_range' ? (
                                    <div style={{ display: 'flex', gap: '8px' ,flexDirection:'column'}}>
                                        <input type="text" value={field.value?.start || ''} readOnly />
                                        <input type="text" value={field.value?.end || ''} readOnly />
                                    </div>
                                ) : (
                                    <input value={formData[fieldKey] || field.value} disabled />
                                )}
                                <img
                                    onClick={() => handleEdit(fieldKey)}
                                    src={`${ASSET_PREFIX_URL}edit-ticket.png`}
                                    alt="Edit"
                                    width="24px"
                                    height="24px"
                                    style={{ cursor: 'pointer' }}
                                />
                            </>
                        )}
                    </div>
                </div>
            );
        });
    };

    return (
        <div className='supplier-page-view'>
            <div className='supplier-page-top-bar'>
                <div className='create-po-title'>
                    <img
                        style={{ cursor: 'pointer' }}
                        src={`${ASSET_PREFIX_URL}template-back-btn.svg`}
                        alt=""
                        width='24px'
                        height='24px'
                        onClick={handleBack}
                    />
                    <div>
                        Supplier details / <span style={{ color: '#610bee' }}>{sequence_id}</span>
                    </div>
                </div>
            </div>
            <div className='supplier-page-bottom-page'>
                <div className='supplier-page-default-fields'>
                    {renderFields({ ...data.defaultFieldsData, ...data.customFieldsData })}
                </div>
            </div>
        </div>
    );
}

export default SupplierPageView;
