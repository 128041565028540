import React, { useState,useEffect } from 'react'
import Select from "react-select";
import './ProductionSchedule.css'
import axios from "axios";
import { BASE_URL } from "../../constants/config";
import Cookies from "js-cookie";
import { toast } from 'react-toastify';
import PopupCloseButton from '../Common/PopupCloseButton/PopupCloseButton';

function CreateProductionPlanning({onclose,setUpdatedDetails}) {
    const [product, setProduct] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [quantity, setQuantity] = useState('')
    const [units, setUnits] = useState('')
    const [options, setOptions] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            overflowY: "auto",
            overflow: "hidden",
        }),
        menuList: (provided) => ({
            ...provided,
            "::-webkit-scrollbar": "auto",
            "::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: "8px",
            },
            "::-webkit-scrollbar-thumb:hover": {
                background: "#555",
            },
        }),
        option: (provided, state) => ({
            ...provided,
            background: state.isSelected ? "transparent" : "white",
            color: state.isSelected ? "black" : "inherit",
        }),
    };

    const handleChange = (e, type) => {
        if (type === 'product') {
            setProduct(e.product_name)
            console.log(e)
        } else if (type === 'start') {
            setStartDate(e.target.value)
        } else if (type === 'end') {
            setEndDate(e.target.value)
        } else if (type === 'quantity') {
            setQuantity(e.target.value)
        }else if(type === 'units'){
            setUnits(e.target.value)
        }

    }


    const handleSave = async () => {
       

        try {
            const headers = {
                'x-auth-token': localStorage.getItem("token")
            };
            const payload = {
                product_name: product,
                quantity: quantity,
                start_date: startDate,
                end_date: endDate,
                unit_of_measurment: units
            };
            console.log(payload)
           const response =  await axios.post(`${BASE_URL}/v1/prod-plan/create-production-plan`, payload, { headers });
            console.log(response)
            if(response.data.meta.success === false){
                toast.error(response.data.meta.message)
            }else{
                setUpdatedDetails(product)
                onclose()
                // window.location.reload()
            }
          

        } catch (error) {
            console.error("Error saving MBOM:", error);
           
        }
    };
    useEffect(() => {
      
            fetchOptions();
        
    }, [page]);

    const fetchOptions = async () => {
        
        try {
            const headers = {
                'x-auth-token': localStorage.getItem("token")
            };
        
                const response = await axios.get(`${BASE_URL}/v1/prod-plan/get-all-products`, {
                    params: {
                        
                        page: page,
                        limit: 10
                    },
                    headers: headers,
                });
                const data = response.data.data.products;
                setOptions(prevOptions => {
                    if (page === 1) {
                        return data; // Replace the options when it's the first page
                    }
                    return [...prevOptions, ...data]; // Append options for subsequent pages
                });
    
                setHasMore(data.length > 0);
           
           
            
           
        } catch (error) {
            console.error("Error fetching options data:", error);
        }
    };
    // const filterOptions = (candidate, input) => {
    //     if (!input) {
    //         return true;
    //     }

    //     const inputValue = input.toLowerCase().trim();
    //     const part_number = candidate.data.part_number.toLowerCase();
    //     return part_number.includes(inputValue);
    // };
    const handleScroll = (event) => {
        const bottom = event.target.scrollHeight === event.target.scrollTop + event.target.clientHeight;
        if (bottom && hasMore) {
            setPage(prevPage => {
                const nextPage = prevPage + 1;
                fetchOptions(nextPage);
                return nextPage;
            });
        }
    };
    return (
        <div className='create-part-number-popup-page'>
            <div className='create-production-plan'>
                <div className='create-production-plan-heading'>
                    <span>Work order details</span>
                    <PopupCloseButton handleClose={onclose}/>
                </div>
                <div className='production-plan-bottom'>
                    <div className='production-plan-inputs'>
                        <span>Select model</span>
                        <Select
                            id="mySelect2"
                            options={options}
                            styles={customStyles}
                            getOptionLabel={(option) => (
                                <div className="select-photo">
                                    {option.product_name}&nbsp;
                                    {/* ({option.part_title.length > 12 ? `${option.part_title.slice(0, 18)}...` : option.part_title}) */}
                                </div>
                            )}
                            onFocus={fetchOptions}
                            onMenuScrollToBottom={handleScroll}
                            onChange={(selected) => handleChange(selected, 'product')}
                            // filterOption={filterOptions}

                            // value={product}
                        />
                    </div>
                    <div className='production-plan-inputs'>
                        <span>Start date</span>
                        <input type='date' min={new Date().toISOString().split("T")[0]} value={startDate} onChange={(e) => handleChange(e, 'start')} />
                    </div>
                    <div className='production-plan-inputs'>
                        <span>End date</span>
                        <input type='date' value={endDate} min={startDate} onChange={(e) => handleChange(e, 'end')} />
                    </div>
                    <div className='production-plan-inputs'>
                        <span>Quantity</span>
                        <input type='number' placeholder='Enter quantity' value={quantity} onChange={(e) => handleChange(e, 'quantity')} />
                    </div>
                    <div className='production-plan-inputs'>
                        <span>Unit of measurements</span>
                        <input type='text' placeholder='Enter units' value={units} onChange={(e) => handleChange(e, 'units')} />
                    </div>
                    <div className="collab-btns">
                        {(!product || !startDate || !endDate || !quantity || !units) ?
                            <button className="btn-sv1" style={{width:'100%'}} onClick={handleSave}>Create</button> : <button style={{width:'100%'}} className="btn-sv2" onClick={handleSave}>Create</button>}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateProductionPlanning