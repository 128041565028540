import React from 'react'
import '../SetupPassword/SetupPassword.css';
import { ASSET_PREFIX_URL } from "../../constants/config";

const RightContainer = () => {
  return (
    <div className="right-container">
      {/* <img src={`${ASSET_PREFIX_URL}bg-voilet.svg`} alt="" /> */}
      <img src={`${ASSET_PREFIX_URL}right-tri.webp`} alt="" />
    </div>
  )
}

export default RightContainer
