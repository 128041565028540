import React, { useState, useEffect } from "react";
import '../DepartmentPages/DepartmentForm.css'
import axios from "axios";
import Cookies from "js-cookie";
import Select from "react-select";
import {
    ASSET_PREFIX_URL,
    BASE_URL,
    BUCKET,
    CATEGORY_UPLOAD_DESIGNS,
} from "../../constants/config";
import { simpleUpload, multiUpload } from "../../constants/helper"
import PopupCloseButton from "../Common/PopupCloseButton/PopupCloseButton";
import NameProfile from "../Common/CommonJsx/NameProfile";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AttachmentsFunction from "../Common/CommonJsx/AttachmentsFunction";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import DownloadAttachment from "../Common/CommonJsx/DownloadAttachment";



function DownloadFilesPopUp({ row, onClose }) {
    return (
        <>
            <div className='create-part-number-popup-page' >
                <div className='create-part-num-div' >
                    <div className='create-part-num-div-top'>
                        <span>Download Files for v{row.major_version}.{row.minor_version} of {row.name}</span>
                        <PopupCloseButton handleClose={onClose} />
                    </div>
                    <div className='create-part-num-div-form' style={{ overflowY: 'scroll' }}>
                        <div className="create-part-num-div-form-label-attahment">
                            <span>Download STEP file</span>
                            <DownloadAttachment uploadedFiles={row.design_files} type='form-upload' />
                        </div>
                        {
                            (row.supporting_files && row.supporting_files.length > 0) && <div className="create-part-num-div-form-label-attahment">
                            <span>Download Supporting documents</span>
                            <DownloadAttachment uploadedFiles={row.supporting_files} type='form-upload' />
                        </div> 
                        }
                        
                    </div>

                    {/* <div className='create-part-num-div-btns'>
                        <button
                        // onClick={submitForm} 
                        >Checkin</button>
                    </div> */}
                </div>

            </div>
        </>
    )
}
export default DownloadFilesPopUp;
