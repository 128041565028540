import React from 'react'
import './LaunchingSoon.css'
import { ASSET_PREFIX_URL } from '../../../constants/config'

function LaunchingSoon() {
    return (
        <div className='launching-page'>
            <div className='launching-head'>
                <span>Launching soon on...</span>
            </div>
            <div className='launching-items'>
                <div className='launching-content'>
                    <div className='launching-img'>
                        <img src={`${ASSET_PREFIX_URL}launch-engineer.webp`}alt='launch-engineer' />
                    </div>
                    <div className='launching-desc'>
                        <span>
                            Engineering:
                        </span>
                        <ul>
                            <li>DFMEA: Generate testing and failure analysis reference documents</li>
                            <li>Testing and validation: Track progress and resource allocation for past, ongoing, and planned tests </li>
                        </ul>
                    </div>
                </div>
                <div className='launching-content'>
                    <div className='launching-img'>
                        <img src={`${ASSET_PREFIX_URL}launch-chain.webp`} alt='launch-chain'/>
                    </div>
                    <div className='launching-desc'>
                        <span>
                        Supply chain:
                        </span>
                        <ul>
                            <li>Sourcing: Create RFI, RFQ, and PO packages, and maintain a live supplier database</li>
                            <li>Supplier industrialization: Track your suppliers’ readiness and qualification status </li>
                            <li>Material planning and logistics: Manage ongoing material procurement and inventory status </li>
                        </ul>
                    </div>
                </div>
                <div className='launching-content'>
                    <div className='launching-img'>
                        <img src={`${ASSET_PREFIX_URL}launch-manufacture.webp`} alt='launch-manufacture'/>
                    </div>
                    <div className='launching-desc'>
                        <span>
                        Manufacturing:
                        </span>
                        <ul>
                            <li>Equipment and line installation: Track readiness of equipment installation activities, including FAT, SAT, and production ramp timelines</li>
                            <li>NPI: Implement effective change management and tracking across any product changes and launches  </li>
                            <li>Production: Use MarathonOS MES to run and visualise your production lines </li>
                            <li>Quality: Track quality issues, escalations, and resolutions </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LaunchingSoon