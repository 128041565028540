import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

// Reusable Tab Panel Component
function CustomTabPanel({ children, value, index }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default function CustomTabs({ tabLabels, currentTab, setCurrentTab, panels,  color = '#1976d2'}) {
  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          aria-label="workspace tabs"
          sx={{
            '.MuiTabs-indicator': { backgroundColor: color }, // Dynamic indicator color
          }}
        >
          {tabLabels.map((label, index) => (
            <Tab
              key={index}
              label={label}
              sx={{
                '&.Mui-selected': {
                  color: color,  // Dynamic selected tab text color
                },
              }}
            />
          ))}
        </Tabs>
      </Box>

      {/* Render each tab panel's content */}
      {panels.map((PanelComponent, index) => (
        <CustomTabPanel key={index} value={currentTab} index={index}>
          <PanelComponent />  {/* Render the corresponding panel component */}
        </CustomTabPanel>
      ))}
    </Box>
  );
}

CustomTabs.propTypes = {
  tabLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentTab: PropTypes.number.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  panels: PropTypes.arrayOf(PropTypes.elementType).isRequired,
};
