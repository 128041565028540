import React from "react";
import "./ContactPage.css";
import { ASSET_PREFIX_URL } from "../../../constants/config";

function ContactPage() {
  return (
    <div className="contactPage">
      <div className="contactPage-top">
        <div className="contactPage-content">
          <span className="contactPage-join">Join the revolution in</span>
          <span className="contactPage-hardware">
            Hardware lifecycle management OS
          </span>
        </div>
        <div className="contactPage-btn">
          <button>Join the Waitlist</button>
        </div>
      </div>
      <div className="contactPage-bottom">
        <div className="contactpage-title">
          <img src={`${ASSET_PREFIX_URL}contact-logo.webp`} alt=""/>
          <span>Tag line for Marathon</span>
        </div>
        <div className="contactpage-list">
          <span className="contactpage-list-head">Product</span>
          <span className="contactpage-list-iteams">Home</span>
          <span className="contactpage-list-iteams">Pricing</span>
          <span className="contactpage-list-iteams">Demo</span>
        </div>
        <div className="contactpage-list">
          <span className="contactpage-list-head">Contact</span>
          <span className="contactpage-list-iteams">Support</span>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
