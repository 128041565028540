import React from 'react'
import './Dots.css'

function Dots() {
  return (
    <>
    <div className="template-drag-box-right">
        <div className="dots"></div>
        <div className="dots"></div>
        <div className="dots"></div>
    </div>
    <div className="template-drag-box-left">
        <div className="dots"></div>
        <div className="dots"></div>
        <div className="dots"></div>
    </div>
</>
  )
}

export default Dots