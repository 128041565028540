import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import "./AddCollaborate.css";
import { BASE_URL,  PHOTO_LINK } from "../../../constants/config";
import PopupCloseButton from "../PopupCloseButton/PopupCloseButton";
import NameProfile from "../CommonJsx/NameProfile";

function AddCollaborate() {
  const [close, setClose] = useState(false);
  const [collaborators, setCollaborators] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCollaborators, setSelectedCollaborators] = useState([]);
  const [collaboratorsAdded, setCollaboratorsAdded] = useState([]);
  const [collaboratorsCount, setCollaboratorsCount] = useState(0);
  const [searchClicked, setSearchClicked] = useState(false);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleSearchClick = () => {
    fetchData()
    setSearchClicked((prevSearchClicked) => !prevSearchClicked);
  };
  // Filter the collaborators based on the search term
  const filteredCollaborators = collaborators.filter((collaborator) =>
    collaborator.fullName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCheckboxChange = (event, collaboratorId) => {
    if (event.target.checked) {
      // If checkbox is checked, add the collaborator to the selectedCollaborators list
      setSelectedCollaborators((prevSelectedCollaborators) => [
        ...prevSelectedCollaborators,
        collaboratorId,
      ]);
    } else {
      // If checkbox is unchecked, remove the collaborator from the selectedCollaborators list
      setSelectedCollaborators((prevSelectedCollaborators) =>
        prevSelectedCollaborators.filter((id) => id !== collaboratorId)
      );
    }
  };
  // "x-auth-token": localStorage.getItem("token"),
  // useEffect(() => {
  //   fetchData();
  // }, []);
  useEffect(() => {
    fetchAddedData();
  }, []);

  const fetchData = async () => {
    try {
      const headers = {
        "x-auth-token": localStorage.getItem("token"),
      };
      const response = await axios.get(BASE_URL + "/v1/org/add-collab", {
        headers: headers,
      });
      const data = response.data.data;
      setCollaborators(data);
    } catch (error) {
      console.error("Error fetching options data:", error);
    }
  };

  const fetchAddedData = async () => {
    try {
      const headers = {
        "x-auth-token": localStorage.getItem("token"),
      };
      const response = await axios.get(BASE_URL + "/v1/org/get-collab", {
        headers: headers,
      });
      const data = response.data.data.list;

      setCollaboratorsAdded(data);
      setCollaboratorsCount(data.length);

    } catch (error) {
      console.error("Error fetching options data:", error);
    }
  };
  const handleCancel = () => {
    setSelectedCollaborators([]); // Clear selectedCollaborators state to deselect all checkboxes
    // Uncheck all checkboxes
    const checkboxes = document.querySelectorAll(
      ".collab-mem-list input[type='checkbox']"
    );
    checkboxes.forEach((checkbox) => (checkbox.checked = false));
  };
  const handleSave = async () => {
    try {
      setSelectedCollaborators([])
      if (!selectedCollaborators) {

        return;
      }
      const headers = {
        "x-auth-token": localStorage.getItem("token"),
      };
      await axios.post(
        BASE_URL + "/v1/org/update-remove-collab",
        {
          _id: selectedCollaborators,
          action: "add",


        },
        {
          headers: headers,
        }
      );

      await fetchAddedData()
      await fetchData()
    } catch (error) {
      console.error("Error fetching options data:", error);
    }
    // Set the showSelected state to true to render the selected collaborators
  };
  const handleRemove = async (selectedId) => {
    try {
      const headers = {
        "x-auth-token": localStorage.getItem("token"),
      };
      await axios.post(
        BASE_URL + "/v1/org/update-remove-collab", // Make sure BASE_URL is defined and correct
        {
          _id: selectedId,
          action: "remove",
        },
        {
          headers: headers
        }
      );

      await fetchData()
      await fetchAddedData()
    } catch (error) {
      console.error("Error fetching options data:", error);
    }
    // Set the showSelected state to true to render the selected collaborators
  };

  const handleClose = () => {
    setClose(true);
  };
  // console.log(selectedCollaborators.length);
  return (
    <div
      className="addcollab-page"
      style={{ display: close ? "none" : "block" }}
    >
      <div className="addcollab-container">
        <div className="addcollab-head">
          <span>Add Collaborator</span>
          <PopupCloseButton handleClose={handleClose} />
        </div>

        <div className="addcollab-collaboarters">
          <div className="collabs">
            <span>
              <b>Collaborators ({collaboratorsCount})</b>
            </span>
          </div>
          <div className="entire-division">
            <div className="collab-search" onClick={handleSearchClick}>
              <SearchIcon style={{ color: "#001325" }} />
              <input
                type="text"
                placeholder="Search Collaborator"
                value={searchTerm}
                onChange={handleSearch}
              />
            </div>
            {searchClicked && (
              <div className="search-collab-members">
                <div className="collab-members-conatiner">
                  {filteredCollaborators.map((collaborator) => (
                    <div className="collab-mem-list" key={collaborator._id}>
                      <input
                        type="checkbox"
                        onChange={(event) =>
                          handleCheckboxChange(event, collaborator._id)
                        }
                      />
                      {collaborator.photo ? <img
                        src={PHOTO_LINK + collaborator.photo }
                        alt=""
                      /> : <div style={{ background: '#610bee', borderRadius: '50%', color: 'white', width: '25px', height: '25px', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <NameProfile userName={collaborator.fullName} />
                      </div>}


                      <span>{collaborator.fullName}</span>
                    </div>
                  ))}
                </div>
                <div className="collab-members-btns">
                  <div className="collab-btns">
                    <button
                      className={`btn-sv ${selectedCollaborators.length > 0 ? 'save-button-active' : ''}`}
                      onClick={handleSave}
                    >
                      Save
                    </button>
                    <button className="btn-cl" onClick={handleCancel}>
                      Cancel
                    </button>
                  </div>

                  <div className="slcted-collab">
                    <span>Selected: {selectedCollaborators.length}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="collab-list">
            {collaboratorsAdded.map((selectedId, i) => (
              <div className="collab-list-menu" key={i}>
                <div className="collab-list-details">
                <NameProfile   userName={selectedId.fullName}  width='25px'  memberPhoto={selectedId.photo}/>&nbsp;&nbsp;

                  <span>{selectedId.fullName}</span>
                </div>

                <div>
                  <button
                    className="remove-btn-addcolab"
                    onClick={() => handleRemove(selectedId._id)}
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCollaborate;
