import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import './Dashboard.css';
import {
  ASSET_PREFIX_URL,
  BASE_URL,
  LOGIN,
  ORGHOME,
  PCLIST,
  PRODUCTS,
  SUPLIST,
  TKTTEMPLATE,
  WAREHOUSELIST,
  WORKSPACES
} from "./../../constants/config";
import Loading from "./../Common/Loading/Loading";

function Dashboard() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { route } = useParams(); // Get the current route param
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate(LOGIN);
    }

    return () => {};
  }, [navigate]);
  const getDashboardItems = async () => {
    try {
      setLoading(true);
      const headers = {
        "x-auth-token": localStorage.getItem("token"),
      };

      const response = await axios.get(`${BASE_URL}/v1/onBoard/get-dashboard`, {
        headers,
      });
      setData(response.data.data.dashboard);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDashboardItems();
  }, []);

  const handleClick = (itemRoute, isActive) => {
    if (isActive) {
      navigate(itemRoute);
    }
  };

  return (
    <>
      <div className="dashboard-page">
        <div className="dashboard-top-cont">
          <span>Welcome to Marathon OS!</span>
          {loading ? (
            <Loading />
          ) : (
            <div className="dashboard-items-cont">
              {data.map((category, index) => (
                <div
                  key={index}
                  className={`dashboard-items`}
                  style={{
                    opacity: category.is_enabled ? 1 : 0.5,
                    cursor: category.is_enabled ? "pointer" : "not-allowed",
                  }}
                  onClick={() =>
                    handleClick(category.navigation, category.is_enabled)
                  }
                >
                  <img
                    src={ASSET_PREFIX_URL + category.icon}
                    alt={category.display_name}
                  />
                  <div>{category.display_name}</div>
                </div>
              ))}
            </div>
          )}
        </div>
        <span className="dashboard-bottom-cont">
          For any queries, reach out to support@marathon-os.com
        </span>
      </div>
    </>
  );
}

export default Dashboard;
