// BreadcrumbsNav.js
import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
function BreadcrumbsNav({ links, current }) {
    const navigate = useNavigate();
    return (
        <Breadcrumbs aria-label="breadcrumb">
            {links.map((link, index) => (
                <Link
                    key={index}
                    color="primary"
                    underline="hover"
                    sx={{ cursor: 'pointer' }}
                    onClick={()=>navigate(link.href)}
                    // href={link.href}
                >
                    {link.label}
                </Link>
            ))}
            <Typography sx={{ color: 'text.primary' }}>{current}</Typography>
        </Breadcrumbs>
    );
}

// PropTypes validation
BreadcrumbsNav.propTypes = {
    links: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            href: PropTypes.string.isRequired,
        })
    ).isRequired,
    current: PropTypes.string.isRequired,
};

export default BreadcrumbsNav;
