import React from "react";
import '../Signup/Signup.css';
import { ASSET_PREFIX_URL } from "../../constants/config";

const LeftContainer = () => {
  return (
    <div className="left-con">
      <div className="headers">
        {" "}
        {/* <div className="head1">
          <span>Release.Control.Measure</span>
        </div> */}
        <div className="head2">
          <span>
           {/* management OS. */}
          End-to-end hardware lifecycle  <span className="text"> management</span>&nbsp;&nbsp; OS
          </span>
        </div>
      </div>

      <div className="triangles">
        <img src={`${ASSET_PREFIX_URL}t-1.webp`} alt="t1" className="left-img" />
        <img src={`${ASSET_PREFIX_URL}t-2.webp`} alt="t2" className="right-img" />

      </div>
    </div>
  );
};

export default LeftContainer;
