import React, { lazy, Suspense } from 'react';
import './Home.css';
import HomeTopNav from './HomeTopNav/HomeTopNav';
import TeamRich from './TeamRich/TeamRich';
import Capabilities from './Capabilities/Capabilities';
import Compliance from './Compliance/Compliance';
import Security from './Security/Security';
import FaqPage from './FaqPage/FaqPage';
import ContactPage from './ContactPage/ContactPage';
import Reviews from './Reviews/Reviews';
import LaunchingSoon from './LaunchingSoon/LaunchingSoon';
import Loading from '../Common/Loading/Loading';

// Lazy-loaded components
const LazyHomeTopNav = lazy(() => import('./HomeTopNav/HomeTopNav'));
const LazyTeamRich = lazy(() => import('./TeamRich/TeamRich'));
const LazyCapabilities = lazy(() => import('./Capabilities/Capabilities'));
const LazyCompliance = lazy(() => import('./Compliance/Compliance'));
const LazySecurity = lazy(() => import('./Security/Security'));
const LazyFaqPage = lazy(() => import('./FaqPage/FaqPage'));
const LazyReviews = lazy(() => import('./Reviews/Reviews'));
const LazyContactPage = lazy(() => import('./ContactPage/ContactPage'));
const LazyLaunchingSoon = lazy(() => import('./LaunchingSoon/LaunchingSoon'));

function Home() {
  return (
    <div className='home'>
      <HomeTopNav />
      <TeamRich />
      <Capabilities />
      <LaunchingSoon />
      <Compliance />
      <Security />
      <FaqPage />
      <Reviews />
      <ContactPage />

      {/* Lazy-loaded components with Suspense fallback */}
      {/* <Suspense fallback={<Loading/>}>
        <LazyHomeTopNav/>
        <LazyTeamRich />
        <LazyCapabilities />
        <LazyLaunchingSoon />
        <LazyCompliance />
        <LazySecurity />
        <LazyFaqPage />
        <LazyReviews />
        <LazyContactPage />
      </Suspense> */}
    </div>
  );
}

export default Home;
