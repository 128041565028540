import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { BASE_URL, ASSET_PREFIX_URL, PARTVIEW } from "../../../constants/config";
import { saveAs } from "file-saver";
import exceljs from "exceljs";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Loading from "../../Common/Loading/Loading";

function ExcelView({ partNumber, selectedSection,tableView,sequence_id,date }) {
  
  const [treeData, setTreeData] = useState([]);
  const [loading, setLoading] = useState(false);
  

  useEffect(() => {
    if ((partNumber||sequence_id) && selectedSection) {
      fetchBOMHierarchy();
    }
  }, [partNumber,sequence_id, selectedSection,date]);
  const fetchBOMHierarchy = async () => {
    setLoading(true);
    const headers = {
      "x-auth-token": localStorage.getItem("token"),
    };
    try {
      let response;
      if(tableView === 'parts'){
        response = await axios.get(`${BASE_URL}/v1/part-nomen/bom-view`, {
          headers,
          params: { partNumber, view: selectedSection },
        });
        setTreeData(response.data.data.flat_data);
      }else if(tableView === 'ec-template'){
        response = await axios.get(`${BASE_URL}/v1/structure-ec/get-ebom`, {
          headers,
          params: { sequence_id, view: selectedSection },
        });
        setTreeData(response.data.data.flat_data);
      }else if(tableView === 'pci-view'){
        response = await axios.get(BASE_URL + "/v1/manufac-bom/get-mbom-view", {
            headers,
            params: { sequence_id, view: selectedSection }
        });
        console.log(response)
        setTreeData(response.data.data.flat_data)
       }else if(tableView === 'mbom-view'){
        response = await axios.get(BASE_URL + "/v1/manufac-bom/mbom-date-view", {
            headers,
            params: { product_id:sequence_id, view: selectedSection,date }
        });
        console.log(response)
        setTreeData(response.data.data.flat_data)
       }
     
    
    } catch (error) {
      console.error("Error fetching BOM hierarchy data:", error);
      // setError("Failed to fetch data");
    }
    setLoading(false);
  };

  const spreadsheetData = useMemo(() => {
    if (treeData.length > 0) {
      // Create headers including "S.No" and other columns
      const headers = [{ value: "S.No" }, ...Object.keys(treeData[0]).map((key) => ({ value: key }))];

      // Create rows with serial numbers and hyperlinks for "Part Number" column
      const rows = treeData.map((row, index) => {
        const serialNumber = { value: index + 1 };
        const rowData = Object.entries(row).map(([key, value]) => {
          if (key === "Part Number") {
            return {
              value: value !== null && value !== undefined ? value.toString() : '',
              href: PARTVIEW(value), // Example hyperlink, replace with actual link
            };
          }
          return { value: value !== null && value !== undefined ? value.toString() : '' };
        });
        return [serialNumber, ...rowData];
      });

      return [headers, ...rows];
    }
    return [];
  }, [treeData]);
  
  const handleDownloadExcel = () => {
    const workbook = new exceljs.Workbook();
    const worksheet = workbook.addWorksheet("BOM");
    spreadsheetData.forEach((row) => {
      worksheet.addRow(row.map((cell) => cell.value));
    });
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "BOM.xlsx");
    }).catch((error) => {
      console.error("Error generating Excel file:", error);
    });
  };

  const HyperlinkCell = ({ cell }) => {
    if (cell && cell.href) {
      return (
        <a href={cell.href} target="_blank" rel="noopener noreferrer"  style={{ color: 'blue', textDecoration: 'underline' }}>
          {cell.value}
        </a>
      );
    }
    return <span>{cell ? cell.value : ""}</span>;
  };

  return (
    <>
   
           <div className="excell-button-download">
        <button onClick={handleDownloadExcel} className="excell-download">
          <img 
            src={`${ASSET_PREFIX_URL}download-icon.png`}
            alt=""
            style={{ width: "20px", height: "20px" }}
          />
          Export to excel
        </button>
      </div>
      <div className="excel-parts">
      {loading ? <Loading excellLoading={true}/>: 
       <>
       <TableContainer component={Paper} className="excel-custom-scrollbar">
       <Table>
         <TableHead>
           <TableRow>
             {spreadsheetData.length > 0 && spreadsheetData[0].map((cell, index) => (
               <TableCell key={index}>{cell.value}</TableCell>
             ))}
           </TableRow>
         </TableHead>
         <TableBody>
           {spreadsheetData.slice(1).map((row, rowIndex) => (
             <TableRow key={rowIndex}>
               {row.map((cell, cellIndex) => (
                 <TableCell key={cellIndex}>
                   <HyperlinkCell cell={cell} />
                 </TableCell>
               ))}
             </TableRow>
           ))}
         </TableBody>
       </Table>
     </TableContainer>
       </>}
       </div>
    
    </>
  );
}

export default ExcelView;



