import React from 'react';
import CloseIcon from "@mui/icons-material/Close";
import './PopupCloseButton.css'; // Use import statement for CSS correctly

function PopupCloseButton({ handleClose }) {


  return (
    <div onClick={() => handleClose()} className="closing-notif">
      <CloseIcon />
    </div>
  );
}

export default PopupCloseButton;
