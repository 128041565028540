import React, { useState, useEffect } from "react";
import { ASSET_PREFIX_URL, DEFAULT_COMPANY_LINK, DEFAULT_PHOTO, DEPTTKTVIEW, LOGIN, PHOTO_LINK } from "../../../constants/config";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import "./Org.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import NameProfile from "../CommonJsx/NameProfile";
import MemberSettings from "../MemberSettings/MemberSettings";
import { menuItems, menuMap, menuNavigationMap } from "../../../constants/sidebarConstants";
import { DASHBOARD } from './../../../constants/config';
import { textLettersLimit } from "../../../constants/helper";

function TicketLeftHOC({
  staredDept,
  photo,
  company,
  memberPhoto,
  memberName, memberEmail

}) {
  const [showPhotos, setShowPhotos] = useState(true);
  const [dropdown, setDropDown] = useState({});
  const [smallNext, setSmallNext] = useState(false);
  const [hoverMenu, setHoverMenu] = useState(false)
  const [hoverSubMenu, setHoverSubMenu] = useState(false)
  const { route } = useParams()
  const [activeMenuItem, setActiveMenuItem] = useState("");
  const [openMemberPopUp, setMemberPopUp] = useState("");
  const { department } = useParams();
  const { id } = useParams();
  const nav = useNavigate();


  const handleOpenDropDownMenu = (section) => {
    setDropDown((prevState) => ({
      ...prevState,
      [section]: !prevState[section], // Toggle the specific section
    }));
    setShowPhotos(true);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      nav(LOGIN);
    }

    return () => { };
  }, [nav]);

  const location = useLocation();

  const handleTogglePhotos = () => {
    setShowPhotos(!showPhotos);

    setHoverMenu(null)
    setHoverSubMenu(null)
    setDropDown('')
    if (!showPhotos) {
      setSmallNext(!smallNext);
    }
  };

  
  const hoveredMenu = (action) => {
    setHoverMenu(action)
  }
 
  const hoveredLeaveMenu = () => {
    setHoverMenu(null)
    setHoverSubMenu(null)
  }
  
  const handleMenuItemClick = (menuItem) => {

    setActiveMenuItem(menuItem);
    setHoverMenu(null);
    setHoverSubMenu(null);
    const navigationMap = menuNavigationMap(route);
  
    if (navigationMap[menuItem]) {
      nav(navigationMap[menuItem]);
    }
  };
  

  const containerStyles = {
    width: showPhotos ? "260px" : "93px",
    transition: "width 0.5s ease",
  };
  useEffect(() => {
    

    const pathname = location.pathname;

    if (pathname.endsWith("/tickets")) {
      setActiveMenuItem("tickets");
    } else if (pathname === DEPTTKTVIEW(route,department,id)) {
      setActiveMenuItem(id);
    } else {
      console.log(pathname)
      setActiveMenuItem(menuMap[pathname] );
    }
  }, [location.pathname, department, id]);


  const handleClick = (department, id) => {
    setActiveMenuItem(id);
    nav(DEPTTKTVIEW(route,department,id))
   
  };


  return (

    <>
      <div className="ticket-template-page">
        <div
          className="next-btn"
          onClick={handleTogglePhotos}
          style={{ cursor: "pointer" }}
        >
          {showPhotos ? (
            <KeyboardArrowLeftRoundedIcon />
          ) : (
            <KeyboardArrowRightRoundedIcon />
          )}
        </div>
        <div style={containerStyles}
          className={`template-sidebar-menu ${smallNext ? "small-next" : ""} ${showPhotos ? "sidebar-expanded" : "sidebar-collapsed"
            }`}>
          <div>

            <div className="menu">

              {Object.keys(menuItems).map(menuKey => {
                const menuItem = menuItems[menuKey];

                // Only render the menu if the route matches the menuKey
                if (route !== menuKey) {
                  return null;
                }

                return (
                  <>
                    {showPhotos ? (
                      <div
                        className={`menu-details`}
                        onClick={() => handleOpenDropDownMenu(menuKey)}
                        style={{ justifyContent: 'space-between' }}
                      >
                        <div className="dropdown-part-create">
                          <img src={`${ASSET_PREFIX_URL}${menuItem.icon}`} alt={menuItem.title} />
                          <span>{menuItem.title}</span>
                        </div>
                        <img src={`${ASSET_PREFIX_URL}template-dropdown.svg`} alt="" className={dropdown[menuKey] ? 'rotated-dropdown' : 'rotated-reverse-dropdown'} />
                      </div>
                    ) : (
                      <div
                        title={menuItem.title}
                        className={`menu-details ${route === menuKey ? "active" : ""}`}
                        onMouseEnter={() => hoveredMenu(menuKey)}
                      >
                        <img src={`${ASSET_PREFIX_URL}${menuItem.icon}`} alt={menuItem.title} /> 
                      </div>
                    )}
                    {dropdown[menuKey] && menuItem.subItems && menuItem.subItems.map(subItem => (
                      <div
                        className={`menu-details-part ${activeMenuItem === subItem.key ? "active" : ""}`}
                        onClick={() => handleMenuItemClick(subItem.key)}
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: "left" }}
                      >
                        <img src={`${ASSET_PREFIX_URL}${subItem.icon}`} alt={subItem.title} style={{ visibility: 'hidden' }} />
                        <span>{subItem.title}</span>
                      </div>
                    ))}
                  </>
                );
              })}
            </div>
            {showPhotos ? (
              <div className="horizontalline"></div>
            ) : (
              <div className="horizontalline1"></div>
            )}
            <div>
              {showPhotos ? (
                <div
                  data-tour="step-depart"
                  className={`menu-details ${activeMenuItem === "tickets" ? "active" : ""
                    }`}
                  onClick={() => handleMenuItemClick("tickets")}
                >
                  <div className="program-template">
                    <img src={`${ASSET_PREFIX_URL}tkt-icon.png`} alt="template" />
                    <span>Tickets</span>
                  </div>
                </div>
              ) : (
                <div
                  title="tickets"
                  className={`menu-details ${activeMenuItem === "tickets" ? "active" : ""
                    }`}
                 
                  onClick={() => handleMenuItemClick("tickets")}
                >
                  <img src={`${ASSET_PREFIX_URL}tkt-icon.png`} alt="template" />
                </div>
              )}
              
            </div>
            <div>
              {showPhotos ? (
                <div
                  data-tour="step-depart"
                  className={`menu-details ${activeMenuItem === "departments" ? "active" : ""
                    }`}
                  onClick={() => handleMenuItemClick("departments")}
                >
                  <div className="program-template">
                    <img src={`${ASSET_PREFIX_URL}programs.svg`} alt="template" />
                    <span>Departments</span>
                  </div>
                </div>
              ) : (
                <div
                  title="departments"
                  className={`menu-details ${activeMenuItem === "departments" ? "active" : ""
                    }`}
                  onMouseEnter={() => hoveredLeaveMenu('supply-chain')}
                  onClick={() => handleMenuItemClick("departments")}
                >
                  <img src={`${ASSET_PREFIX_URL}programs.svg`} alt="template" />
                </div>
              )}
              {showPhotos ? (
                <div style={{display:'flex',flexDirection:'column',gap:'20px',marginBottom:'20px'}}>
                  {staredDept.map((name, index) => (
                    <div
                      className={`prg-org ${activeMenuItem === name.departmentID ? "active" : ""}`}
                      key={index}
                      onClick={() => handleClick(name.departmentName, name.departmentID)}
                    >
                      <span>{name.departmentName}</span>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="prg-org">
                  <span></span>
                </div>
              )}
            </div>
          </div>
          <div className="details-sidebar-div">
            <div>
              {showPhotos ? (

                <div className="back-to-home" onClick={() => nav(DASHBOARD)}>

                  <img style={{ width: '20px', height: '20px' }}
                    src={`${ASSET_PREFIX_URL}House.png`}
                    alt=""
                  />


                  <span>Back to home</span>
                </div>


              ) : (
                <div
                  className="back-to-home"
                  onClick={() => nav(DASHBOARD)}
                  style={{ cursor: "pointer", gap: '0px', justifyContent: 'center' }}
                >
                  <img
                    style={{ width: '20px', height: '20px', padding: '0px' }}
                    src={`${ASSET_PREFIX_URL}House.png`}
                    alt=""
                  />

                </div>
              )}
            </div>
            <div>
              {showPhotos ? (
                <div
                  className="prf-setting"
                  style={{ cursor: "pointer" }}
                  onClick={() => setMemberPopUp("settings1")}
                >
                  <div className="user-prf">
                    <div className="prf-picture">
                      {memberPhoto ? <img
                        src={`${PHOTO_LINK}${memberPhoto}`}
                        alt=""
                      /> : <NameProfile userName={memberName} memberPhoto={memberPhoto} width="40px" fontSize='24px' fontweight='500' />}

                     

                      <div className="online-status"></div>
                    </div>

                    <span>{memberName}</span>
                  </div>
                  <SettingsOutlinedIcon />
                </div>
              ) : (
                <div
                  className="prf-picture"
                  onClick={() => setMemberPopUp("settings2")}
                  style={{ cursor: "pointer" }}
                >
                  {memberPhoto ? <img
                    src={`${PHOTO_LINK}${memberPhoto}`}
                    alt=""
                  /> : <NameProfile userName={memberName} memberPhoto={memberPhoto} width="40px" fontSize='24px' fontweight='500' />}
                  <div className="online-status"></div>
                </div>
              )}
            </div>
            <div>
              {showPhotos ? (
                <div className="profile-container">
                  {photo ? <img src={`${PHOTO_LINK}${photo}`} alt={company} style={{ width: '50px', height: '50px', borderRadius: '8px' }} /> :
                    <NameProfile userName={company} memberPhoto={photo} width="50px" fontSize='24px' fontweight='500' borderRadius='8px' />}

                  <div className="profile-details">
                    <span className="org-name" title={company}>{textLettersLimit(company,16)}</span>
                    <span className="org-type">Hardware project</span>
                  </div>
                </div>
              ) : (
                <div className="profile-container">
                  {photo ? <img src={`${PHOTO_LINK}${photo}`} alt={company} style={{ width: '50px', height: '50px', borderRadius: '8px' }} /> :
                    <NameProfile userName={company} memberPhoto={photo} width="50px" fontSize='24px' fontweight='500' borderRadius='8px' />}
                </div>
              )}
            </div>
          </div>

        </div>
      </div>

      {hoverMenu && (
        <div className="menu-absolute-div-sub" style={{ top: '100px' }} onMouseLeave={() => hoveredLeaveMenu(hoverMenu)}>
          <div className="menu-absolute-div-list">
            {menuItems[hoverMenu]?.subItems.map(subItem => (
              <div
                className={`absolute-menus ${activeMenuItem === subItem.key ? "active" : ""}`}
                onClick={() => handleMenuItemClick(subItem.key)}
              >
                <span>{subItem.title}</span>
              </div>
            ))}
          </div>
        </div>
      )}


      {openMemberPopUp === 'settings1' && (
        <div className="member-settings-page-div" style={{ bottom: '0px', left: '275px' }}>
          <MemberSettings
            onClose={() => setMemberPopUp(!openMemberPopUp)}
            memberPhoto={memberPhoto}
            memberEmail={memberEmail}
            memberName={memberName}
          />

        </div>

      )}
      {openMemberPopUp === 'settings2' && (
        <div className="member-settings-page-div" style={{ bottom: '0px', left: '100px' }}>
          <MemberSettings
            onClose={() => setMemberPopUp(!openMemberPopUp)}
            memberPhoto={memberPhoto}
            memberEmail={memberEmail}
            memberName={memberName}
          />

        </div>

      )}

    </>


  );
}

export default TicketLeftHOC;