import React from "react";
import "./TeamRich.css";
import { ASSET_PREFIX_URL } from "../../../constants/config";

function TeamRich() {
  return (
    <div className="team-rich-page" id='team-rich'>
      <div className="team-rich-container">
       
          <img src={`${ASSET_PREFIX_URL}desktop-org.webp`} alt=""  className="team-rich-img" loading="lazy"/>
          <img src={`${ASSET_PREFIX_URL}team-rich-mb-img.webp`} alt=""  className="team-rich-img-mb" loading="lazy"/>
       
        <div className="team-rich-content">
          <span className="team-rich-head">TEAM RICH</span>
          <span className="team-rich-title">Tailored for small to mid-sized businesses:</span>
          <span className="team-rich-desc">
            Affordable, User-Friendly Data Solutions. No Specialised Staff
            Required. Pay Only for What You Use. Empower Your Business with
            Essential Data in Today's Data-Driven World.
          </span>
          <button className="team-rich-btn">Join the Waitlist</button>
        </div>
      </div>
    </div>
  );
}

export default TeamRich;
