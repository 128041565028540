import React from 'react';
import './PoDownload.css';

function SupplierDetailsPo({ supplierDetails, compType, generatePoNumber, selectedAddresses }) {
  
    if (!supplierDetails) {
        return null; // Return null or some default content if supplierDetails is not provided
    }

    // Filter supplierDetails based on compType
    const filteredDetails = compType === 'supply'
        ? Object.fromEntries(Object.entries(supplierDetails).filter(([key, value]) => value.is_default))
        : supplierDetails;

    const fields = Object.keys(filteredDetails);

    const renderValue = (field) => {
        if (field.inputType === 'multiselect' && Array.isArray(field.value)) {
            return field.value.join(', ');
        }
        return field.value;
    };

    return (
        <div className='po-download-supply-page'>
            {compType === 'supply' && (
                <div className='po-download-heads'>
                    <span>PO number: {generatePoNumber}</span>
                </div>
            )}
            
            {compType === 'supply' && <div className='horizontal-line-po'></div>}
            <div> 
                {compType === 'supply' && 'VENDOR DETAILS'}
            </div>
            <div className='po-download-supply-fields-heads-details'>
                {fields.map((fieldName, index) => {
                    const field = filteredDetails[fieldName];
                    if (field.inputType === 'select-person'||field.fieldName === 'Status') {
                        return null; // Skip rendering this field
                    }
                    return (
                        <div className='po-download-supply-details' key={index}>
                            <span className='po-download-supply-details-key'>{field.fieldName}</span>
                            <span className='po-download-supply-details-value'>: &nbsp; {renderValue(field)}</span>
                        </div>
                    );
                })}
                {compType === 'default' && 
                 <div className='po-download-supply-details' >
                 <span className='po-download-supply-details-key'>Delivery address</span>
                 <span className='po-download-supply-details-value'>: &nbsp; {selectedAddresses}</span>
             </div>}
               
            </div>
        </div>
    );
}

export default SupplierDetailsPo;
