

import React, { useState, useEffect } from "react";
import '../DepartmentPages/DepartmentForm.css'
import axios from "axios";
import Cookies from "js-cookie";
import Select from "react-select";
import {
    ASSET_PREFIX_URL,
    BASE_URL,
    WORKSPACE,
    WORKSPACE_VISIBILITY_OPTIONS,
    HEADERS
} from "../../constants/config";
import PopupCloseButton from "../Common/PopupCloseButton/PopupCloseButton";
import NameProfile from "../Common/CommonJsx/NameProfile";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";




function CreateWorkspacePopUp({ onClose}) {
    const [workspaceName, setWorkspaceName] = useState("");
    const [description, setDescription] = useState("");
    const [visibility, setVisibility] = useState(null);
    const [teamMembers, setTeamMembers] = useState([]);
    const [allMembers, setAllMembers] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false); // Track submission status
    const [fieldErrors, setFieldErrors] = useState({});
    const nav = useNavigate();

    useEffect(() => {
        fetchAllMembers();
    }, []);

    const fetchAllMembers = async () => {
        try {
            const memberResponse = await axios.get(BASE_URL + "/v1/setting/get-mem", {
                headers: HEADERS,
            });
            if (!memberResponse.data?.meta?.success) {
                toast.error(memberResponse.data?.meta.message)
            } else{
                setAllMembers(memberResponse.data.data.memberList)
            }
            // setSelectMember(response.data.data);
        } catch (error) {
            console.error("Error fetching options data:", error);
        }
    };
    const handlePersonSelectChange = (fieldName, value) => {
        setTeamMembers({ fieldName, value });
    };

    const validateForm = () => {
        const errors = {};

        // 1. Validate Workspace Name (No special characters)
        const nameRegex = /^[a-zA-Z0-9\s]+$/;
        if (!workspaceName) {
            errors.workspaceName = "Workspace name is required.";
        } else if (!nameRegex.test(workspaceName)) {
            errors.workspaceName = "Workspace name cannot contain special characters.";
        }

        if (!visibility) {
            errors.visibility = "Visibility is required.";
        }

        // 2. Validate Team Members (if private visibility is selected)
        if (visibility?.value === "private" && teamMembers.length === 0) {
            errors.teamMembers = "At least one team member must be selected.";
        }

        setFieldErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const submitForm = async (e) => {
        e.preventDefault(); // Prevent page reload on form submission
        if (!validateForm()) return; // Stop if validation fails
        setIsSubmitting(true); // Disable submit button

        const formData = {
            workspace_name: workspaceName,
            description,
            visibility: visibility?.value,
            team_members: teamMembers.map((member) => member.value),
        };

        try {
            const response = await axios.post(`${BASE_URL}/v1/pdm/create-workspace`, formData, {
                headers: HEADERS,
            });
            console.log("Form submitted successfully:", response.data);
            if (!response.data?.meta?.success) {
                setFieldErrors({workspaceName: response.data?.meta.message});
            } else {
                nav(WORKSPACE(response.data?.data.sequence_id))
            }
            // navigate to details page
        } catch (error) {
            console.error("Error submitting form:", error);
        } finally {
            setIsSubmitting(false); // Re-enable submit button
        }
    };


    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: "369px",
            backgroundColor: "transparent",
            border: "1px solid #EDF2F7",
            boxShadow: "none",
        }),
        menu: (provided) => ({
            ...provided,
            maxWidth: "369px", // Set the maximum width for the dropdown menu
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
        }),

        option: (provided, state) => ({
            ...provided,
            background: state.isSelected ? "transparent" : "white",
            color: state.isSelected ? "black" : "inherit",
            cursor: "pointer",
            ":hover": {
                background: "lightgray",
            },
        }),


    };
    return (
        <>
            <div className="ec-form-page">
                <div className="ec-ticket-home">
                    <div className="ex-ticket-title">
                        <span className="ec-title">Create new workspace
                        </span>
                        <PopupCloseButton handleClose={onClose} />
                    </div>
                    <div className="form-grid" style={{ marginTop: '20px', width: '100%' }}>
                        <div className="grid-item">
                            <span>Workspace Name*</span>
                            <div className="type-texts">
                                <input
                                    type="text"
                                    value={workspaceName}
                                    onChange={(e) => setWorkspaceName(e.target.value)}
                                    placeholder="e.g., Battery Project Alpha"
                                />

                            </div>
                            {fieldErrors.workspaceName && (
                                <span className="error-message">
                                    <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                    {fieldErrors.workspaceName}
                                </span>
                            )}
                        </div>
                        <div className="grid-item">
                            <span>Description</span>
                            <div className="type-texts">
                                <textarea
                                    type="text"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder="A short description of the workspace’s purpose or goals"
                                />
                            </div>
                        </div>
                        <div className="grid-item">
                            <span>Visibility</span>
                            <Select
                                options={WORKSPACE_VISIBILITY_OPTIONS}
                                value={visibility}
                                onChange={setVisibility}
                                placeholder="Select visibility"
                            />
                            {fieldErrors.visibility && (
                                <span className="error-message">
                                    <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                    {fieldErrors.visibility}
                                </span>
                            )}
                        </div>
                        {(visibility?.value == 'private') && (<div className="grid-item">
                            <span>Team Members</span>
                            <Select
                                placeholder="Add members"
                                styles={{
                                    ...customStyles,
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure it's above other elements
                                }}
                                menuPortalTarget={document.body} // Render the menu in the body
                                isMulti={true}
                                options={allMembers.map((member) => ({
                                    value: member._id, // Use full name as the value
                                    label: (
                                        <div
                                            className="member-option"
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "10px",
                                            }}
                                        >
                                            <NameProfile userName={member.fullName} width='26px' memberPhoto={member.photo} fontweight='500' />

                                            {member.fullName}
                                        </div>
                                    ),
                                }))}
                                onChange={setTeamMembers}
                            />
                            {fieldErrors.teamMembers && (
                                <span className="error-message">
                                    <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                    {fieldErrors.teamMembers}
                                </span>
                            )}
                            {/* <Select
                                // options={teamMembersOptions}
                                isMulti
                                value={teamMembers}
                                onChange={setTeamMembers}
                                placeholder="Assign users or roles"
                            /> */}

                        </div>)}
                    </div>
                    <div className="depart-form-btns">
                        <button className="dept-form-submit" onClick={submitForm} disabled={isSubmitting}>
                            submit
                        </button>
                    </div>
                </div>
            </div>
        </>

    );
}

export default CreateWorkspacePopUp;
