import React, { useState, useEffect } from "react";
import { BASE_URL, HEADERS, INTERNALTRANSFERVIEW, LOCATIONVIEW, PHOTO_LINK, WAREHOUSEVIEW } from "../../constants/config";
import Loading from "../Common/Loading/Loading";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Pagination from "../Common/CommonJsx/Pagination";
import NameProfile from "../Common/CommonJsx/NameProfile";
import SearchFilter from "../Common/CommonJsx/SearchFilter";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./TicketList.css";
import CreateInternalTransfer from "../InternalTransfers/CreateInternalTransfer";
import DateFormateForList from "../Common/CommonJsx/DateFormateForList";

function InternalTransferList() {
  const [transfers, setTransfers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [resetButton, setResetButton] = useState(false);
  const nav = useNavigate();
  const [createWarehouse, setCreateWarehouse] = useState(false);
  const [updateData, setUpdateData] = useState("");
  const fetchLocations = async () => {
    try {
      setLoading(true);


      const response = await axios.get(
        `${BASE_URL}/v1/location/get-internal-transfer-list`,
        {
          params: {
            page: currentPage,
            limit: 10,
            search: searchTerm,
          },
          headers: HEADERS,
        }
      );

      const details = response.data.data.list_count;
      const paginationDetails = response.data.data.pagination;
      setTransfers(details);
      setTotalPages(paginationDetails.total_pages);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };



  useEffect(() => {
    fetchLocations();
  }, [currentPage, searchTerm,updateData]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setResetButton(true);
  };

  const handleReset = () => {
    setSearchTerm("");
    setResetButton(false);
    setCurrentPage(1);
  };

  const openCreatePartPopUp = () => {
    setCreateWarehouse(!createWarehouse);
  };


  const handleNavClick = (event, id) => {
    event.stopPropagation();
    window.open(LOCATIONVIEW(id), '_blank'); // Opens in a new tab
};

  return (
    <>

      <div className="depart-main" style={{ marginTop: "80px" }}>
        <div className="part-number-list-title">
          <span>Internal transfers</span>
          <div className="part-number-list-btns">
            <button onClick={openCreatePartPopUp}>
              <AddOutlinedIcon />
              Create new transfer
            </button>
          </div>
        </div>

        <div className="tck-fltr">
          <SearchFilter
            searchTerm={searchTerm}
            handleSearchChange={handleSearchChange}
            listType="transfer..."
          />
          {resetButton && (
            <button className="reset-btn" onClick={handleReset} data-tour="step-11">
              reset <TuneOutlinedIcon />
            </button>
          )}
        </div>

     
      
              <div className="department-table">
                <table className="department-table-data">
                  <thead>
                    <tr>
                    <th className="depart-header">Transfer ID</th>
                    <th className="depart-header">Source</th>
                    <th className="depart-header">Destination</th>
                    <th className="depart-header">Transfer by</th>
                    <th className="depart-header">Date</th>
                    <th className="depart-header">Number of parts</th>
                      {/* <th className="tck-header">Manager</th>
                      <th className="tck-header">Address</th> */}
                    </tr>
                  </thead>
                  {loading ? <Loading/> :  <tbody>
                    {transfers.map((internalItems) => {
                      return (
                        <tr key={internalItems._id} onClick={() => nav(INTERNALTRANSFERVIEW(internalItems.internal_transfer_seq_id))}>
                          <td>{internalItems.internal_transfer_seq_id}</td>
                          <td><a style={{color:'blue'}} onClick={(e)=>handleNavClick(e,internalItems.source_location_seq_id)}>
                            {internalItems.source_location_seq_id}</a></td>
                          <td><a style={{color:'blue'}} onClick={(e)=>handleNavClick(e,internalItems.destination_location_seq_id)}>
                            {internalItems.destination_location_seq_id}</a></td>
                          <td><div
                                  className="member-option"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <NameProfile userName={internalItems.member_name}
                                    memberPhoto={internalItems.member_photo} width="26px" fontweight='500' />


                                  <span className="member-fullname">
                                    {
                                      internalItems.member_name
                                    }
                                  </span>
                                </div></td>
                          <td><DateFormateForList formateTime={internalItems.createdAt}/></td>
                          <td>{internalItems.partsCount}</td>
                        </tr>
                      );
                    })}
                  </tbody>}
                 
                </table>
              </div>
           

        {(totalPages > 1 && !loading)  && (
          <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
        )}
      </div>
     
      {createWarehouse && <CreateInternalTransfer handleClose={() => setCreateWarehouse(!createWarehouse)} setUpdateData={setUpdateData} />}
    </>
  );
}

export default InternalTransferList;
