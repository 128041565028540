import React from 'react'
import CloseIcon from "@mui/icons-material/Close";
import './TemplateConfirmPopUp.css'

function TemplateConfirmPopUp({ onclose, onsave, versionDefault, ondefault,type,onEcSave,
    onEcDefault }) {
    const handleclose = () => {
        onclose()
    }
    const handleSave = () => {
       
            ondefault(versionDefault,'non-default')
        
       
    }
    const handleDefault = () => {
    
        onsave(versionDefault,'default')
      
        
    }
    return (
        <div className='default-popup'>
            <div className='default-popup-container'>
                <div className='closing-div'>
                <div onClick={handleclose} className='default-closing'>
                    <CloseIcon />
                </div>
                </div>
               
                <div className='default-message'>
                    <span>Are you sure you want to update this template?</span>


                </div>
                <div className='default-btns'>
                    <button onClick={handleSave}>No</button>
                    <button onClick={handleDefault}>Yes</button>
                </div>
            </div>
        </div>
    )
}

export default TemplateConfirmPopUp