import { ASSETLIST, ASSETNUMBER, ASSETPARTS, ASSETPTEMPLATE, DEPLIST,
     DEVLIST, ECLIST, ECTEMPLATE, GRNLIST, INTERNALTRANFERS, INVENTORY,
      LAUNCHEDPARTS, LOCATIONINVENTORY, LOCATIONLIST, LOCATIONTEMPLATE, 
      MATERIALLIST, MBOMATTRIBUTES, ORGHOME, OVERVIEW, PARTLIST, PARTNUMBER,
       PCILIST, PCLIST, PCTEMPLATE, POLIST, PONUMBER, POPTEMPLATE, PRODUCTIONLIST,
        PRODUCTS, SETTINGS, SUPLIST, SUPTEMPLATE, TKTLIST, TKTTEMPLATE, WAREHOUSELIST,
         WAREHOUSETEMPLATE, WORKORDER, ZONESLIST, ENG_WORKSPACES } from "./config";


export const menuItems = {
    templates: {
        title: "Templates",
        icon: "template_icon.svg",
        dropdown: true,
        subItems: [
            { key: "template", title: "Template",  },
            // { key: "tickets", title: "Tickets",  },
            { key: "ec-template", title: "EC Template",  },
            { key: "part-num", title: "Part naming",  },
            { key: "assets-number", title: "Asset naming",  },
            { key: "asset-templates", title: "Asset template",  },
            { key: "supply-template", title: "Supplier",  },
            { key: "po-number", title: "PO number",  },
            { key: "po-template", title: "PO",  },
            { key: "pc-template", title: "PC Template",  },
            { key: "mbom-attributes", title: "MBOM attributes",  },
            { key: "warehouse-template", title: "Warehouse template",  },
            { key: "location-template", title: "Location template",  }
        ]
    },
    hr: {
        title: "HR",
        icon: "hr_icon.svg",
        dropdown: true,
        subItems: [
            { key: "teams", title: "Hierarchy" }
        ]
    },
    engineering: {
        title: "Engineering",
        icon: "eng_icon.svg",
        dropdown: true,
        subItems: [
            { key: "create-part", title: "Parts"},
            { key: "ec-ticket", title: "Engg. change (EC)"},
            { key: "workspaces", title: "Workspaces"},
        ]
    },
    supplychain: {
        title: "Supply chain",
        icon: "supplier_icon.svg",
        dropdown: true,
        subItems: [
            { key: "supply-list", title: "Suppliers",  },
            { key: "po-list", title: "Parts PO",  },
            { key: "assets-list", title: "Assets PO",  },
            { key: "parts-grn", title: "Parts GRN",  },
            { key: "inventory-list", title: "Inventory",  },
        ]
    },
    manufacturing: {
        title: "Manufacturing",
        icon: "manufac_icon.svg",
        dropdown: true,
        subItems: [
            { key: "pc-list", title: "PC",  },
            { key: "pci-list", title: "PCI",  },
            { key: "launched-parts", title: "Launched parts",  },

        ]
    },
    production: {
        title: "Production",
        icon: "production_icon.svg",
        dropdown: true,
        subItems: [
            { key: "mbom-list", title: "Products",  },
            { key: "production-list", title: "Production schedule",  },
            { key: "work-order-list", title: "Work order",  },
            { key: "material-list", title: "Material planning",  },

        ]
    },
    // pdm: {
    //     title: "PDM",
    //     icon: "pdm-icon.png",
    //     dropdown: true,
    //     subItems: [
    //         { key: "workspaces", title: "Workspaces", icon: "parts-icon.png" },
    //     ]
    // },
    wms: {
        title: "Warehouses",
        icon: "wms_icon.svg",
        dropdown: true,
        subItems: [
            { key: "warehouse-list", title: "Warehouses",  },
            { key: "location-list", title: "Locations",  },
            // { key: "zones-list", title: "Zones",  },
            // { key: "location-inventory-list", title: "Inventory",  },
            { key: "internal-trasfer", title: "Internal transfer",  },
            // { key: "production-list", title: "Production schedule",  },
            // { key: "work-order-list", title: "Work order",  },
            // { key: "material-list", title: "Material planning",  },

        ]
    },
};




export const menuMap = {
    "/hr/org-home": "teams",
    "/templates/part-number": "part-num",
    "/templates/template": "template",
    "/templates/ec-template": "ec-template",
    "/templates/asset-template": "asset-templates",
    "/templates/supply-chain-templates": "supply-template",
    "/templates/purchase-order-templates": "po-template",
    "/templates/po-number": "po-number",
    "/templates/assets-number": "assets-number",
    "/supplychain/all-supplier-list": "supply-list",
    "/production/mbom-list": "mbom-list",
    "/manufacturing/pci-list": "pci-list",
    "/manufacturing/launched-parts": "launched-parts",
    "/manufacturing/pc-list": "pc-list",
    "/manufacturing/pc-launched-list": "pc-launched-list",
    "/supplychain/all-po-list": "po-list",
    "/templates/pc-templates": "pc-template",
    "/templates/mbom-attributes": "mbom-attributes",
    "/supplychain/assets-list": "assets-list",
    "/production/production-list": "production-list",
    "/production/work-order-list": "work-order-list",
    "/production/material-list": "material-list",
    "/overview": "overview",
    "/depart-list": "departments",
    "/settings": "settings",
    "/tickets": "tickets",
    "/engineering/all-ec-list": "ec-ticket",
    "/engineering/all-deviation-list": "ec-deviation",
    "/supplychain/all-inventory": "inventory-list",
    "/engineering/part-list": "create-part",
    "/assets-part-list": "assets-part-list",
    "/all-grn-parts": "parts-grn",
    "/warehouse-templates":"warehouse-template",
    "/location-templates": "location-template",
    "/location-list": "location-list",
    "/warehouse-list": "warehouse-list",
    "/internaltransfer-list": "internal-trasfer",
    "/inventory-list": "location-inventory-list",
    "/zones-list": "zones-list",
    "/workspaces": "workspaces",
    "/supplychain/all-grn-parts": "parts-grn",
    "/templates/warehouse-templates":"warehouse-template",
    "/templates/location-templates": "location-template",
    "/wms/location-list": "location-list",
    "/wms/warehouse-list": "warehouse-list",
    "/wms/internaltransfer-list": "internal-trasfer",
    "/wms/inventory-list": "location-inventory-list",
    "/wms/zones-list": "zones-list",
};




export const menuNavigationMap = (route) => ({
    "teams": ORGHOME,
    "part-num": PARTNUMBER,
    "template": TKTTEMPLATE,
    "inventory-list": INVENTORY,
    "ec-template": ECTEMPLATE,
    "asset-templates": ASSETPTEMPLATE,
    "assets-number": ASSETNUMBER,
    "supply-template": SUPTEMPLATE,
    "pc-template": PCTEMPLATE,
    "mbom-attributes": MBOMATTRIBUTES,
    "po-template": POPTEMPLATE,
    "po-number": PONUMBER,
    "tickets": TKTLIST(route),
    "production-list": PRODUCTIONLIST,
    "work-order-list": WORKORDER,
    "material-list": MATERIALLIST,
    "parts-grn": GRNLIST,
    "supply-list": SUPLIST,
    "mbom-list": PRODUCTS,
    "pci-list": PCILIST,
    "launched-parts": LAUNCHEDPARTS,
    "pc-list": PCLIST,
    "po-list": POLIST,
    "assets-list": ASSETLIST,
    "assets-part-list": ASSETPARTS,
    "ec-ticket": ECLIST,
    "ec-deviation": DEVLIST,
    "overview": OVERVIEW,
    "departments": DEPLIST(route),
    "create-part": PARTLIST,
    "settings": SETTINGS(route),
    "warehouse-list":WAREHOUSELIST,
    "location-list":LOCATIONLIST,
    "warehouse-template":WAREHOUSETEMPLATE,
    "location-template":LOCATIONTEMPLATE,
    "internal-trasfer": INTERNALTRANFERS,
    "location-inventory-list": LOCATIONINVENTORY,
    "zones-list": ZONESLIST,
    "workspaces": ENG_WORKSPACES,


});
