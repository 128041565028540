import React from "react";
import "./Complaince.css";
import { ASSET_PREFIX_URL } from "../../../constants/config";

function Compliance() {
  return (
    <div className="compliance-page" id='compliance'>
      <div className="compliance-container">
        <div className="compliance-content">
          <span className="compliance-head">Compliance Certifications</span>
          <span className="compliance-desc">
            We comply with the following industry-accepted standards to help you
            ensure your data is secure and compliant.
          </span>
        </div>
        <div className="compliance-imgs">
          <div className="compliance-imgs-1">
            <img src={`${ASSET_PREFIX_URL}iso1.webp`} alt="" className="compliance-imgs-2"/>
            <span>ISO 27001</span>
          </div>
          <div className="compliance-imgs-1">
            <img src={`${ASSET_PREFIX_URL}iso2.webp`} alt="" className="compliance-imgs-2"/>
            <span>ISO 27017</span>
          </div>
          <div className="compliance-imgs-1">
            <img src={`${ASSET_PREFIX_URL}iso3.webp`} alt="" className="compliance-imgs-2"/>
            <span>ISO 27018</span>
          </div>
          <div className="compliance-imgs-1">
            <img src={`${ASSET_PREFIX_URL}soc.webp`} alt="" className="compliance-imgs-3"/>
            <span>Soc-2 type II</span>
          </div>
          <div className="compliance-imgs-1">
            <img src={`${ASSET_PREFIX_URL}truste.webp`} alt="" className="compliance-imgs-4"/>
            <span>Trust-e</span>
          </div>
          <div className="compliance-imgs-1">
            <img src={`${ASSET_PREFIX_URL}gdpr.webp`} alt="" className="compliance-imgs-5"/>
            <span>GDPR</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Compliance;
