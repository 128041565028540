import React from 'react';
import Select from 'react-select';

function PoNamingView({ data }) {
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: "292px",
            backgroundColor: "white",
            border: "1px solid #edf2f7",
            boxShadow: "none",
            color: 'black',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
        }),
        option: (provided, state) => ({
            ...provided,
            background: "white",
            color: "black",
            cursor: "pointer",
            ":hover": {
                background: "#610BEE",
                color: "white",
            },
        }),
        singleValue: (provided, state) => ({
            ...provided,
            backgroundColor: "transparent",
            color: "black",
            padding: "5px 10px",
            borderRadius: "4px",
            fontSize: "14px",
        }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: "14px",
            color: "black",
        }),
        menu: (provided) => ({
            ...provided,
            width: "292px",
            backgroundColor: "white",
            color: "black",
        }),
        menuList: (provided) => ({
            ...provided,
            backgroundColor: "white",
        }),
    };

    // Convert data into an array of options for Select component
    const options = Object.keys(data).map(key => ({
        value: key,
        label: data[key],
    }));

    return (
        <div className='create-part-num-div-form-dynamic'>
            {options.map((option, index) => (
                <div key={index}>
                    <div className='create-part-num-div-form-label-input'>
                        <span>{option.value}</span>
                        <div className='create-part-num-div-form-input'>
                            <Select
                            isDisabled
                                styles={customStyles}
                                options={options}
                                value={option} // Assuming you want to set the default value based on the options
                            />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default PoNamingView;
