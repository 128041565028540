import React from 'react'
import { ASSET_PREFIX_URL } from "../../constants/config";

function Logo() {
  return (
    <div>
         <a href='/'><img src={`${ASSET_PREFIX_URL}logo-desktop.svg`} alt="logo" className="reg-logo"/></a>
    </div>
  )
}

export default Logo;
