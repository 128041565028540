import React, { useEffect, useState } from 'react';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import '../PopUpsFolder/Notification.css'
import PopupCloseButton from '../Common/PopupCloseButton/PopupCloseButton'
import Select from "react-select";
import Cookies from 'js-cookie';
import axios from 'axios';
import { ASSET_PREFIX_URL, ASSETVIEW, BASE_URL, TICKET_ATTACHMENT_BUCKET, HEADERS, CATEGORY_UPLOAD_ASSETS, BUCKET } from "../../constants/config";
import { simpleUpload, multiUpload } from "../../constants/helper"
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import AttachmentsFunction from '../Common/CommonJsx/AttachmentsFunction';
import { useNavigate } from 'react-router-dom';
import NameProfile from '../Common/CommonJsx/NameProfile';
import MemberDropdown from '../Common/MemberDropdown';


function CreateAsset({ handleClose,
    rightSectionItems,
    attributeOptions,
    setRightSectionItems,
    getPartNumberList, partLengthOfList }) {

    const nav = useNavigate()
    const [templateId, setTemplateId] = useState("");
    const [counterValues, setCounterValues] = useState([]);
    const [addButton, setAddButton] = useState(Array(rightSectionItems.length).fill(true));
    const [minusButton, setMinusButton] = useState(Array(rightSectionItems.length).fill(false));
    const [partNumberOptions, setPartNumberOptions] = useState([]);
    const [partTitle, setpartTitle] = useState('');
    const [partDesc, setpartDesc] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [fields, setFields] = useState([]);
    const [formData, setFormData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [uploadError, setUploadError] = useState('');
    const [fileSizeError, setFileSizeError] = useState('');
    const [errors, setErrors] = useState('')
    const [activePage, setActivePage] = useState(1);
    const LIMIT = 20;
    const [optionsCount, setOptionsCount] = useState('')
    const [search, setSearch] = useState('');
    const [data, setData] = useState({});
    const getPartNumberChildList = async () => {
        try {
            //   setLoading(true)
            const headers = {
                "x-auth-token": localStorage.getItem("token"),
            };
            const listofPartNumber = await axios.get(
                `${BASE_URL}/v1/part-nomen/get-list-part-number`,
                {
                    params: {
                        page: activePage,
                        limit: LIMIT,
                        // part_action: partAction,
                        search: search, // Include partAction if specified
                    },
                    headers: headers,
                }
            );
            // get-asset-fields
            const fields = await axios.get(`${BASE_URL}/v1/asset/get-asset-fields`, { headers },)
            setFields(fields.data.data.fields)
            setPartNumberOptions(search !== '' ? listofPartNumber.data.data.part_number_list : (prevNotifications) => [...prevNotifications, ...listofPartNumber.data.data.part_number_list]);
            setTemplateId(fields.data.data.fields[0].asset_template_id);
            setOptionsCount(listofPartNumber.data.data.count_part_list)
            //   setPartNumbers(listofPartNumber.data.data.part_number_list)

            //   setTotalPages(listofPartNumber.data.data.total_pages);
            setActivePage(search === '' && activePage + 1);
            //   setLoading(false)
        } catch (error) {
            console.log(error);
        }
    };
 
    useEffect(() => {
        if (search !== '') {
            const delayTimer = setTimeout(() => {
                getPartNumberChildList();
            }, 1000); // Adjust the debounce delay (in milliseconds) as needed

            return () => clearTimeout(delayTimer);
        }
        else {
            getPartNumberChildList();
        }
        // Clear the timer on each input change

    }, [search]);




    const handleCounterIncrease = (value, meaning, position, action, is_unique, item) => {

        const lastCharacter = value.slice(-1);
        const isAlphabetic = isNaN(parseInt(lastCharacter));

        const newValue = isAlphabetic
            ? value.slice(0, -1) + String.fromCharCode(lastCharacter.charCodeAt(0) + 1)
            : value.slice(0, -1) + (parseInt(lastCharacter, 10) + 1);

        setCounterValues((prevValues) => {
            const updatedValues = [...prevValues];
            updatedValues[position] = newValue;
            return updatedValues;
        });

        const updatedRightSectionItems = [...rightSectionItems];
        updatedRightSectionItems[position].default_value = is_unique ? incrementValue(item) : newValue;

        setRightSectionItems(updatedRightSectionItems);

        // Update the specific button state
        setAddButton((prevButtons) => {
            const updatedButtons = [...prevButtons];
            updatedButtons[position] = false;
            return updatedButtons;
        });
        setMinusButton((prevButtons) => {
            const updatedButtons = [...prevButtons];
            updatedButtons[position] = true;
            return updatedButtons;
        });
    };

    const handleDecrement = (value, meaning, position, action) => {
        const lastCharacter = value.slice(-1);
        const isAlphabetic = isNaN(parseInt(lastCharacter));

        let newValue;
        if (isAlphabetic) {
            // Decrement the alphabetic character
            newValue = value.slice(0, -1) + String.fromCharCode(lastCharacter.charCodeAt(0) - 1);
        } else {
            const numericValue = parseInt(lastCharacter, 10);
            // Ensure the value is greater than 0 before decrementing
            if (numericValue > 0) {
                newValue = value.slice(0, -1) + (numericValue - 1);
            } else {
                // Optionally handle if the value is already at 0
                newValue = value;
            }
        }

        setCounterValues((prevValues) => {
            const updatedValues = [...prevValues];
            updatedValues[position] = newValue;
            return updatedValues;
        });

        const updatedRightSectionItems = [...rightSectionItems];
        updatedRightSectionItems[position].default_value = newValue;
        setRightSectionItems(updatedRightSectionItems);

        // Update the specific button state
        setAddButton((prevButtons) => {
            const updatedButtons = [...prevButtons];
            updatedButtons[position] = true;
            return updatedButtons;
        });
        setMinusButton((prevButtons) => {
            const updatedButtons = [...prevButtons];
            updatedButtons[position] = false;
            return updatedButtons;
        });
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: "340px",
            backgroundColor: "transparent",
            border: "1px solid #EDF2F7",
            boxShadow: "none",
        }),

        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
        }),

        option: (provided, state) => ({
            ...provided,
            background: state.isSelected ? "transparent" : "white",
            color: state.isSelected ? "black" : "inherit",
            cursor: "pointer",
            ":hover": {
                background: "lightgray",
            },
        }),


    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];

        handleFile(file);
    };
    const upload = () => {
        document.getElementById("fileupld").click();
    };
    const handleFileDrop = async (event) => {
        event.preventDefault();
        // setDragging(false);

        const files = event.dataTransfer.files;
        if (files.length > 0) {
            const file = files[0];

            handleFile(file);
        }
    };
    const handleDragOver = (event) => {
        event.preventDefault();
        // setDragging(true);
    };

    const handleFile = async (file) => {
        setFileSizeError('');
        setUploadError('');
        const fileSizeMB = file.size / (1024 * 1024);  // size in MB
        if (fileSizeMB > 2) {
            setFileSizeError('File size cannot be more than 2 MB');  // Notify the user
            return;  // Exit the function
        }
        //fetch pre signed URL
        setIsLoading(true);
        try {
            const preSignedURL = await axios.post(
                `${BASE_URL}/v1/member/get-presigned-url`,
                { bucket_name: BUCKET, file: file.name, category: CATEGORY_UPLOAD_ASSETS, filesize: fileSizeMB },
                { headers: HEADERS }
            );
            if (preSignedURL.data.meta.code === 200 && preSignedURL.data.meta.message === 'SUCCESS' && preSignedURL.data.data.url) {
                //upload to s3
                // await axios.put(preSignedURL.data.data.url, file, {
                //     headers: {
                //         'Content-Type': file.type
                //     }
                // });
                if (preSignedURL.data.data.is_mutipart) {
                    await multiUpload(preSignedURL.data.data, file, HEADERS);
                } else {
                    await simpleUpload(preSignedURL.data.data, file);
                }

                setUploadedFiles(prevUploadedFiles => [...prevUploadedFiles, { name: file.name, key: preSignedURL.data.data.key }]);
            } else {
                alert('Error generating signed URL')
            }
        } catch (e) {

            setUploadError('Error in uploading file');
        } finally {
            // Set isLoading back to false after upload (whether success or failure)
            setIsLoading(false);
        }
    }



    const handleSelectChange = (selected, meaning, position, default_value) => {
        const updatedRightSectionItems = [...rightSectionItems];

        updatedRightSectionItems[position].default_value = selected.value ? selected.value : default_value;



        setRightSectionItems(updatedRightSectionItems);


    };


    const handlemeaningChange = (index, value, meaning, default_value) => {
        const updatedRightSectionItems = [...rightSectionItems];

        if (value === "") {
            updatedRightSectionItems[index].default_value = "";
        } else {

            updatedRightSectionItems[index].default_value = value;
        }

        setRightSectionItems(updatedRightSectionItems);
    };





    const generateDynamicPartName = () => {
        const dynamicPart = rightSectionItems.map((item, index) => {
            if (
                item.variable_selected_type === "Fixed"

            ) {
                return item.default_value
            } else if (item.character_type === 'special_character') {
                return item.character_length
            } else if (item.variable_selected_type === "Counter" && item.is_unique === false) {
                return item.default_value

            } else if (item.variable_selected_type === "Counter" && item.is_unique === true) {

                return item.default_value
            } else if (item.character_type === "input_variable"||item.variable_selected_type === 'Static') {
                return item.default_value
            }
            return "";
        });

        return dynamicPart.join("").toUpperCase();
    };

    const dynamicPartNumber = generateDynamicPartName();

    const handlePartTitle = (e) => {
        setpartTitle(e.target.value)
    };


    // const handleCloseOptions = (e) => {
    //     setOpenOptions(false)

    // };

    const handlePartDesc = (e) => {
        setpartDesc(e.target.value)
    };

    const [selectedValues, setSelectedValues] = useState({});

    const handlemeasureChange = (selected, title) => {
        setSelectedValues(prevState => ({
            ...prevState,
            [title]: selected.value // Set the selected value for the attribute title
        }));
        console.log(selected.value)
    };






    const handleCreate = async () => {
        const headers = HEADERS


        const saveData = JSON.stringify(rightSectionItems);
        const attributes = JSON.stringify(attributeOptions.data.map(item => ({
            title: item.title,
            options: item.options.map(option => option),
            value: selectedValues[item.title]
        })))
        if (!partTitle) {
            // If partTitle is not filled, set an error message
            setErrors('Please fill all details');
        } else if (Object.keys(selectedValues).length !== attributeOptions.data.length) {
            setErrors('Please fill all attributes');
        } else {
            try {
                const response = await axios.post(
                    `${BASE_URL}/v1/asset/create-new-asset`,
                    {

                        data: saveData,
                        asset_number: dynamicPartNumber,
                        templateId,
                        asset_title: partTitle,
                        description: partDesc,
                        attribute_data: attributes,
                        attachments: uploadedFiles,
                        custom_data: JSON.stringify(data)

                    },
                    { headers }
                );

                if (response.data.meta.success === false) {
                    console.error(response.data.meta.message);
                    setErrors(response.data.meta.message);
                } else {
                    nav(ASSETVIEW(response.data.data.new_asset))
                    setErrors('');
                    getPartNumberList();
                    handleClose();

                }
            } catch (error) {
                console.error("An error occurred during the request:", error);
                setErrors("An error occurred during the request. Please try again.");
            }
        }

    };



    function incrementValue(item) {
        const lastChar = item.default_value.slice(-1);
        let result;

        if (!isNaN(parseInt(lastChar, 10))) {
            result = parseInt(item.default_value, 10) + (partLengthOfList + 1);
        } else {
            const nextLetter = String.fromCharCode(lastChar.charCodeAt(0) + (partLengthOfList + 1));
            result = item.default_value.slice(0, -1) + nextLetter;
        }

        return result;
    }

    let filteredFields;
    filteredFields =
        fields.filter(field => !field.is_default && field.is_default_template);




    const handlePersonSelectChange = (fieldName, value) => {
        let fieldValue = {
            photo: value.label.props.children[0].props.memberPhoto ? value.label.props.children[0].props.memberPhoto : '',
            member_id: value.value,
            fullName: value.label.props.children[1],
        };
        const transformedFieldName = fieldName.replace(/\s+/g, '_').toLowerCase();
        const updatedFormData = { ...formData, [fieldName]: value };
        setFormData(updatedFormData);
        saveFieldData(transformedFieldName, fieldValue);

        // Clear field error
        // setFieldErrors(prevErrors => ({ ...prevErrors, [fieldName]: '' }));
    };

    const handleMultiSelectChange = (fieldName, value) => {
        let fieldValue = value.map(item => item.label);
        const transformedFieldName = fieldName.replace(/\s+/g, '_').toLowerCase();
        const updatedFormData = { ...formData, [fieldName]: value };
        setFormData(updatedFormData);
        saveFieldData(transformedFieldName, fieldValue);

        // Clear field error
        // setFieldErrors(prevErrors => ({ ...prevErrors, [fieldName]: '' }));
    };

    const handleChange = (fieldName, value, dateType) => {
        const transformedFieldName = fieldName.replace(/\s+/g, '_').toLowerCase();
        let updatedFormData = { ...formData };


        updatedFormData[transformedFieldName] = value;


        setFormData(updatedFormData);
        saveFieldData(transformedFieldName, updatedFormData[transformedFieldName]);

        // Clear field error
        // setFieldErrors(prevErrors => ({ ...prevErrors, [fieldName]: '' }));
    };


    const saveFieldData = (fieldName, value) => {
        const field = filteredFields.find(f => f.fieldName.replace(/\s+/g, '_').toLowerCase() === fieldName);
        if (!field) return;
        let fieldData;
        if (field.inputType === 'single_select') {
            fieldData = {
                value: value.label,
                is_default: field.is_default,
                inputType: field.inputType,
                fieldName: field.fieldName,
                options: field.options,
                order: filteredFields.indexOf(field) + 1,
            };
        } else {
            fieldData = {
                value: value,
                is_default: field.is_default,
                inputType: field.inputType,
                fieldName: field.fieldName,
                options: field.options,
                order: filteredFields.indexOf(field) + 1,
            };
        }
        console.log(value)
        setData(prevData => ({
            ...prevData,
            [fieldName]: fieldData
        }));
    };




    return (
        <>

            <div className='create-part-number-popup-page' >
                <div className='create-part-num-div' >
                    <div className='create-part-num-div-top'>
                        <span>Create Asset</span>
                        <PopupCloseButton handleClose={handleClose} />
                    </div>


                    <div className='create-part-num-div-form' style={{ overflowY: 'scroll' }}>
                        <div className="create-part-num-errors">
                            <div className="create-part-num-div-part-number">
                                <span>Asset Number Preview</span>
                                <span className="create-part-title">
                                    {generateDynamicPartName()}
                                </span>

                            </div>
                            {errors !== '' && <div className="errors-msg-div">
                                <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />
                                <span className="error-message">{errors}</span>
                            </div>}

                        </div>

                        <div className='create-part-num-div-form-dynamic'>
                            {rightSectionItems.map((item, index) => (
                                <div key={index} >

                                    {item.variable_selected_type === 'Counter' && (
                                        <div className='create-part-num-div-form-label-input'>
                                            <span>{item.meaning}*</span>
                                            <div className='create-part-num-div-form-input'>
                                                <input
                                                    type='text'
                                                    // value={
                                                    //     item.is_unique
                                                    //         ? counterValues[item.position] ||
                                                    //         item.default_value.slice(0, -1) +
                                                    //         (parseInt(item.default_value.slice(-1), 10) + 1)
                                                    //         : counterValues[item.position] !== undefined
                                                    //             ? counterValues[item.position]
                                                    //             :  item.default_value
                                                    // }
                                                    value={
                                                        item.default_value
                                                    }
                                                    className='create-part-num-div-counter-input'
                                                    disabled
                                                    onChange={(e) =>
                                                        handlemeaningChange(
                                                            index,
                                                            e.target.value,
                                                            item.meaning,
                                                            item.default_value
                                                        )
                                                    }
                                                />

                                                {/* <img src={`${ASSET_PREFIX_URL}create-count-minus.png`} alt='Plus Icon'  /> */}
                                                {item.is_unique === false ? <>
                                                    {(minusButton[index] === true && counterValues[index]) && <img src={`${ASSET_PREFIX_URL}create-count-minus.png`} alt='Plus Icon'
                                                        onClick={() => handleDecrement(item.default_value, item.meaning, index, 'minus')} />}
                                                    {addButton[index] === false && counterValues[index] ? (
                                                        <img src={`${ASSET_PREFIX_URL}create-count-plus-disable.png`} alt='Plus Icon' />
                                                    ) : (
                                                        <img
                                                            src={`${ASSET_PREFIX_URL}create-count-plus.png`}
                                                            alt='Plus Icon'
                                                            onClick={() => handleCounterIncrease(item.default_value, item.meaning, index, 'add', item.is_unique, item)}
                                                        />
                                                    )}
                                                </> : ''}

                                            </div>
                                        </div>
                                    )}
                                    {item.variable_selected_type === 'Fixed' && (
                                        <div className='create-part-num-div-form-label-input'>
                                            <span>{item.meaning}*</span>
                                            <div className='create-part-num-div-form-input'>
                                                <Select
                                                    styles={customStyles}
                                                    // value={{ value: item.partValue, label: item.partValue }}
                                                    options={item.variable_meaning.map((item) => ({
                                                        value: item.variable,
                                                        label: item.variable,
                                                    }))}
                                                    value={{
                                                        value: item.default_value,
                                                        label: item.default_value
                                                    }}
                                                    onChange={(selected) =>
                                                        handleSelectChange(
                                                            selected,
                                                            item.meaning,
                                                            item.position,
                                                            item.default_value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {item.character_type === 'input_variable' && (
                                        <div className='create-part-num-div-form-label-input'>
                                            <span>{item.meaning}*</span>
                                            <div className='create-part-num-div-form-input'>
                                                <input type='text'
                                                    value={item.default_value}
                                                    // value={item.default_value}
                                                    onChange={(e) =>
                                                        handlemeaningChange(
                                                            index,
                                                            e.target.value,
                                                            item.meaning,
                                                            item.default_value
                                                        )
                                                    } />

                                            </div>
                                        </div>
                                    )}
                                    {item.variable_selected_type === 'Static' && (
                                        <div className='create-part-num-div-form-label-input'>
                                            <span>{item.meaning}*</span>
                                            <div className='create-part-num-div-form-input'>
                                                <input type='text'
                                                    value={item.default_value}
                                                    disabled
                                                     />

                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>

                        <div className="create-part-num-div-form-dynamic">
                            <div className="create-part-num-div-form-label-input">
                                <span>Asset title*</span>
                                <div className="create-part-num-div-form-input">
                                    <input type="text" placeholder="asset Title" value={partTitle} onChange={(e) => handlePartTitle(e)} />
                                </div>
                            </div>
                            <div className="create-part-num-div-form-label-input">
                                <span>Description</span>
                                <div className="create-part-num-div-form-input">
                                    <input
                                        type="text"
                                        placeholder="Describe this asset (optional)"
                                        value={partDesc} onChange={(e) => handlePartDesc(e)}
                                    />
                                </div>
                            </div>
                            {attributeOptions.data.map((item, index) => (
                                <div className="create-part-num-div-form-label-input" key={index}>
                                    <span>{item.title}*</span>
                                    <div className="create-part-num-div-form-input">
                                        {/* Render your Select component here */}
                                        <Select
                                            styles={customStyles}
                                            options={item.options.map(option => ({
                                                value: option,
                                                label: option,
                                            }))}
                                            placeholder={`Select ${item.title}`}
                                            value={selectedValues[item.title] ? { value: selectedValues[item.title], label: selectedValues[item.title] } : null}
                                            onChange={(selected) => handlemeasureChange(selected, item.title)}
                                        />

                                    </div>
                                </div>
                            ))}
                            {filteredFields.map((field, index) => (
                                <div key={index} className='create-asset-num-div-form-label-input'>
                                    <span>{field.fieldName}</span>
                                    {(field.inputType === 'text') && (
                                        <input
                                            type='text'
                                            // disabled={!inputDisable}
                                            placeholder={field.placeholder || ''}
                                            value={formData[field.fieldName.replace(/\s+/g, '_').toLowerCase()] || ''}
                                            onChange={(e) => handleChange(field.fieldName, e.target.value)}
                                        />
                                    )}
                                    {(field.inputType === 'paragraph') && (
                                        <textarea

                                            // disabled={!inputDisable}
                                            placeholder={field.placeholder || ''}
                                            value={formData[field.fieldName.replace(/\s+/g, '_').toLowerCase()] || ''}
                                            onChange={(e) => handleChange(field.fieldName, e.target.value)}
                                        />
                                    )}
                                    {(field.inputType === 'link') && (
                                        <input
                                            type='url'
                                            // disabled={!inputDisable}
                                            placeholder={field.placeholder || ''}
                                            value={formData[field.fieldName.replace(/\s+/g, '_').toLowerCase()] || ''}
                                            onChange={(e) => handleChange(field.fieldName, e.target.value)}
                                        />
                                    )}
                                    {(field.inputType === 'number') && (
                                        <input
                                            type='number'
                                            // disabled={!inputDisable}
                                            placeholder={field.placeholder || ''}
                                            value={formData[field.fieldName.replace(/\s+/g, '_').toLowerCase()] || ''}
                                            onChange={(e) => handleChange(field.fieldName, e.target.value)}
                                        />
                                    )}
                                    {field.inputType === 'date_range' && (
                                        <div className="type-texts" style={{ width: '335px' }}>
                                            <div>
                                                <span>Start Date</span>
                                                <input
                                                    placeholder={field.placeholder}
                                                    type="date"
                                                    value={formData[field.fieldName]?.start || ""}
                                                    min={new Date().toISOString().split("T")[0]}
                                                    onChange={(e) =>
                                                        handleChange(field.fieldName, {
                                                            ...formData[field.fieldName],
                                                            start: e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div>
                                                <span>End Date</span>
                                                <input
                                                    type="date"
                                                    value={formData[field.fieldName]?.end || ""}
                                                    min={formData[field.fieldName]?.start}
                                                    onChange={(e) =>
                                                        handleChange(field.fieldName, {
                                                            ...formData[field.fieldName],
                                                            end: e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>


                                    )}
                                    {field.inputType === 'single_select' && (
                                        <Select
                                            styles={customStyles}
                                            // isDisabled={!inputDisable || field.fieldName === 'Status'}
                                            options={field.options.map(option => ({ value: option, label: option }))}
                                            value={
                                                formData[field.fieldName.replace(/\s+/g, '_').toLowerCase()] ||
                                                (field.fieldName === 'Status' ? { value: 'Created', label: 'Created' } : '')
                                            }
                                            onChange={(selectedOption) => handleChange(field.fieldName, selectedOption)}
                                        />
                                    )}
                                    {field.inputType === 'multi_select' && (
                                        <Select
                                            styles={customStyles}
                                            isMulti
                                            // isDisabled={!inputDisable}
                                            options={field.options.map(option => ({ value: option, label: option }))}
                                            onChange={(selectedOptions) => handleMultiSelectChange(field.fieldName, selectedOptions)}
                                        />
                                    )}
                                    {field.inputType === 'person' && (
                                       <MemberDropdown field={field} formData={formData}
                                       handlePersonSelectChange={handlePersonSelectChange}
                                     />
                                    )}
                                    {field.inputType === 'multi_person' && (
                                        <MemberDropdown field={field} formData={formData}
                                        handlePersonSelectChange={handlePersonSelectChange}
                                      />
                                    )}
                                    {field.inputType === 'date' && (
                                        <input
                                            type='date'
                                            // disabled={!inputDisable}
                                            min={field.fieldName !== 'PO Date' ? new Date().toISOString().split("T")[0] : ''}
                                            value={formData[field.fieldName.replace(/\s+/g, '_').toLowerCase()] || ''}
                                            onChange={(e) => handleChange(field.fieldName, e.target.value)}
                                        />
                                    )}

                                    


                                </div>
                            ))}

                        </div>


                        <div className="create-part-num-div-form-label-attahment">
                            <span>Attachments*</span>
                            <div
                                onDrop={handleFileDrop}
                                onDragOver={handleDragOver}
                                className="filedropzone"
                            >
                                <img src={`${ASSET_PREFIX_URL}uploading-icon.svg`} alt="" />
                                {isLoading ? <p>Uploading ....</p> :
                                    <p>Drop files to attach or<span onClick={upload}> Browse</span></p>}

                            </div>
                            <input
                                type="file"
                                id="fileupld"
                                style={{ display: "none" }}
                                onChange={handleFileUpload}
                                className="btn-upload"
                                multiple
                            />
                        </div>
                        {!uploadError && <div className="error">{uploadError}</div>}
                        {fileSizeError && <div className="error"><span className="error-message"
                            style={{ color: '#FF9845' }}><ErrorOutlineOutlinedIcon /> {fileSizeError}</span></div>}
                        <AttachmentsFunction uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} type='form-upload' />


                    </div>

                    <div className='create-part-num-div-btns'>
                        <button onClick={handleCreate}>Create</button>
                        <button onClick={handleClose}>Cancel</button>

                    </div>
                </div>
            </div>

        </>

    )
}
export default CreateAsset