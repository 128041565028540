import React, { useEffect, useState } from 'react';
import { BASE_URL, HEADERS } from "../../constants/config";
import Cookies from "js-cookie";
import axios from "axios";
import Loading from '../Common/Loading/Loading';
import Pagination from '../Common/CommonJsx/Pagination';

function WarehouseLocations({ sequence_id }) {
  const [locationList, setLocationList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); // Default to 1
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getWarehouseList();

  }, [currentPage]);

  const getWarehouseList = async (page) => {
    if (loading || currentPage > totalPages) return; // Prevent multiple calls
    setLoading(true); // Set loading before fetching
    try {
      
      const response = await axios.get(`${BASE_URL}/v1/warehouse/get-location-list`, {
        params: {
          warehouse_sequence_id: sequence_id,
          page: currentPage,
        },
        headers: HEADERS,
      });



      setLocationList(response.data.data.location_list); // Append new data to the existing list
      setTotalPages(response.data.data.pagination.total_pages);
      setLoading(false); // Done loading
    } catch (error) {
      console.log(error);
      setLoading(false); // Reset loading in case of error
    }
  };




  return (
    <div className="department-table">
   
        <table className="department-table-data" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th style={{ width: "15%" }}>Location ID</th>
              <th style={{ width: "20%" }}>Location name</th>
            </tr>
          </thead>
          {loading ? <Loading /> :
          <tbody>
            {locationList.map((item, index) => (
              <tr
                key={index}
              >

                <td>{item.location_sequence_id}</td>
                <td>{item.location_default_data.location_name.value}</td>
              </tr>
            ))}
          </tbody>
}
        </table>
        <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifuContent: 'center' }}>
          {(totalPages > 1 && !loading) && <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />}
        </div>
      

    </div>
  )
}

export default WarehouseLocations