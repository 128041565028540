import React, { useState } from 'react'
import { ASSET_PREFIX_URL, BUCKET, PHOTO_LINK, BASE_URL, HEADERS } from '../../../constants/config';
// import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import axios from 'axios';
import Cookies from "js-cookie";

function DownloadAttachment({ uploadedFiles }) {
  const getFileIcon = (fileName) => {
    console.log(fileName);
    const extension = fileName.split(".").pop().toLowerCase();

    if (extension === "pdf") {
      return (
        <div
          style={{ overflow: "hidden", border: "none" }}
          className="extension-type-div"
        >
          <iframe
            src={PHOTO_LINK + fileName}
            title="CSV Preview"
          //   width= '75px'
          // height= '75px'
          // border= 'none'
          // style={{ overflowY: 'hidden', border: 'none',padding:'0px' }}
          // scrolling="no"
          />
        </div>
      );
    } else if (extension === "xls" || extension === "xlsx") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}xlsFile.png`}
          alt="Excel"
          width="75px"
          height="75px"
        />
      );
    } else if (extension === "xml") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}xmlFile.png`}
          alt="XML"
          width="75px"
          height="75px"
        />
      );
    } else if (
      extension === "jpg" ||
      extension === "png" ||
      extension === "jpeg" ||
      extension === "svg"
    ) {
      return <img src={PHOTO_LINK + fileName} alt="" className="png-img-div" />;
    } else if (extension === "pptx") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}pptxFile.png`}
          alt="ppt"
          width="75px"
          height="75px"
        />
      );
    } else if (extension === "csv") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}csvFile.png`}
          alt="csv"
          width="75px"
          height="75px"
        />
      );
    } else if (extension === "docx") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}wordFile.png`}
          alt="csv"
          width="75px"
          height="75px"
        />
      );
    } else {
      return (
        <img
          src={`${ASSET_PREFIX_URL}filesImage.png`}
          alt="File"
          width="75px"
          height="75px"
        />
      );
    }
  };

  const handleDownload = async (index, uploadedFiles) => {
    const { key, name } = uploadedFiles[index];
    const preSignedURL = await axios.post(
      `${BASE_URL}/v1/member/get-presigned-url-for-download`,
      { bucket_name: BUCKET, key, file_name: name },
      { headers: HEADERS }
    );
    if (
      preSignedURL.data.meta.code === 200 &&
      preSignedURL.data.meta.message === "SUCCESS" &&
      preSignedURL.data.data.url
    ) {
      const link = document.createElement('a');
      link.href = preSignedURL.data.data.url; // File URL from the uploaded files list
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  return (
    <div className="uploaded-files-view" style={{flexDirection: 'row'}}>
      {uploadedFiles.map((file, index) => (
        <div key={index} className="uploaded-file-view" style={{width:'12%'}}>
          <div className="file-details-view">
            <div className="file-image-preview">
              {getFileIcon(file.key)}
            </div>


            <div className="upld-file-details-view">
              <span title={file.name} className="file-view-name">
                {file.name.length > 20
                  ? file.name.substring(0, 15) + "..."
                  : file.name}
              </span>
            </div>

            <div className="file-details-view-hover">
              <a href={PHOTO_LINK + file.key} target="_blank">
                <div className="file-box-link">
                  <span
                    title={file.name}
                    className="file-view-name-view"
                  >
                    {file.name.length > 20
                      ? file.name.substring(0, 18) + "..."
                      : file.name}
                  </span>
                </div>
              </a>

              <div className="file-delete">
                <CloudDownloadOutlinedIcon
                  onClick={() => handleDownload(index, uploadedFiles)}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default DownloadAttachment