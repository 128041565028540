import React, { useState, useEffect } from "react";
import "./Signup.css";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL, ASSET_PREFIX_URL, LOGIN, PROFILEDETAILS } from "../../constants/config";
import { useNavigate } from "react-router-dom";
import { gapi } from "gapi-script";
import LeftContainer from "../Common/LeftContainer";
import GoogleLogin from 'react-google-login';
import Cookies from "js-cookie";
import { MicrosoftLogin } from 'react-microsoft-login';

function Signup({toastStyle}) {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedError, setIsCheckedError] = useState("");


  const nav = useNavigate();
  const clientId =
    "489950323098-a8i5stost392oousg6k1t3u4tbupi8tq.apps.googleusercontent.com";
  const handleSignup = async (event) => {
    event.preventDefault();
    let isValidated = true;
    if (!isChecked) {
      setIsCheckedError("Please check the checkbox to proceed.");
      isValidated = false;
    } else {
      setIsCheckedError("");
    }
    if (
      email.includes("@") &&
      email.includes(".") &&
      email.indexOf("@") !== 0 &&
      email.length - email.lastIndexOf(".") >= 3
    ) {
      setEmailError("");
    } else {
      setEmailError("Please enter your work email");
      isValidated = false;
    }
    if (isValidated) {
      CallRegisterApi(email,'')
    }
  };

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      handleSignup(event);
    }
  };
  
  async function CallRegisterApi(email, token, isMsalSSO) {
    console.log(isMsalSSO)
    let data;
    if (token) {
      data = await axios.post(BASE_URL + "/v1/member/register", { idToken: token, isMsalSSO });
    } else {
      data = await axios.post(BASE_URL + "/v1/member/register", { email });
    }
    let { message, success } = data.data.meta;
    if (success) {
      let { token, isSSO } = data.data.data;
      if (isSSO) {
        Cookies.set("token", token);
        nav(PROFILEDETAILS);
      } else {
        toast.info(message, toastStyle);
      }
    } else {
      //need to show error
      setEmailError(message);
    }
  }

  const responseGoogle = async (response) => {
    CallRegisterApi('', response.tokenId, 0);
  }

  useEffect(() => {
    gapi.load("client:auth2", () => {
      gapi.auth2.init({ clientId: clientId });
    });
  }, []);


 
  const handleAuth = (err,authResponse) => {
    console.log(authResponse)
    if (!err) {
      CallRegisterApi('',authResponse.account.username, 1);
    } else {
      toast.error(err,toastStyle);
    }
   
  };

  const handleError = (error) => {
    // Handle the authentication error
    console.error(error);
  };

  return (
    <div className="login-page">
      <LeftContainer />
      <div className="right-con">
        <div className="right">
          <img src={`${ASSET_PREFIX_URL}logo-desktop.svg`} alt="logo" className="reg-logo" />
          <span className="account">Create your account</span>
          {/* <button className="sign-btn" onClick={googleAuth}>
            {" "}
            <img src={googleicon} alt="google" width="23px" height="23px" />
            Sign In with Google
          </button> */}
          <div className="social-login">

            <GoogleLogin
              clientId={clientId}
              buttonText="Sign In with Google"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
              render={renderProps => (
                <button onClick={renderProps.onClick} className="google-button">
                <img
                  src={`${ASSET_PREFIX_URL}google_logo.svg`}
                  alt="google" width='24px' height='24px' />Sign In with Google
              </button>
              )}
            />

          <MicrosoftLogin
            clientId="8ef57ff0-a2bf-423d-8400-fe3fdf571177"
            authCallback={handleAuth}
            authError={handleError}
            buttonTheme="light"
            className="microsoft-btn"
          >
             <button className="google-button" style={{paddingTop:'7.8px',paddingBottom:'7.8px',fontWeight:'600'}}>
                  <img
                    src={`${ASSET_PREFIX_URL}microsoft_logo.svg`}
                    alt="microsoft" width='24px' height='24px' />Sign In with Microsoft
                </button>
          </MicrosoftLogin>
          </div>
          <div className="or">
            <span>or</span>
          </div>
          <div className="form">
            <div className={`input-container ${emailError ? "error" : ""}`}>
              <input
                type="email"
                placeholder="Enter Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="email-inp"
                onKeyUp={handleKeyUp}
              />
            </div>
            <div className="error-cont">
              {emailError && (
                <div className="error-message">
                  <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />
                  {emailError}
                </div>
              )}
             
            </div>
            <div className={`check-box ${isCheckedError ? "error" : ""}`}>
              <input
                type="checkbox"
                className="inp-check"
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
                onKeyUp={handleKeyUp}
              />
              <div className="check-box-para">
                By creating an account means you agree to the{" "}
                <span>Terms and Conditions</span> , and our Privacy Policy
              </div>
            </div>
            {isCheckedError && (
              <div className="error-message">
                <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />
                {isCheckedError}
              </div>
            )}
            <button className="sign-up" onClick={handleSignup}>
              Sign up
            </button>
            

            <div className="login-route">
              <p>
                Already registered?{" "}
                <span>
                  <a href={LOGIN} style={{ textDecoration: "none" }}>
                    Login
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
