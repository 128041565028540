import React, { useState } from 'react'
import './Template.css'
import { ASSET_PREFIX_URL } from "../../../constants/config";
import { useParams } from 'react-router-dom'

function SingleSelectTemplate({ onUpdate, data, onClose, containsAlphanumeric, fieldNameArray,noFieldNameError,
    startsWithAlphaError,uniqueFieldNameError,defaultFieldsArray,uniqueError }) {
    const [options, setoptions] = useState(data.options || ['']);
    const [fieldName, setFieldName] = useState(data.fieldName);
    const [is_mandatory, setis_mandatory] = useState(data.is_mandatory);
    const [error, setError] = useState('')
    const { templateId } = useParams();
    const filteredFieldNameArray = fieldNameArray.filter((item) => item !== data.fieldName);
    const handleFieldNameChange = (event) => {
        setFieldName(event.target.value);
    };
    const handleCheckboxChange = (event) => {
        // Update the state with the new value of the checkbox
        setis_mandatory(event.target.checked);
    };
    // let containsAlphanumeric = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]+$/.test(fieldName);
    const handleUpdate = () => {


        if (!fieldName) {
            setError(noFieldNameError);

        } else if (!containsAlphanumeric.test(fieldName)) {
            setError(startsWithAlphaError);

        } else if (filteredFieldNameArray.includes(fieldName)) {
            setError(uniqueFieldNameError);
          }else if(defaultFieldsArray.includes(fieldName.toLowerCase()) || defaultFieldsArray.includes(fieldName.toUpperCase())){
            setError(uniqueError)
          }  else {
            const updatedData = {
                template_id: templateId,
                inputType: 'single_select',
                is_default: false,
                is_mandatory:is_mandatory?is_mandatory:false,
                fieldName,
                options
            };
            onUpdate({ ...data, ...updatedData })

            onClose()
        }
    };

    const handleAddOption = () => {
        const newOption = `New Option ${options.length + 1}`;
        setoptions([...options, '']);
    };
    const handleOptionChange = (index) => (event) => {
        const updatedoptions = [...options];
        updatedoptions[index] = event.target.value;
        setoptions(updatedoptions);
    };
    const handleBoxRemoval = (index) => {
        const newData = [...options];
        newData.splice(index, 1);
        setoptions(newData);
    };
    const handleCancel = () => {
        onClose()
    }
    const isDisabled = !containsAlphanumeric.test(fieldName) || !fieldName ||
     (data.fieldName?filteredFieldNameArray.includes(fieldName):fieldNameArray.includes(fieldName))||
     defaultFieldsArray.includes(fieldName.toLowerCase()) || defaultFieldsArray.includes(fieldName.toUpperCase());
    const buttonClass = isDisabled ? "btn-sv1" : "btn-sv2";
    return (
        <div className='template-text-box-container'>
            <div className='template-text-box-container-heading'>
                <span>Edit field</span>
            </div>
            <div className='template-text-box-container-mandatory-check'>
                <input type="checkbox" checked={is_mandatory}
                    onChange={handleCheckboxChange} />
                <span>Mandatory Field</span>
            </div>
            <div className='template-text-box-container-texttype'>
                <span>Field Name</span>
                <div className='template-text-box-container-field-input'>
                    <input type='text' placeholder='single select field'
                        onChange={handleFieldNameChange} value={fieldName} />
                </div>
                {error && <div className='error-message' style={{ color: 'red' }}>{error}</div>}
            </div>
            <div className='template-text-box-container-text-switches'>
                <span>Field Input</span>
                {options.map((item, index) => (
                    <div key={index} className='template-text-box-container-text-singleselect-boxes'>
                        <div className='template-text-box-container-text-singleselect'>
                            <input type='radio' value={item}
                                checked={fieldName === item}
                                onChange={handleOptionChange(index)} />
                            <input type='text' placeholder={`Enter option ${index + 1}`}
                                value={item}
                                onChange={handleOptionChange(index)} />
                        </div>
                        <img src={`${ASSET_PREFIX_URL}template-selected-remove.svg`} alt='' width='20px' height='20px' onClick={() => handleBoxRemoval(index)} />
                    </div>
                ))}
                <div className='template-text-box-container-text-singleselect-adding'>
                    <buttom
                        className='template-text-box-container-text-singleselect-adding-btn' onClick={handleAddOption} ><img src={`${ASSET_PREFIX_URL}template-select-add.svg`} alt='' width='20px' height='20px' /><span>Add option</span></buttom>
                </div>
            </div>
            <div className="collab-members-btns1">
                <div className="collab-btns1">
                    <button className={buttonClass} onClick={handleUpdate}>
                        Save
                    </button>
                    <button className="btn-cl1" onClick={handleCancel}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default SingleSelectTemplate
