import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ASSET_PREFIX_URL, BASE_URL, HEADERS, INTERNALTRANFERS, LOCATIONVIEW, PARTVIEW } from '../../constants/config';
import BreadcrumbsNav from '../Common/BreadcrumbsNav';
import TicketTitlePopUp from '../Common/CommonJsx/TitlePopUp';
import Loading from '../Common/Loading/Loading';
import { toast } from 'react-toastify';
import ApproveRecieve from './ApproveRecieve';

function InternalTransferView() {
    const { sequence_id } = useParams()
    const [partList, setPartList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ticketPopUp, setTicketPopUp] = useState(false);
    const [ticketTitle, setTicketTitle] = useState("");
    const [canApprove, setCanApprove] = useState("");
    const [updateData, setUpdateData] = useState("");
    const [locations, setLocations] = useState("");
    const [openApprove, setOpenApprove] = useState(false);
    useEffect(() => {
        getPartsList()
    }, [sequence_id,updateData])
    const getPartsList = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/v1/location/get-transfer-details`, {
                params: { internal_transfer_seq_id: sequence_id },
                headers: HEADERS,
            });
            setPartList(response.data.data.transfer_details);
            setLocations(response.data.data.loc_seq_id)
            setCanApprove(response.data.data.destination_approver)
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const handleTitleClick = (event, internalItems) => {
        event.stopPropagation();
        setTicketPopUp(true);
        setTicketTitle(internalItems.location_default_data.location_name.value);
    };


    const handleRecieve = async () => {
        
    
       
        try {
          const payload = {
            internal_transfer_seq_id:sequence_id
          };
          console.log(payload);
          const response = await axios.post(
            `${BASE_URL}/v1/location/received-internal-transfer`,
            payload,
            { headers: HEADERS }
          );
          if(response.data.meta.success){
            setUpdateData(response)
          }else{
            toast.error(response.data.meta.message)
          }
         
        } catch (error) {
          console.error("Error saving data:", error);
        } 
      
      };
    return (
        <>
        {loading ? <Loading/> : <div className="depart-main">
                <div className='create-po-title' style={{ marginTop:'30px',justifyContent:'space-between' }}>
                    <BreadcrumbsNav
                        links={[
                            { label: "Internal transfers", href: INTERNALTRANFERS },
                        ]}
                        current={sequence_id}
                    />
                   {canApprove && <button className="tkt-btn" onClick={()=>setOpenApprove(!openApprove)}>Approve transfer</button>} 
                </div>
                <div className='create-po-title'>
                    <a style={{color:'blue',cursor:'pointer'}} href={LOCATIONVIEW(locations.source_location_seq_id)}>{locations.source_location_seq_id}</a>
                    <img
                                src={`${ASSET_PREFIX_URL}changes-arrow.svg`}
                                alt=""
                                width="12px"
                                className="arrow-watchers"
                            />
                    <a style={{color:'blue',cursor:'pointer'}} href={LOCATIONVIEW(locations.destination_location_seq_id)} target='_blank'>{locations.destination_location_seq_id}</a>
                </div>
                <div className="department-table">
                    <table className="department-table-data">
                        <thead>
                            <tr>
                                <th className="depart-header">Part number</th>
                                <th className="depart-header">Part title</th>
                                <th className="depart-header">Quantity</th>

                            </tr>
                        </thead>
                        <tbody>
                            {partList.map((item, i) => (
                                <tr key={i}>
                                    <td><a style={{color:'blue',cursor:'pointer'}} target='_blank' href={PARTVIEW(item.part_number)}>{item.part_number}</a></td>
                                    <td title={item.part_title}>
                                        {item.part_title.length > 30 ? (
                                            <>
                                                {item.part_title.slice(0, 30)}
                                                ...
                                                <button className="view-more-text" onClick={(event) => handleTitleClick(event, item)}>
                                                    view
                                                </button>
                                            </>
                                        ) : (
                                            item.part_title
                                        )}
                                    </td>

                                    <td>{item.quantity}</td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </div>}
            
            {ticketPopUp && (
                <TicketTitlePopUp
                    header='part title'
                    onClose={() => setTicketPopUp(!ticketPopUp)}
                    ticketTitle={ticketTitle}
                />
            )}
            {openApprove && <ApproveRecieve onclose={()=>setOpenApprove(!openApprove)} handleRecieve={handleRecieve}/>}

        </>

    )
}

export default InternalTransferView