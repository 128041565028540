import React from 'react'
import './Loading.css'

function Loading({smallScreen,excellLoading}) {
  const getClassName = () => {
    if (smallScreen) return 'screen1';
    if (excellLoading) return 'excell-loading';
    return 'screen';
  };
  return (
    <div class={getClassName()} >

  <div class="loader">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </div>

</div>
  )
}

export default Loading