import React, { useState, useEffect, useRef } from 'react'
import {
  BASE_URL,
  PARTVIEW
} from "../../constants/config";
import Cookies from "js-cookie";
import axios from "axios";
import Loading from '../Common/Loading/Loading';
import SearchFilter from '../Common/CommonJsx/SearchFilter';
import { textLettersLimit } from '../../constants/helper';


function EcpartsPage({ editable,
  sequence_id,
  searchedParts, type, ticketId,setUpdatedData,
  updatedData }) {

  const [searchParts, setSearchParts] = useState(searchedParts ? searchedParts : '');
  const [partDetailsCondition, setPartDetailsCondition] = useState(false);
  const [searchEcLoading, setSearchEcLoading] = useState(false);
  const [selectedParts, setSelectedParts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [activePage, setActivePage] = useState(1);
    

  const [partOptions, setPartOptions] = useState([])
  const LIMIT = 20;
  const containerRef = useRef(null);


  useEffect(() => {
    if (searchParts !== '') {

      debounce(getUniqueParts)()
    } else {
      getParts();
    }
  }, [activePage, searchParts,updatedData]);
  const handleSeachPartNumbers = (e) => {
    const searchParameter = e.target.value;
    setSearchParts(searchParameter);
    if (searchParameter === '') {
      setSearchEcLoading(true);
      setPartOptions([])
      setPartDetailsCondition(false)
    } else {
      setActivePage(1)
      setSearchEcLoading(true);
      setPartOptions([])
      setPartDetailsCondition(true)
    }

  };
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };
  const handleScroll = debounce(() => {
    const container = containerRef.current;
    if (!hasMore || searchEcLoading) {

      return;
    }


    if (container.scrollTop + container.clientHeight >= container.scrollHeight - 20) {
      setActivePage(prevPage => prevPage + 1);
    }
  }, 500);

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      container.addEventListener('scroll', handleScroll);

      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [containerRef, handleScroll]);


  const getParts = async () => {
    try {
      setSearchEcLoading(true);

      const headers = {
        "x-auth-token": localStorage.getItem("token"),
      };
      let url;
      if (type === 'ec-template') {
        url = `${BASE_URL}/v1/ec-template/get-part-num-mapping`;
      } else if (type === 'ec-deviation') {
        url = `${BASE_URL}/v1/ec-deviations/get-part-num-mapping`;
      } else if (type === 'pc-template') {
        url = `${BASE_URL}/v1/prod-change/get-part-num-mapping`;
      } else if (type === 'pci-view') {
        url = `${BASE_URL}/v1/manufac-bom/get-part-num-mapping`;
      }
      const approvalList = await axios.get(
        url,
        {
          params: {
            sequence_id, size: LIMIT, page: activePage
          },
          headers
        }
      );
      // console.log(approvalList.data.data.parts);
      // if (type === 'ec-template') {
      // setPartOptions(approvalList.data.data.parts)
      if (activePage !== 1) {
        setPartOptions((prev) => {
          return [
            ...new Set([...prev, ...approvalList.data.data.parts]),
          ];
        });
      } else {
        setPartOptions((prev) => {
          // Filter out duplicate child parts before adding
          const newParts = approvalList.data.data.parts.filter(
            (newPart) => !prev.some((existingPart) => existingPart.id === newPart.id)
          );
          return [...prev, ...newParts];
        });
      }
      // 
      // setPartOptions(approvalList.data.data.parts),

      // } else if (type === 'ec-deviation') {
      //   setPartOptions(approvalList.data.data.dev_approval_list)
      // }
      setHasMore(approvalList.data.data.parts.length === LIMIT)
      setSearchEcLoading(false);
    } catch (error) {
      // Handle error
      console.log(error)
    }
  };
  const getUniqueParts = async () => {
    try {
      setSearchEcLoading(true)
      const headers = {
        "x-auth-token": localStorage.getItem("token"),
      };


      let response;
      // if (type === 'ec-template') {
      //   response = await axios.get(
      //     `${BASE_URL}/v1/ec-deviations/unique-parts`,

      //     {
      //       params: { search: searchParts, size: LIMIT, page: activePage }
      //       ,
      //       // params: params,
      //       headers: headers,
      //     }


      //   );
      // } else if (type === 'pc-template') {

      // }

      // response = await axios.get(
      //   `${BASE_URL}/v1/ec-deviations/unique-parts`,

      //   {
      //     params: { search: searchParts, size: LIMIT, page: activePage }
      //     ,
      //     // params: params,
      //     headers: headers,
      //   }


      // );
      if (['ec-template', 'ec-deviation'].includes(type)) {
        response = await axios.get(
          `${BASE_URL}/v1/ec-deviations/unique-parts`,
          {
            params: { search:searchParts, page: activePage, size: LIMIT, },
            headers,
          }
        );
      } else if (type === 'pc-template') {
        response = await axios.get(
          `${BASE_URL}/v1/prod-change/get-appr-parts`,
          {
            params: { search:searchParts, page: activePage, size: LIMIT,pc_sequence_id:sequence_id },
            headers,
          }
        );
      }

      const { part_number_list, total_count } = response.data.data;
      setPartOptions((prev) => {
        return [
          ...new Set([...prev, ...part_number_list]),
        ];
      });
      // setPartOptions(searchParts !== '' ? part_number_list : [...partOptions, ...part_number_list]);
      //   setOptionsCount(total_count);
      setHasMore(part_number_list.length === LIMIT)
      // setActivePage(!searchParts && activePage + 1);
      setSearchEcLoading(false);
    } catch (error) {
      console.error("Error fetching parts:", error);
    }
  };
  const handleUpdateOrDelete = async (action) => {
    try {
      const headers = {
        "x-auth-token": localStorage.getItem("token"),
      };

      if (action === 'update') {
        try {
          const updatedSelectedParts = selectedParts.map(part => ({
            ...part,
            part_id: part._id // Assuming _id is the part_id
          }));
          console.log(updatedSelectedParts.map(item => item._id))
          if (type === 'ec-template') {
            const getpartsDetails = await axios.post(
              `${BASE_URL}/v1/ec-template/create-part-num-mapping`,
              {
                enchange_id: ticketId, sequence_id: sequence_id,
                part_details: JSON.stringify(updatedSelectedParts),

              },
              { headers: headers }
            );
          } else if (type === 'ec-deviation') {
            const getpartsDetails = await axios.post(
              `${BASE_URL}/v1/ec-deviations/create-part-number-dev`,
              {
                deviation_id: ticketId, sequence_id: sequence_id,
                part_details: JSON.stringify(updatedSelectedParts),

              },
              { headers: headers }
            );
          } else if (type === 'pc-template') {
            // remove-pc-parts
            const getpartsDetails = await axios.post(
              `${BASE_URL}/v1/prod-change/add-pc-parts`,
              {
                pc_id: ticketId, sequence_id: sequence_id,
                part_numbers: selectedParts.map(item => item.part_number),

              },
              { headers: headers }
            );
          }

          console.log(updatedSelectedParts)
          setPartDetailsCondition(false)
          setSearchParts('')
         
          setUpdatedData(updatedSelectedParts)

        } catch (error) {
          console.log(error)
        }





      } else {
        console.log(selectedParts.map(item => item._id))
        try {
          if (type === 'ec-template') {
            await axios.post(
              `${BASE_URL}/v1/ec-template/remove-part-num-mapping`,
              {
                enchange_id: ticketId, sequence_id: sequence_id,
                part_ids: selectedParts.map(item => item._id),

              },
              { headers: headers }
            );
          } else if (type === 'ec-deviation') {
            await axios.post(
              `${BASE_URL}/v1/ec-deviations/remove-part-num-mapping`,
              {
                enchange_id: ticketId, sequence_id: sequence_id,
                part_ids: selectedParts.map(item => item._id),

              },
              { headers: headers }
            );
          } else if (type === 'pc-template') {
            // remove-pc-parts
            await axios.post(
              `${BASE_URL}/v1/prod-change/remove-pc-parts`,
              {
                pc_id: ticketId, sequence_id: sequence_id,
                part_ids: selectedParts.map(item => item._id),

              },
              { headers: headers }
            );
          }

          // getParts()
          setUpdatedData(selectedParts.map(item => item._id))
          // console.log("Selected parts:", getpartsDetails);
        } catch (error) {
          console.log(error)
        }

      }

    } catch (error) {
      console.error("Error updating or deleting parts:", error);
    }
  };
  return (
    <div style={{ width: '100%' }}>
      {editable &&

        <SearchFilter searchTerm={searchParts} handleSearchChange={handleSeachPartNumbers} listType='part number to add...' />
      }


      {/* {searchEcLoading ? <Loading smallScreen={true} /> : */}
        <div className="part-number-parts-divs" style={{height:'60vh'}} ref={containerRef} onScroll={handleScroll}>
          {/* <InfiniteScroll
          dataLength={partOptions.length}
          next={() => handleNextFunction()}
          hasMore={partOptions.length > optionsCount}

        // className="infinite-scroll-content"
        > */}
          {(partOptions.length === 0 && !searchEcLoading) ? <div className='data-not-found-img'>
            <span>Parts not found!</span>
          </div> : <> {partOptions.map((item, index) => (
            <div key={item.part_id} className="part-numbers-views">
              <div className="part-numbers-views-details">
                <div style={{ display: 'flex', gap: '15px' }}>
                  {editable && <div className="part-numbers-views-checkbox">
                    <input type="checkbox" onChange={(e) => {
                      const isChecked = e.target.checked;
                      if (isChecked) {
                        setSelectedParts([...selectedParts, item]);
                      } else {
                        setSelectedParts(selectedParts.filter(part => part.part_id !== item.part_id));
                      }
                      // console.log("Selected ECs:", selectedParts);
                    }} />
                  </div>}

                  <div className="part-numbers-views-content">
                    <a href={PARTVIEW(item.part_number)} target='_blank' className='link-to-view-ec'>{item.part_number}</a>
                    {/* <span className="part-number-view-number">{item.part_number}</span> */}
                    {/* <span className="part-number-view-title">{item.part_title}</span> */}
                    <span className="part-number-view-title" title={item.part_title}>
                      {textLettersLimit(item.part_title, 40)}
                    </span>
                  </div>
                </div>

                {/* <div>
                  <input type="number" value={item.quantity} disabled style={{background:'inherit'}}/>
                </div> */}
              </div>
            </div>
          ))}</>}

          {/* </InfiniteScroll> */}
        </div>
      {/* } */}
      <div>
        {(editable && !searchEcLoading) && (
          <>
            {partDetailsCondition ? (
              <button
                className="part-view-delete-parts"
                onClick={() => handleUpdateOrDelete('update')}
                disabled={selectedParts.length < 1}
              >
                Update
              </button>
            ) : (
              <button
                className="part-view-delete-parts"
                onClick={() => handleUpdateOrDelete('delete')}
                disabled={selectedParts.length < 1}
              >
                Delete
              </button>
            )}
          </>
        )}


        {/* Use onClick={handleUpdateOrDelete} for the "Update" button */}
        {/* Use onClick={handleUpdateOrDelete} for the "Delete" button */}

      </div>
    </div>
  )
}

export default EcpartsPage