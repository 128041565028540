import React, { useState } from 'react'
import PopupCloseButton from '../../Common/PopupCloseButton/PopupCloseButton'
import { BASE_URL, HEADERS } from "../../../constants/config";
import Cookies from "js-cookie";
import axios from "axios";

function RejectPopUp({ onclose, sequence_id, type, ticketId,
    ticketName,openApproveList,setUpdatedData,
    notifyIds }) {

    const [rejectMessage, setRejectMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')


    const handleChange = (e) => {
        setErrorMessage('')
        setRejectMessage(e.target.value)
    }

    const handleReject = async () => {
        if (!rejectMessage) {
            setErrorMessage('Please enter reason to reject this EC')
        } else {
            try {
                const headers = HEADERS
               


                let endpoint, idKey,nameKey,url,rejectType;
                if (type === 'ec-template') {
                    endpoint = 'ec-template'
                    idKey = 'ec_id'
                    nameKey = 'sequence_id'
                    url = 'reject-ec'
                } else if (type === 'ec-deviation') {
                    endpoint = 'ec-deviations'
                    idKey = 'dev_id'
                    nameKey = 'sequence_id'
                    url = 'reject-ec'
                } else if (type === 'view') {
                    endpoint = 'purchase-order'
                    idKey = 'po_id'
                    nameKey = 'po_name'
                    url = 'reject-po'
                }else if (type === 'assets-view') {
                    endpoint = 'asset'
                    idKey = 'po_id'
                    nameKey = 'asset_name'
                    url = 'reject-asset'
                }else if (type === 'pc-template') {
                    endpoint = 'prod-change'
                    idKey = 'pc_id'
                    nameKey = 'sequence_id'
                    url = 'reject-pc'
                    rejectType = openApproveList === 'reject-trail' ? 'trial-approvers' : 'plan-approvers'
                }else if (type === 'pci-view') {
                    endpoint = 'manufac-bom'
                    idKey = 'product_id'
                    nameKey = 'sequence_id'
                    url = 'reject-pci'    
                }
                let commentRejectMessage 
                if(openApproveList === 'reject-trail'){
                    commentRejectMessage = `Rejected trail phase: ${rejectMessage}`
                }else if(openApproveList === 'reject-plan'){
                     commentRejectMessage = `Rejected launch phase: ${rejectMessage}`
                }else{
                    commentRejectMessage = `Rejected: ${rejectMessage}`
                }
                await axios.post(`${BASE_URL}/v1/${endpoint}/${url}`, {
                    [nameKey]:sequence_id, rejected_message: rejectMessage,
                    [idKey]: ticketId,type:rejectType
                }, { headers: headers });
               
                    await axios.post(
                        `${BASE_URL}/v1/comment/create-comment/${sequence_id}`,
                        {
                            comments: commentRejectMessage, ticket_id: ticketId, ticket_title: ticketName,
                            member_ids: notifyIds
                        },
                        { headers }
                    );
                
              
                    setUpdatedData(commentRejectMessage)
                onclose()
            } catch (error) {
                console.log(error)
            }
        }



    }
    return (
        <div className='notification-page'>
            <div className='notification-div'>
                <div className='create-part-num-div-top'>
                   {type === 'ec-template' && <span>Rejecting EC?</span>} 
                   {type === 'ec-deviation' && <span>Rejecting Deviation?</span>} 
                   {type === 'view' && <span>Rejecting PO?</span>}
                   {type === 'assets-view' && <span>Rejecting asset?</span>} 
                   {type === 'pc-template' && <span>Rejecting PC?</span>} 
                   {type === 'pci-view' && <span>Rejecting PCI?</span>} 
                    <PopupCloseButton

                        handleClose={onclose}
                    />
                </div>
                <div className='reject-text-area'>
                    <div className='reject-mesage-error'>
                        <textarea placeholder='Please enter reason to reject*' value={rejectMessage}
                            onChange={(e) => handleChange(e)} />
                        {errorMessage !== '' && <span className='error-message'>{errorMessage}</span>}
                    </div>


                    <button onClick={handleReject}>Confirm</button>
                </div>
            </div>
        </div>
    )
}

export default RejectPopUp