import React from "react";
import ReactDOM from "react-dom/client";
import { TourProvider } from '@reactour/tour'
// import TourSteps from "./Components/Common/TourGuides/TourSteps";
import { reactourStyles } from "./Components/Common/TourGuides/TourSteps";
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import App from "./App";



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <>
    {/* <TourProvider styles={reactourStyles}> */}
    <ToastContainer />
   
    <App />
    {/* </TourProvider> */}
    </>
    
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// how to do login form
