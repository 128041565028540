import React, { useState } from 'react';
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import BreadcrumbsNav from '../Common/BreadcrumbsNav';
import axios from 'axios';
import { HEADERS, WAREHOUSELIST,BASE_URL } from '../../constants/config';
import CustomTabs from '../Common/CustomTabs';
import WarehouseLocations from './WarehouseLocations';
import { useParams } from 'react-router-dom';
import WarehouseParts from './WarehouseParts';
import WarehouseGRN from './WarehouseGRN';
import WarehouseDetails from './WarehouseDetails';
import CreateLocation from '../LocationPages/CreateLocation'

function WareHouseView() {
    const [selectedSection, setSelectedSection] = useState(0);
    const [openCreateLocation, setOpenCreateLocation] = useState(false)
    const { sequence_id } = useParams()

   
    return (
        <>
            <div className="depart-main" style={{ marginTop: "80px" }}>
                <div className="part-number-list-title">
                    <div>
                        <BreadcrumbsNav
                            links={[
                                { label: "warehouses", href: WAREHOUSELIST },
                            ]}
                            current={sequence_id}
                        />
                    </div>
                    <div className="part-number-list-btns">
                        <button onClick={() => setOpenCreateLocation(!openCreateLocation)}>
                            <AddOutlinedIcon />
                            Create new location
                        </button>
                    </div>
                </div>

                {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={selectedSection}
                    onChange={handleSectionClick}
                    aria-label="Warehouse Tabs"
                >
                    <Tab label="Locations" value="locations" sx={{ textTransform: 'none' }}/>
                    <Tab label="Parts" value="parts" sx={{ textTransform: 'none' }}/>
                    <Tab label="GRN" value="grn" sx={{ textTransform: 'none' }}/>
                    <Tab label="Warehouse details" value="warehouse-details" sx={{ textTransform: 'none' }}/>
                </Tabs>
            </Box> */}
                <div className='supplier-page-bottom-page' style={{ padding: '8px' }}>
                    <div className='workspace-detail-tab'>
                        <CustomTabs
                            tabLabels={["Locations", "Parts", "GRN", "Warehouse details"]}
                            currentTab={selectedSection}
                            setCurrentTab={setSelectedSection}
                            panels={[
                                () => <WarehouseLocations sequence_id={sequence_id}/>,
                                () => <WarehouseParts sequence_id={sequence_id}/>,
                                () => <WarehouseGRN sequence_id={sequence_id}/>,
                                () => <WarehouseDetails sequence_id={sequence_id}/>,
                            ]}
                        />
                    </div>
                </div>
            </div>
            {openCreateLocation && <CreateLocation handleClose={() => setOpenCreateLocation(!openCreateLocation)}
                warehouseNumber={sequence_id} />}
        </>

    );
}

export default WareHouseView;
