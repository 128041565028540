import React from 'react'
import "./HomeTopNav.css"
import { useNavigate } from 'react-router-dom'
import { LOGIN } from '../../../constants/config'

function HomeNavs({onclose}) {
    const handleCloseMenu = () =>{
        onclose()
    }
    const nav = useNavigate()

    
    return (
        <div className='menu-page'>
            <div className='menu-close-icon'>
                <span onClick={handleCloseMenu}>close x</span>
            </div>
            <div className='menu-navs'>
            <a href="#team-rich"  onClick={handleCloseMenu}>Team Rich</a>
            <a href="#capabilities" onClick={handleCloseMenu}>Capabilities</a>
            <a href="#compliance" onClick={handleCloseMenu}>Compliance</a>
            <a href="#security" onClick={handleCloseMenu}>Security</a>
            </div>
            <div className='menu-buttons'>
                <button className="try-demo-menu">Try Demo</button>
                <button className="home-login-menu" onClick={()=>nav(LOGIN)}>Login</button>
            </div>
        </div>
    )
}

export default HomeNavs