import React, { useState, useEffect, useCallback } from 'react';
import './ProductionSchedule.css';
import axios from "axios";
import { ASSET_PREFIX_URL, BASE_URL } from "../../constants/config";
import Cookies from "js-cookie";
import Loading from '../Common/Loading/Loading';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import ProductionErrorParts from './ProductionErrorParts';
import { debounce } from '../../constants/helper';

const today = new Date();
const start = new Date(today);
start.setDate(today.getDate() - 3);
const end = new Date(start);
end.setMonth(start.getMonth() + 2);
const formatDate = (date) => date.toISOString().split('T')[0];

const ProductionSchedule = () => {
    const [selectedFilter, setSelectedFilter] = useState('daily');
    const [loading, setLoading] = useState(false);
    const [visibleDates, setVisibleDates] = useState([]);
    const [scheduleData, setScheduleData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [startDate, setStartDate] = useState(formatDate(start))
    const [openErrorParts, setOpenErrorParts] = useState(false)
    const [errorParts, setErrorParts] = useState([])
    const [updatedData, setUpdatedData] = useState(false);
    const [endDate, setEndDate] = useState(formatDate(end))
    const itemsPerPage = 8;
    const nav = useNavigate();
    const location = useLocation();
    const [editState, setEditState] = useState({
        productId: null,
        date: null,
        field: null,
        value: '',
        oldValue: ''
    });

    const handleEdit = (productId, date, field, initialValue, productName) => {


        setEditState({ productId, date, field, value: initialValue || 0, oldValue: initialValue || 0, productName });
    };


    const handleSave = async () => {
        const { productId, date, field, value, oldValue } = editState;

        if (productId && date && field) {
            console.log({
                id: productId,
                key: field,
                new_value: value,
                old_value: oldValue
            });
            try {
                const headers = {
                    'x-auth-token': localStorage.getItem("token")
                };
                const payload = {
                    id: productId,
                    key: field,
                    new_value: value,
                    old_value: oldValue
                };
                console.log(payload);
                const response = await axios.post(
                    `${BASE_URL}/v1/prod-plan/update-production-schedule`,
                    payload,
                    { headers }
                );
                console.log(response)
                if (response.data.meta.success === false) {
                    toast.error(response.data.meta.message);

                    if (response.data.error_parts) {
                        setOpenErrorParts(!openErrorParts)
                        setErrorParts(response.data.error_parts)
                        console.log(response.data.error_parts);
                    } else {
                        console.log('response is false but no error parts');
                    }
                } else {
                    setUpdatedData(value)
                    setEditState(false);
                }


            } catch (error) {
                console.error("Error saving MBOM:", error);
            }
        }

    };


    const handleCancel = (e) => {
        e.stopPropagation()
        // Cancel the edit and reset the state
        setEditState(false);
    };

    const handleRowChange = (e) => {
        setEditState((prevState) => ({ ...prevState, value: e.target.value }));
    };

    // useEffect(() => {
    //     fetchWorkPlan();
    // }, [currentPage, itemsPerPage, selectedFilter, startDate, endDate]);
    const fetchWorkPlan = async () => {
        try {
            setLoading(true);
            const headers = {
                "x-auth-token": localStorage.getItem("token"),
            };
            const savedState = JSON.parse(localStorage.getItem('prostate')) || {};
            const page = savedState.currentPage || 1;
            const filter = savedState.selectedFilter || 'daily';
            const start = savedState.startDate || '';
            const end = savedState.endDate || '';
            const response = await axios.get(
                `${BASE_URL}/v1/prod-plan/get-prod-schedule`,
                {
                    params: {
                        page: page,
                        limit: itemsPerPage,
                        start_date: start,
                        end_date: end,
                        view_type: filter // Adjust parameters as needed // Adjust parameters as needed
                    },
                    headers: headers,
                }
            );

            const schedule = response.data.data.schedule || [];
            const dates = schedule.map(item => item.date);
            console.log(response.data.data.pagination.totalPages)
            setTotalPages(response.data.data.pagination.totalPages) // Extracting dates for headers
            setVisibleDates(dates);
            setScheduleData(schedule);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const page = params.get('page') || 1;
        const filter = params.get('filter') || 'daily';
        const start = params.get('start') || startDate;
        const end = params.get('end') || endDate;

        // Load state from localStorage if available
        const savedState = JSON.parse(localStorage.getItem('prostate')) || {};
        setCurrentPage(Number(page) || savedState.currentPage || 1);
        setSelectedFilter(filter || savedState.selectedFilter || 'daily');
        setStartDate(start || savedState.startDate || '');
        setEndDate(end || savedState.endDate || '');
    }, []);

    // Update URL params when state changes
    useEffect(() => {
        updateUrlParams();
        fetchWorkPlanDebounced();
    }, [currentPage, selectedFilter, startDate, endDate,updatedData]);

    const updateUrlParams = useCallback(debounce(() => {
        const params = new URLSearchParams();
        params.append('page', currentPage);
        params.append('filter', selectedFilter);
        params.append('start', startDate);
        params.append('end', endDate);

        nav({ search: params.toString() });
    }, 300), [currentPage, selectedFilter, startDate, endDate]);

    // Optionally, persist state in localStorage
    useEffect(() => {
        const prostate = {
            currentPage,
            selectedFilter,
            startDate,
            endDate
        };
        localStorage.setItem('prostate', JSON.stringify(prostate));
    }, [currentPage, selectedFilter, startDate, endDate]);

    const fetchWorkPlanDebounced = useCallback(debounce(fetchWorkPlan, 300), []);

    const handleFilterClick = (filter) => {
        setSelectedFilter(filter);
        setCurrentPage(1);
        // No need to call updateUrlParams here as it's already handled in the effect
    };



    const handlePagination = (direction) => {
        if (direction === 'next' && currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            // alert(currentPage+1)
            updateUrlParams();
        } else if (direction === 'prev' && currentPage > 1) {
            setCurrentPage(currentPage - 1);
            // alert(currentPage-1)
            updateUrlParams();
        }
    };
    const handleChange = (e, type) => {
        if (type === 'start') {
            setStartDate(e.target.value);
        } else if (type === 'end') {
            setEndDate(e.target.value);
        }
        updateUrlParams();
    }



    const groupProductsByName = (schedule) => {
        const groupedProducts = {};
        schedule.forEach(day => {
            day.products.forEach(product => {
                if (!groupedProducts[product.product_name]) {
                    groupedProducts[product.product_name] = {};
                }
                groupedProducts[product.product_name][day.date] = product;
            });
        });
        return groupedProducts;
    };

    const renderProductRows = (groupedProducts) => {
        return Object.keys(groupedProducts).map((productName) => {
            const productDates = Object.keys(groupedProducts[productName]);

            return (
                <React.Fragment key={productName}>
                    <tr style={{ padding: '16px', fontSize: '16px', border: '1px solid #edf2f7' }}>
                        <td className='production-table-row-head' style={{ background: 'rgba(246, 246, 246, 0.50)' }}>
                            {productName}
                        </td>
                        {visibleDates.map((date) => {
                            const productData = groupedProducts[productName][date];

                            return (
                                <td
                                    style={{
                                        padding: '16px',
                                        fontSize: '16px',
                                        background: 'rgba(246, 246, 246, 0.50)',
                                        border: '1px solid #edf2f7',
                                        wordBreak: 'break-all'
                                        // Fixed width for each cell
                                    }}
                                    key={date}
                                    className='production-table-row-data'
                                >

                                </td>
                            );
                        })}
                    </tr>
                    <tr style={{ padding: '16px', fontSize: '16px', border: '1px solid #edf2f7' }}>
                        <td className='production-table-row-head'>Planned</td>
                        {visibleDates.map((date) => {
                            const productData = groupedProducts[productName][date];
                            const daysBack = 6;

                            // Get today's date and calculate the date 6 days ago
                            const today = new Date();
                            const pastDate = new Date();
                            pastDate.setDate(today.getDate() - daysBack);

                            // Convert both dates to the 'YYYY-MM-DD' format
                            const todayStr = today.toISOString().split('T')[0];
                            const pastDateStr = pastDate.toISOString().split('T')[0];

                            // Check if the date is within the editable range (past 6 days or future)
                            const isEditableDate = date >= pastDateStr;

                            const isEditing = selectedFilter === 'daily' && editState.productId && editState.date === date && editState.field === 'planned_production' &&
                                editState.productName === productName && isEditableDate;

                            return (
                                <td
                                    style={{
                                        padding: '16px',
                                        fontSize: '16px',
                                        border: '1px solid #edf2f7',
                                        background: productData?.planned === 'N/A' ? 'rgba(246, 246, 246, 0.50)' : 'white',
                                        wordBreak: 'break-all'
                                        // Fixed width for each cell
                                    }}
                                    key={date}
                                    className='production-table-row-data'
                                    
                                >
                                    {isEditing ? (
                                        <div className='edit-prod-table-cell'>
                                            <input
                                                type='number'
                                                value={editState.value}
                                                onChange={handleRowChange}
                                            />
                                            <div>
                                                <img
                                                    onClick={handleSave}
                                                    src={`${ASSET_PREFIX_URL}save-details.png`}
                                                    alt=""
                                                    width="24px"
                                                    height="24px"
                                                    style={{ cursor: 'pointer' }}
                                                />&nbsp;
                                                <img
                                                    onClick={(e) => handleCancel(e)}
                                                    src={`${ASSET_PREFIX_URL}cancel-detail.png`}
                                                    alt=""
                                                    width="24px"
                                                    height="24px"
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                            {productData ? (productData.planned !== 'N/A' ? productData.planned : 'N/A') : 'N/A'}
                                            {productData.is_planned_editable && <img
                                                onClick={() => handleEdit(productData.id, date, 'planned_production', productData.planned || '', productName)}
                                                src={`${ASSET_PREFIX_URL}edit-ticket.png`}
                                                alt=""
                                                width="12px"
                                                height="12px"
                                                style={{ cursor: 'pointer' }}
                                            />}
                                        </div>
                                        // <span>
                                        //     {/* Format decimal numbers */}
                                        //     {productData ? (productData.planned !== 'N/A' ? productData.planned : 'N/A') : 'N/A'}
                                        // </span>
                                    )}
                                </td>
                            );
                        })}
                    </tr>
                    <tr style={{ padding: '16px', fontSize: '16px', border: '1px solid #edf2f7' }}>
                        <td className='production-table-row-head'>Actual</td>
                        {visibleDates.map((date) => {
                            const productData = groupedProducts[productName][date];
                            const daysBack = 6;

                            const today = new Date();
                            const pastDate = new Date();
                            pastDate.setDate(today.getDate() - daysBack);
                            
                            const pastDateStr = pastDate.toISOString().split('T')[0];
                            const isEditableDate = date >= pastDateStr;

                            const isEditing = selectedFilter === 'daily' && editState.productId && editState.date === date && editState.field === 'actual_production' &&
                                editState.productName === productName && isEditableDate;

                            return (
                                <td
                                    style={{
                                        padding: '16px',
                                        fontSize: '16px',
                                        border: '1px solid #edf2f7',
                                        background: productData?.actual === 'N/A' ? 'rgba(246, 246, 246, 0.50)' : 'white',
                                        wordBreak: 'break-all'
                                        // Fixed width for each cell
                                    }}
                                    key={date}
                                    className='production-table-row-data'
                                    
                                >
                                    {isEditing ? (
                                        <div className='edit-prod-table-cell'>
                                            <input
                                                type='number'
                                                value={editState.value}
                                                onChange={handleRowChange}
                                            />
                                            <div>
                                                <img
                                                    onClick={handleSave}
                                                    src={`${ASSET_PREFIX_URL}save-details.png`}
                                                    alt=""
                                                    width="24px"
                                                    height="24px"
                                                    style={{ cursor: 'pointer' }}
                                                />&nbsp;
                                                <img
                                                    onClick={(e) => handleCancel(e)}
                                                    src={`${ASSET_PREFIX_URL}cancel-detail.png`}
                                                    alt=""
                                                    width="24px"
                                                    height="24px"
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                            {productData ? (productData.actual !== 'N/A' ? productData.actual : 'N/A') : 'N/A'}
                                            {productData.is_actual_editable  && <img
                                                onClick={() => handleEdit(productData.id, date, 'actual_production', productData.actual || '', productName)}
                                                src={`${ASSET_PREFIX_URL}edit-ticket.png`}
                                                alt=""
                                                width="12px"
                                                height="12px"
                                                style={{ cursor: 'pointer' }}
                                            />}
                                        </div>
                                        // <span >
                                        //     {/* Format decimal numbers */}
                                        //     {productData ? (productData.actual !== 'N/A' ? productData.actual : 'N/A') : 'N/A'}
                                        // </span>
                                    )}
                                </td>
                            );
                        })}
                    </tr>
                    <tr className='production-table-horizon' style={{ padding: '16px', fontSize: '16px' }}>
                        <td className='production-table-row-head'>Difference</td>
                        {visibleDates.map((date) => {
                            const productData = groupedProducts[productName][date];

                            return (
                                <td
                                    style={{
                                        padding: '16px',
                                        fontSize: '16px',
                                        border: '1px solid #edf2f7',

                                        wordBreak: 'break-all',
                                        background: productData?.difference === 'N/A' ? 'rgba(246, 246, 246, 0.50)' : 'white',
                                        // Fixed width for each cell
                                    }}
                                    key={date}
                                    className='production-table-row-data'
                                >
                                    {/* Format decimal numbers */}
                                    {productData ? (productData.difference !== 'N/A' ? productData.difference : 'N/A') : 'N/A'}
                                </td>
                            );
                        })}
                    </tr>
                </React.Fragment>
            );
        });
    };

    return (
        <>
            <div className='prod-sche-page'>
                <div className='prod-sche-top-nav'>
                    <div className='prod-schedule-back-nav'>
                        <span>Production Schedule</span>
                    </div>
                    <div className='prod-schedule-filters'>
                        <div className='production-date-filters'>
                            <span>Start date</span>
                            <input type='date' value={startDate} onChange={(e) => handleChange(e, 'start')} />
                            <span>-</span>
                            <span>End date</span>
                            <input type='date' value={endDate} min={startDate} onChange={(e) => handleChange(e, 'end')} />

                        </div>
                        <div className='prod-schedule-filters-btns'>
                            <button
                                className={`prod-schedule-filters-btn1 ${selectedFilter === 'daily' ? 'selected' : ''}`}
                                onClick={() => handleFilterClick('daily')}
                            >
                                Daily
                            </button>
                            <button
                                className={`prod-schedule-filters-btn2 ${selectedFilter === 'weekly' ? 'selected' : ''}`}
                                onClick={() => handleFilterClick('weekly')}
                            >
                                Week
                            </button>
                            <button
                                className={`prod-schedule-filters-btn3 ${selectedFilter === 'monthly' ? 'selected' : ''}`}
                                onClick={() => handleFilterClick('monthly')}
                            >
                                Month
                            </button>
                        </div>
                        <div className='prod-schedule-filters-btns'>
                            <button onClick={() => handlePagination('prev')} disabled={currentPage === 1}>&lt;</button>
                            <span style={{ width: '150px' }}>
                                {currentPage} / {totalPages} {/* Display current page and total pages */}
                            </span>
                            <button onClick={() => handlePagination('next')} disabled={currentPage === totalPages}>&gt;</button>
                        </div>

                    </div>
                </div>
                {loading ? <Loading /> : <div className='production-schedule-table'>
                    <table className='production-schedule-table-view'>
                        <thead className='production-schedule-head'>
                            <tr>
                                <th className='production-table-row-head'></th>
                                {visibleDates.map((date, index) => (
                                    <th key={index} className='production-table-row-data' style={{ fontSize: '14px', width: '500px', background: 'rgba(246, 246, 246, 0.50)' }}>{date}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>

                            {/* {scheduleData.map(schedule => renderProductRows(schedule.products))} */}
                            {renderProductRows(groupProductsByName(scheduleData))}

                        </tbody>
                    </table>
                </div>}

            </div>
            {openErrorParts && <ProductionErrorParts errorParts={errorParts} handleclose={() => setOpenErrorParts(!openErrorParts)} />}
        </>

    );
};

export default ProductionSchedule;
