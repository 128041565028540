import React, { useEffect, useRef } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import '../../ListsPages/TicketList.css';

function TicketTitlePopUp({ header, ticketTitle, onClose }) {
  const textareaRef = useRef(null);

  // Auto-resize the textarea based on content
  const autoResize = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto'; // Reset height
      textarea.style.height = `${textarea.scrollHeight + 2}px`; // Adjust for padding
    }
  };

  // Run auto-resize on title change and when component mounts
  useEffect(() => {
    autoResize();
  }, [ticketTitle]);

  return (
    <div className="popup-container">
      <div className="popup-box">
        <div className="popup-header">
          <span className="popup-title">{header}</span>
          <CloseIcon onClick={onClose} className="popup-close-icon" />
        </div>

        <div className="popup-divider"></div>

        <div className="popup-content">
          <textarea
            ref={textareaRef}
            className="popup-textarea"
            value={ticketTitle}
            readOnly
            onInput={autoResize}
          />
        </div>
      </div>
    </div>
  );
}

export default TicketTitlePopUp;
