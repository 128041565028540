import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './SettingPage.css';
import { useParams } from 'react-router-dom';
import { ASSET_PREFIX_URL } from '../../../constants/config';
// import Notification from '../NotificationPage/Notification';
import Privacy from '../PrivacyPage/Privacy';
import General from '../GeneralPage/General';
import UsePage from '../UsersPage/UsePage';
import CompanyPage from '../CompanyPage/CompanyPage';
import CustomTabs from '../../Common/CustomTabs';
// import TicketTemplateTopNav from '../../TicketComponent/TicketTemplateTopNav';

function SettingHome({ toastStyle }) {
  const location = useLocation();
  const navigate = useNavigate();
  const {route} = useParams()
  const searchParams = new URLSearchParams(location.search);
  let defaultState = 'general';
  for (let [key, value] of searchParams) {
    if (key === 'tab') {
      defaultState = value;
    }
  }
  const [selectedSection, setSelectedSection] = useState(0);

  const handleSectionClick = (section) => {
    setSelectedSection(section);
    navigate(`/${route}/settings?tab=${section}`);
  };

  return (
    <>
      {/* <TicketTemplateTopNav/> */}
      <div className='settings-page'>
        <div className='settings-cont'>
          <div className='setting-home'>
            <img src={`${ASSET_PREFIX_URL}settingsIcon.svg`} alt='' width='24px' height='24px' />
            <span>Settings</span>
          </div>
          <div className='workspace-detail-tab'>
                        <CustomTabs
                            tabLabels={["General", "Company info", "Users", "Privacy"]}
                            currentTab={selectedSection}
                            setCurrentTab={setSelectedSection}
                            panels={[
                                () => <General />,
                                () =><CompanyPage />,
                                () =><UsePage toastStyle={toastStyle} />,
                                () =>  <Privacy toastStyle={toastStyle} />,
                            ]}
                        />
                    </div>
         
        </div>
      </div>
    </>
  );
}

export default SettingHome;
