import React, { useState, useCallback } from 'react';
import Select from "react-select";
import axios from "axios";
import NameProfile from './CommonJsx/NameProfile';
import { BASE_URL, HEADERS } from '../../constants/config';
import { toast } from 'react-toastify';

function DesignDropdown({ field, handleDesignSelectChange }) {
    const [designs, setDesigns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [intitalDesign, setInitialDesign] = useState(null);
    const [page, setPage] = useState(1);
    const limit = 10;

    const fetchDesigns = async (pageNumber = 1) => {
        if (loading || !hasMore) return;
        setLoading(true);
        try {
            const designResponse = await axios.get(BASE_URL + "/v1/pdm/get-designs", {
                params: {
                    page: pageNumber,
                    limit
                },
                headers: HEADERS,
            });
            if (!designResponse.data?.meta?.success) {
                toast.error(designResponse.data?.meta.message)
            } else {
                const designs = designResponse.data.data.designs;
                console.log(designs);
                if (designs.length === 0) {
                    setHasMore(false); // Stop fetching when no more members are available
                } else {
                    setDesigns(prevDesigns => [...prevDesigns, ...designs]);
                    setPage(prevPage => prevPage + 1); // Increase the page number for the next fetch
                }
            }
        } catch (error) {
            console.error("Error fetching members:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleMenuScrollToBottom = () => {
        if (hasMore) {
            fetchDesigns(page); // Fetch next batch of members when scrolled to the bottom
        }
    };

    // const handleDesignSelectChange = (selectedOption) => {
    //     alert(selectedOption.value);
    // }
    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: "340px",
            height: '40px',
            backgroundColor: "transparent",
            border: "1px solid #EDF2F7",
            boxShadow: "none",
        }),

        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
        }),

        option: (provided, state) => ({
            ...provided,
            background: state.isSelected ? "transparent" : "white",
            color: state.isSelected ? "black" : "inherit",
            cursor: "pointer",
            ":hover": {
                background: "lightgray",
            },
        }),


    };

    return (
        <Select
            placeholder={field ? field.placeholder : null}
            styles={customStyles}
            isMulti={false}
            options={designs.map((design) => ({
                value: design._id,
                label: `${design.name}-v${design.latest_version.version.major}.${design.latest_version.version.minor}`,
                design
            }))}
            onChange={(selectedOption) => {
                handleDesignSelectChange(selectedOption.design);
            }}
            value={
                field && field.design
                    ? {
                        value: field.design._id,
                        label: `${field.design.name}-v${field.design.latest_version.version.major}.${field.design.latest_version.version.minor}`,
                        design: field.design,
                    }
                    :  null
            }
            onFocus={() => fetchDesigns(1)}
            onMenuScrollToBottom={handleMenuScrollToBottom}
            isLoading={loading}
        />
    );
}

export default DesignDropdown;
