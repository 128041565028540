import React, { useState } from "react";
import "./FaqPage.css";
import { ASSET_PREFIX_URL } from "../../../constants/config";

function FaqPage() {
  const array = [
    {
      question: "Do I need to tell my vendors that we're using Tola?",
      answer: "Do I need to tell my vendors that we're using Tola",
    },
    {
      question: "How do I get started with Tola?",
      answer: "Do I need to tell my vendors that we're using Tola",
    },
    {
      question: "Is our company a good fit for Tola?",
      answer: "Do I need to tell my vendors that we're using Tola",
    },
    {
      question: "What are the benefits of using Tola?",
      answer: "Do I need to tell my vendors that we're using Tola",
    },
    {
      question: "What is the cost of using Tola?",
      answer: "Do I need to tell my vendors that we're using Tola",
    },
    {
      question: "Who is behind Tola?",
      answer: "Do I need to tell my vendors that we're using Tola",
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  return (
    <div className="faqpage">
      <div className="faqpage-left-container">
        <span className="faq-title">FAQ</span>
        <span className="faqpage-visit">
          Visit our
          <span className="faqpage-help-center"> help center </span>
          to get in touch.
        </span>
        <span className="faqpage-visit"> We’re super responsive.</span>
      </div>
      <div className="faqpage-right-container">
        {array.map((i, index) => (
          <div key={index} className={`faqpage-questions ${openIndex === index ? 'open' : ''}`}>
            <img src={`${ASSET_PREFIX_URL}open-answer.webp`} alt="" onClick={() => handleToggle(index)} />
            <div className="faq-ques-ans">
              <span className="faq-answer">{i.question}</span>
              {openIndex === index && <span className="faq-answer" style={{ color: '#8d8c8c' }}>{i.answer}</span>}
            </div>

          </div>
        ))}
      </div>
    </div>
  );
}

export default FaqPage;
