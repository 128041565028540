
import React, { useState } from 'react'
import CloseIcon from "@mui/icons-material/Close";
import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL, HEADERS } from '../../constants/config';

function ResetEBOMtree({ onclose,sequence_id,setUpdatedData }) {
    const handleReset = async() => {
        // if(acti)
        await axios.post(BASE_URL + "/v1/structure-ec/reset-tree", {
            ec_sequence_id:sequence_id,
        },
        {
            headers: HEADERS
        });
    

       
           
        setUpdatedData('reset data')
        onclose()
        // window.location.reload();
        
        
    }

    const handleClose=()=>{
        onclose()
    }
    return (
        <div className='default-popup'>
            <div className='default-popup-container'>
                <div className='closing-div'>
                    <div className='default-closing' onClick={handleClose}>
                        <CloseIcon />
                    </div>
                </div>

                <div className='default-message'>
                    <span>Would you like to reset the changes made in EBOM?</span>


                </div>
                <div className='default-btns'>
                    <button onClick={handleClose}>No</button>
                    <button onClick={handleReset} style={{background:'#610bee',color:'white'}}>Yes</button>
                </div>
            </div>
        </div>
    )
}

export default ResetEBOMtree