const TourSteps = [

    {
        selector: '[data-tour="step-4"]',
        content: "Template name and default version number"
    },
    {
        selector: '[data-tour="step-5"]',
        content: "Choose any version to view template form elements "
    },
    {
        selector: '[data-tour="step-6"]',
        content: "Make version as default"
    },
    {
        selector: '[data-tour="step-7"]',
        content: "Save template details"
    },

];

export default TourSteps

export const templateSteps = [

    {
        selector: '[data-tour="step-1"]',
        // content: "Create your template "
        // content: () => (
        //     <div>
        //       <p>Create your template</p>
        //       <button onClick={() => goToNextStep()}>next</button>
        //       <button onClick={() => goToPreviousStep()}>previous</button>
        //     </div>
        //   ),
        content: ({ goToStep, inDOM,currentStep }) => {
            // console.log('inDOM:', currentStep)
            return (
            <div>
              <h2>Step 1</h2>
              <p>The target element is currently in the DOM.</p>
              <button onClick={()=>goToStep (currentStep+1)}>Custom Next</button>
            </div>
            )
            }
      
    },
    {
        selector: '[data-tour="step-2"]',
        // content: "Click to view the template"
        content: ({ goToStep, inDOM,currentStep }) => {
            // console.log('inDOM:', currentStep)
            return (
            <div>
              <h2>Step 1</h2>
              <p>Click to view the template</p>
              <button onClick={()=>goToStep (currentStep+1)}>Custom Next</button>
            </div>
            )
            }
    },
    {
        selector: '[data-tour="step-3"]',
        content: "Filter template by departments"
    },
    {
        selector: '[data-tour="step-ticket"]',
        content: "Click to view Tickets"
    },
];
export const emptyTemplateSteps = [

    {
        selector: '[data-tour="step-1"]',
        content: "Create your template "
    },
    {
        selector: '[data-tour="step-ticket"]',
        content: "Click to view Tickets"
    },

];

export const ticketSteps = [

    {
        selector: '[data-tour="step-8"]',
        content: "Create your new ticket "
    },
    {
        selector: '[data-tour="step-9"]',
        content: "Click to view tickets assigned to you"
    },
    {
        selector: '[data-tour="step-10"]',
        content: "Filter ticktes by assignee"
    },
    {
        selector: '[data-tour="step-11"]',
        content: "Reset to view all Tickets"
    },
    {
        selector: '[data-tour="step-depart"]',
        content: "Click to view Departments"
    },
];
export const emptyTicketSteps = [

    {
        selector: '[data-tour="step-8"]',
        content: "Create your new Ticket "
    },
    {
        selector: '[data-tour="step-11"]',
        content: "Reset to view all Tickets"
    },
    {
        selector: '[data-tour="step-depart"]',
        content: "Click to view Departments"
    },
];

export const hierarchySteps = [

    {
        selector: '[data-tour="step-8"]',
        content: "Click to Add Members, Assistants, Departments "
    },
    {
        selector: '[data-tour="step-9"]',
        content: "Click to Edit, Remove, Change Manager of the member "
    },
    {
        selector: '[data-tour="step-10"]',
        content: "Click to Add Collaborators"
    },
    {
        selector: '[data-tour="step-template"]',
        content: "Click to view Templates"
    },

];

export const hierarchyAssitantSteps = [

    {
        selector: '[data-tour="step-9"]',
        content: "Click to Edit, Remove, Change Manager of the member "
    },
    {
        selector: '[data-tour="step-10"]',
        content: "Click to Add Collaborators"
    },
    {
        selector: '[data-tour="step-template"]',
        content: "Click to view Templates"
    },

];

export const hierarchyEmptySteps = [

    {
        selector: '[data-tour="step-8"]',
        content: "Click to Add Members,Assistants,Departments "
    },
    {
        selector: '[data-tour="step-10"]',
        content: "Click to Add Collaborators"
    },
    {
        selector: '[data-tour="step-template"]',
        content: "Click to view Templates"
    },

];

export const departmentSteps = [

    {
        selector: '[data-tour="step-1"]',
        content: "Make as favourite or unfavrourite departments"
    },
    // {
    //     selector: '[data-tour="step-9"]',
    //     content: "click to edit ,remove ,changing manager of the member "
    // },
    // {
    //     selector: '[data-tour="step-10"]',
    //     content: "Click to Add collaborators"
    // },

];


export const reactourStyles = {
    popover: (base) => ({
        ...base,
        '--reactour-accent': '#610bee',
        borderRadius: 10,
    }),
    maskArea: (base) => ({ ...base, rx: 10 }),
    maskWrapper: (base) => ({ ...base }),
    badge: (base) => ({ ...base, right: 'auto', left: '-0.8125em' }),
    controls: (base) => ({ ...base,display: 'none'}),
    arrow: (base) => ({ ...base, display: 'none' }),
    dot: (base, state) => ({
        ...base,
        left: '0.8125em', // Adjust the value as needed
    }),
    close: (base) => ({ ...base, left: 'auto', right: 10, top: 10 }),
};