import React from 'react'
import CloseIcon from "@mui/icons-material/Close";

function ApproveRecieve({onclose,handleRecieve}) {
  return (
    <div className='default-popup'>
    <div className='default-popup-container'>
        <div className='closing-div'>
            <div className='default-closing' onClick={onclose}>
                <CloseIcon />
            </div>
        </div>

        <div className='default-message'>
            <span>Have you received parts  ?</span>


        </div>
        <div className='default-btns'>
            <button onClick={onclose}>No</button>
            <button onClick={handleRecieve} style={{ background: '#610bee', color: 'white' }}>Yes</button>
        </div>
    </div>
</div>
  )
}

export default ApproveRecieve