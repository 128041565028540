import React from "react";
import "./UserPage.css";



function Invited({invite,loading}) {
  console.log(invite)
  // const [openDelete, setOpenDelete] = useState(false);
  // const [openCancelInvitation, setOpenCancelInvitation] = useState(false);
  
  // const handleDeleteShow = (index) => {
  //   setOpenDelete(index); // Set the index of the item for which the menu should be open
  // };
  // const handleCancelInvite = () => {
  //   setOpenCancelInvitation(!openCancelInvitation);
  // };
  // useEffect(() => {
  //   inviteMembersList();
  // }, []);

  // const inviteMembersList = async () => {
  //   try {
  //     const headers = {
  //       "x-auth-token": localStorage.getItem("token"),
  //     };
  //     const response = await axios.get(
  //       BASE_URL + "/v1/setting/get-invite-members",
  //       { headers }
  //     );
  //     setInvite(response.data.data.invited_members);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  
  return (
    <>
    
      <div className="member-page">
        
        {invite.map((item) => (
          <div key={item} className="member-details">
            
            <div className="member-email">
              <span>{item.email}</span>
            </div>
            <div className="select-admins">
              <span>Invitation sent</span>
            </div>
            {/* <div className="dot-menu">
              <img
                src={`${ASSET_PREFIX_URL}dots-menu.svg`}
                onClick={() => handleDeleteShow(item)}
                style={{ cursor: "pointer" }}
                alt=""
              />
              {openDelete === item && (
                <button
                  className="invited-delete-btn"
                  style={{ cursor: "pointer" }}
                  onClick={handleCancelInvite}
                >
                  <DeleteOutlineOutlinedIcon />
                  <span>Cancel Invitation</span>
                </button>
              )}
            </div> */}
          </div>
        ))}
      </div>
      {/* {openCancelInvitation && <RemovePopup />} */}
    </>
  );
}

export default Invited;
