import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import "./PartNumberView.css";
import {
  PHOTO_LINK,
  TICKET_ATTACHMENT_BUCKET,
  PARTLIST,
  HEADERS,
  ASSET_PREFIX_URL, BASE_URL,
  BUCKET,
  CATEGORY_UPLOAD_PARTS
} from "../../constants/config";
import { simpleUpload, multiUpload } from "../../constants/helper";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { useNavigate } from "react-router-dom";
import Loading from '../Common/Loading/Loading';
import BomHierarchy from "../Common/BomHierarchy/Bomhierarchy";
import ExcellView from "../Common/BomHierarchy/ExcellView";
import PartNumbersTabPage from "./PartNumbersTabPage";
import { makeByCodeOptions } from "../../constants/helper";
import CustomTabs from "../Common/CustomTabs";
import PartDetailsTab from "./PartDetailsTab";
import PartDesignTab from "./PartDesignTab";


function PartNumberView() {
  let defaultState = 'excel';
  let defaultDetailsState = 'details';
  const [partDetails, setPartDetails] = useState({})
  const [photo, setPhoto] = useState('');
  const [fullName, setFullName] = useState('')
  const [createdDate, setCreatedDate] = useState('');
  const [loading, setLoading] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isBOM, setIsBOM] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [fileSizeError, setFileSizeError] = useState('');
  const [quantity, setQuantity] = useState('');
  const [childLength, setChildLength] = useState('');
  const [partNumber, setPartNumber] = useState('');
  const [shouldEdit, setShouldEdit] = useState(true);
  const [updatedDetails, setUpdatedDetails] = useState(false);
  const [selectedSection, setSelectedSection] = useState(defaultState);
  const [selectedDetails, setSelectedDetails] = useState(defaultDetailsState);
  const [attributeOptions, setAttributeOptions] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [editingValue, setEditingValue] = useState({
    part_title: '',
    description: '',
    quantity: '',
  });
  const [editingField, setEditingField] = useState({
    part_title: false,
    description: false,
    quantity: false,
  });
  const nav = useNavigate();
  const { id } = useParams();
  // const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleFileNameClick = (e, fileKey) => {
    e.preventDefault(); // Prevent the default action

    // Check if the file is a .gltf file
    if (fileKey.endsWith('.gltf')) {
      window.open(`/engineering/part-preview/${fileKey}`, '_blank', 'noopener,noreferrer'); // Navigate to the specific route for GLTF files
    } else {
      window.open(PHOTO_LINK + fileKey, '_blank', 'noopener,noreferrer'); // Open other files in a new tab
    }
  };

  // const _id = id.id
  const handleSectionClick = (section) => {

    // setOpenBomView(!openBomView)
    setSelectedSection(section);



  };
  const handleDetailsSectionClick = (section) => {


    setSelectedDetails(section);
    // if (section === 'parts'){
    //   getPartNumberChildParts()
    // }




  };
  useEffect(() => {
    partNumberDetails();
  }, [id, updatedDetails]);

  const partNumberDetails = async () => {

    try {
      setLoading(true)
      const partNumberDetails = await axios.get(
        `${BASE_URL}/v1/part-nomen/get-part-details/${id}`,
        { headers: HEADERS }
      );
      console.log(partNumberDetails)
      setAttributeOptions(partNumberDetails.data.data.part_details.attribute_data);

      setPhoto(partNumberDetails.data.data.part_details.created_by.photo);
      setFullName(partNumberDetails.data.data.part_details.created_by.fullName);
      setPartDetails(partNumberDetails.data.data.part_details);
      setPartNumber(partNumberDetails.data.data.part_details.part_number)
      setIsBOM(partNumberDetails.data.data.part_details.part_action)
      setUploadedFiles(partNumberDetails.data.data.part_details.attachments)
      setChildLength(partNumberDetails.data.data.part_details.child_parts.length)
      // setChildParts(partNumberDetails.data.data.part_details.childParts)
      setQuantity(partNumberDetails.data.data.part_details.quantity)
      // console.log(partNumberDetails.data.data.part_details.childParts)
      setShouldEdit(partNumberDetails.data.data.should_edit)

      const createdAtDate = new Date(partNumberDetails.data.data.part_details.createdAt);
      const formattedDate = createdAtDate.toISOString().split('T')[0];
      setCreatedDate(formattedDate)
      setLoading(false)
    } catch (error) {
      console.log(error);
    }
  };


  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    handleFile(file);
  };
  const upload = () => {
    document.getElementById("fileupld").click();
  };
  const handleFileDrop = async (event) => {
    event.preventDefault();
    // setDragging(false);
    if (shouldEdit) {
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        const file = files[0];

        handleFile(file);
      }
    }

  };
  const handleDragOver = (event) => {
    event.preventDefault();
    // setDragging(true);
  };
  const getFileIcon = (fileName) => {
    // console.log(fileName)
    const extension = fileName.split(".").pop().toLowerCase();

    if (extension === "pdf") {
      return (
        <div

          style={{ overflow: 'hidden', border: 'none', }} className="extension-type-div">
          <iframe
            src={PHOTO_LINK + fileName}
            title="CSV Preview"
          //   width= '75px'
          // height= '75px'
          // border= 'none'
          // style={{ overflowY: 'hidden', border: 'none',padding:'0px' }}
          // scrolling="no"
          />
        </div>

      );
    }
    else if (extension === "xls" || extension === "xlsx") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}xlsFile.png`}
          alt="Excel"
          width="75px"
          height="75px"
        />
      );
    }
    else if (extension === "xml") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}xmlFile.png`}
          alt="XML"
          width="75px"
          height="75px"
        />
      );
    } else if (extension === "jpg" || extension === 'png' || extension === 'jpeg' || extension === 'svg') {
      return (
        <img src={PHOTO_LINK + fileName} alt=""
          className="png-img-div" />
      );
    }
    else if (extension === "pptx") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}pptxFile.png`}
          alt="ppt"
          width="75px"
          height="75px"
        />
      );
    }
    else if (extension === "csv") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}csvFile.png`}
          alt="csv"
          width="75px"
          height="75px"
        />
      );
    }
    else if (extension === "docx") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}wordFile.png`}
          alt="csv"
          width="75px"
          height="75px"
        />
      );
    } else {
      return <img src={`${ASSET_PREFIX_URL}filesImage.png`} alt="File" width="75px"
        height="75px" />;
    }
  };

  const handleFile = async (file) => {
    setFileSizeError('');
    setUploadError('');
    const fileSizeMB = file.size / (1024 * 1024);  // size in MB
    if (fileSizeMB > 2) {
      setFileSizeError('File size cannot be more than 2 MB');  // Notify the user
      return;  // Exit the function
    }
    //fetch pre signed URL
    setIsLoading(true);
    try {
      const headers = HEADERS
      const preSignedURL = await axios.post(
        `${BASE_URL}/v1/member/get-presigned-url`,
        { bucket_name: BUCKET, file: file.name, category: CATEGORY_UPLOAD_PARTS, filesize: fileSizeMB },
        { headers: HEADERS }
      );
      if (preSignedURL.data.meta.code === 200 && preSignedURL.data.meta.message === 'SUCCESS' && preSignedURL.data.data.url) {
        //upload to s3
        // await axios.put(preSignedURL.data.data.url, file, {
        //   headers: {
        //     'Content-Type': file.type
        //   }
        // });
        if (preSignedURL.data.data.is_mutipart) {
          await multiUpload(preSignedURL.data.data, file, headers);
        } else {
          await simpleUpload(preSignedURL.data.data, file);
        }
        // When uploading a new file, add it to the attachments array

        // If editing, send the updated attachments array along with other updates
        const updatedAttachments = [...partDetails.attachments, { name: file.name, key: preSignedURL.data.data.key }];
        await axios.post(
          `${BASE_URL}/v1/part-nomen/edit-part-details`,
          { part_id: partDetails.part_number, updates: { attachments: updatedAttachments } },
          { headers }
        );
        partNumberDetails()
        // Handle the response as needed

      } else {
        alert('Error generating signed URL')
      }
    } catch (e) {
      setUploadError('Error in uploading file');
    } finally {
      // Set isLoading back to false after upload (whether success or failure)
      setIsLoading(false);
    }
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "180px",
      backgroundColor: "transparent",
      border: "none",
      outline: "none", // Remove the border
      boxShadow: "none", // Remove any box shadow
      //  border: 1px solid  #EDF2F7;
    }),

    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none", // Hide the line (border)
    }),

    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? "transparent" : "white", // Set the background color
      color: state.isSelected ? "black" : "inherit", // Set the text color
      cursor: "pointer", // Add cursor pointer on hover
      ":hover": {
        background: "lightgray", // Change the background color on hover
      },
    }),

    // You can define styles for other parts of the Select component here
  };
  const handleBack = () => {
    nav(PARTLIST);
  };






  const handleUpdate = async (key, value, updateType) => {
    console.log(key)
    console.log(value)
    // Create an object with the provided key and value
    let updatedDetails
    if (updateType) {
      // If updateType is true, update the attribute_data
      updatedDetails = {
        'attribute_data': attributeOptions.map(item => {
          if (item.title === key) {
            return { ...item, value };
          }
          return item;
        })
      };
    } else {
      // If updateType is false, update the key directly
      updatedDetails = {
        [key]: value
      };
    }
    setUpdatedDetails(updatedDetails)
    console.log(updatedDetails)
    // const updatedDetails = {
    //   [key]: value
    // };
    try {
      const headers = HEADERS
      const response = await axios.post(
        `${BASE_URL}/v1/part-nomen/edit-part-details`, { part_id: partDetails.part_number, updates: updatedDetails }, { headers }
      )
      setEditingField((prevState) => ({
        ...prevState,
        [key]: false,
      }));
      // partNumberDetails()
      console.log(response)

    } catch (error) {
      console.log(error)
    }
    // console.log(updatedDetails);
    // Now you have the object with only the provided key-value pair
  };
  const deleteAttachment = async (key) => {

    try {

      const headers = HEADERS
      await axios.post(BASE_URL + '/v1/part-nomen/delete-attachment', { ticketId: partDetails._id, key }, { headers: headers });
      // toast.info(`${key} has been removed `, toastStyle);
      // fetchFormDetails();
      partNumberDetails()
    } catch (error) {
      console.log(error);
    }


  }
  const handleEditing = (e, section) => {

    setEditingField((prevState) => ({
      ...prevState,
      [section]: true,
    }));


  }
  const handleCancelEditing = (e, section) => {

    setEditingField((prevState) => ({
      ...prevState,
      [section]: false,
    }));


  }
  const handleEngineeringChange = (e, section) => {
    console.log(section, ':', e.target.value)
    e.stopPropagation()
    setEditingValue((prevState) => ({
      ...prevState,
      [section]: e.target.value,
    }));
    console.log((prevState) => ({
      ...prevState,
      [section]: e.target.value,
    }))

  }



  return (
    <>
      {loading ? <Loading /> : <div className="part-view-page">

        <div className="part-view-title">
          {/* <img
            src={`${ASSET_PREFIX_URL}template-back-btn.svg`}
            alt=""
            width="24px"
            height="24px"
            onClick={handleBack}
          /> */}
          <span onClick={handleBack} style={{ cursor: 'pointer' }}> &nbsp;Parts / </span>
          <span style={{ color: "#610bee" }}> &nbsp;{partDetails.part_number}</span>
        </div>
        <div className="part-view-cont">
          <div className="part-view-left">
            <div className="part-view-left-title">

              <div className="part-title-view">
                {!editingField.part_title &&

                  <>
                    <span style={{ fontSize: '24px', color: '#001325', fontWeight: '500' }}>{partDetails.part_title}</span>
                    {(shouldEdit) && <img src={`${ASSET_PREFIX_URL}edit-ticket.png`} style={{ width: '15px', height: '15px' }}

                      onClick={(e) => handleEditing(e, 'part_title')} />}

                  </>}

                {editingField.part_title && <textarea
                  value={editingValue.part_title ? editingValue.part_title : partDetails.part_title}
                  onChange={(e) => handleEngineeringChange(e, 'part_title')} />}

              </div>
              {editingField.part_title && <div className="tckt-tmpt-btns">
                {editingValue.part_title ? (
                  <button
                    className="tckt-tmpt-btns-save1"
                    onClick={() => handleUpdate('part_title', editingValue.part_title)}
                  >
                    Save
                  </button>
                ) : ''
                }

                <button
                  className="tckt-tmpt-btns-cnl"
                  onClick={(e) => handleCancelEditing(e, 'part_title')}
                >
                  Cancel
                </button>
              </div>}


            </div>
            <div className="part-title-view">
              {!editingField.description &&

                <>
                  <span style={{ fontSize: '16px', color: '#001325', fontWeight: '500' }}>{partDetails.description}</span>

                  {(shouldEdit) && <img src={`${ASSET_PREFIX_URL}edit-ticket.png`} style={{ width: '15px', height: '15px' }}

                    onClick={(e) => handleEditing(e, 'description')} />}
                </>}

              {editingField.description && <textarea
                value={editingValue.description ? editingValue.description : partDetails.description}
                onChange={(e) => handleEngineeringChange(e, 'description')} />}

            </div>
            {editingField.description && <div className="tckt-tmpt-btns">
              {editingValue.description ? (
                <button
                  className="tckt-tmpt-btns-save1"
                  onClick={() => handleUpdate('description', editingValue.description)}
                >
                  Save
                </button>
              ) : ''
              }

              <button
                className="tckt-tmpt-btns-cnl"
                onClick={(e) => handleCancelEditing(e, 'description')}
              >
                Cancel
              </button>
            </div>}
            <div className="create-part-num-div-form-label-attahment">
              <span>Attachments*</span>
              <div className="filedropzone" onDrop={handleFileDrop}
                onDragOver={handleDragOver}
              >
                <img src={`${ASSET_PREFIX_URL}uploading-icon.svg`} alt="" />
                {isLoading ? <p>Uploading ....</p> :
                  <p>Drop files to attach or<span onClick={upload}> Browse</span></p>}
              </div>
              <input
                type="file"
                id="fileupld"
                accept="image/jpeg, image/png, image/gif"
                style={{ display: "none" }}
                disabled={!shouldEdit}
                onChange={handleFileUpload}
                className="btn-upload"
              />
            </div>
            {!uploadError && <div className="error">{uploadError}</div>}
            {fileSizeError && <div className="error"><span className="error-message"
              style={{ color: '#FF9845' }}><ErrorOutlineOutlinedIcon /> {fileSizeError}</span></div>}
            <div className="uploaded-files-view" style={{ marginBottom: '25px' }}>
              {uploadedFiles.map((file, index) => (
                <div key={index} className="uploaded-file-view">
                  <div className="file-details-view" >

                    <div className="file-image-preview">
                      {getFileIcon(file.key)}
                    </div>
                    {/* <img src={PHOTO_LINK+file.key} width='80px'/> */}
                    <div className="upld-file-details-view">


                      <span title={file.name} className="file-view-name">{file.name.length > 20 ? file.name.substring(0, 15) + '...' : file.name}</span>




                    </div>

                    <div className="file-details-view-hover">

                      <a onClick={(e) => handleFileNameClick(e, file.key)} >
                        <div className="file-box-link">
                          <span title={file.name} className="file-view-name-view">{file.name.length > 20 ? file.name.substring(0, 18) + '...' : file.name}</span>

                        </div>
                      </a>
                      {shouldEdit ? <>
                        <div className="file-delete">
                          <DeleteOutlineOutlinedIcon
                            onClick={() => deleteAttachment(file.key)}
                          />
                        </div>

                      </> : ''}
                    </div>

                  </div>




                </div>
              ))}

            </div>
            {/* {childLength > 0 && ( */}
            <>
              <div
                className="setting-navs"
                style={{ marginBottom: "15px" }}
              >
                <div
                  className={`privacys${selectedSection === "excel" ? "settingActive" : ""
                    }`}
                  onClick={() => handleSectionClick("excel")}
                  style={{
                    cursor: "pointer",
                    boxShadow:
                      selectedSection === "excel"
                        ? "0px 3px 0px 0px #FF7A7A"
                        : "none",
                    padding: "10px 16px",
                    color:
                      selectedSection === "excel" ? "#FF7A7A" : "black",
                  }}
                >
                  Excel view
                </div>

                <div
                  className={`generals${selectedSection === "tree" ? "settingActive" : ""
                    }`}
                  onClick={() => handleSectionClick("tree")}
                  style={{
                    cursor: "pointer",
                    boxShadow:
                      selectedSection === "tree"
                        ? "0px 3px 0px 0px #FF7A7A"
                        : "none",
                    padding: "10px 16px",
                    color: selectedSection === "tree" ? "#FF7A7A" : "black",
                  }}
                >
                  Tree view
                </div>
              </div>
              
            </>
            {/* )
            } */}


            {/* {childLength > 0 && (
              <> */}
            {selectedSection === "excel" && (
              <div className="part-excell-view">
                <ExcellView
                  partNumber={partDetails.part_number}
                  selectedSection={selectedSection}
                  tableView='parts'
                />
                {/* <ExcellView partNumber={partDetails.part_number} /> */}
              </div>
            )}
            {selectedSection === "tree" && (
              <div className="part-bom-view">
                <BomHierarchy
                  partNumber={partDetails.part_number}
                  selectedSection={selectedSection}
                  bomType='parts'
                />
              </div>
            )}
            {/* </>
            )} */}



          </div>

          <div className="part-view-right">
            {/* <div className='setting-navs'> */}
              <CustomTabs
                tabLabels={["Details", `Child parts(${partDetails.child_parts ? partDetails.child_parts.length : '0'})`, 'Design']}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                panels={[
                  () => <PartDetailsTab memberDetails={{fullName, photo}} attributeOptions={attributeOptions} customStyles={customStyles} shouldEdit={shouldEdit} handleUpdate={handleUpdate} makeByCodeOptions={makeByCodeOptions} partDetails={partDetails} createdDate={createdDate}/>,
                  () => <PartNumbersTabPage partNumber={partNumber} shouldEdit={shouldEdit} updatedDetails={updatedDetails} setUpdatedDetails={setUpdatedDetails} />,
                  () => <PartDesignTab partNumber={partNumber} shouldEdit={shouldEdit} updatedDetails={updatedDetails} setUpdatedDetails={setUpdatedDetails} />,
                ]}
                color="rgb(255, 122, 122)"
              />
          </div>
        </div>
      </div>}

    </>

  );
}

export default PartNumberView;