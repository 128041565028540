import React, { useState, useEffect } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import PopupCloseButton from "../Common/PopupCloseButton/PopupCloseButton";
import { ASSET_PREFIX_URL } from '../../constants/config';
import Dots from '../Common/DotsDiv/Dots';

const order = ['srno', 'cpu', 'gst', 'quantity', 'total', 'part_number','part_title'];

const PoTableFields = ({ onclose, setSaveButton, setTableFields, tableFields, template_id }) => {
    const [fields, setFields] = useState(tableFields);
    const [error, setError] = useState('');

    useEffect(() => {
        console.log('Initial Fields:', fields);
    }, [fields]);

    const moveField = (dragIndex, hoverIndex) => {
        if (dragIndex !== hoverIndex) {
            const draggedField = fields[dragIndex];
            const newFields = [...fields];
    
            // Remove the field from its original position
            newFields.splice(dragIndex, 1);
    
            // Insert the field at the new position
            newFields.splice(hoverIndex, 0, draggedField);
    
            // Update ids to maintain uniqueness (optional, depending on your needs)
            newFields.forEach((field, index) => {
                field.id = `${index + 1}`;
            });
    
            setFields(newFields);
            console.log('Fields after move:', newFields);
        }
    };
    

    const handleAddMore = () => {
        const newField = {
            template_id,
            fieldName: 'Add a column',
            id: `${fields.length + 1}`,
            is_default: false,
            is_mandatory: false,
            template_type: 'poItems'
        };
        setFields([...fields, newField]);
        console.log('Fields after add:', [...fields, newField]);
    };

    const handleRemoveField = (id) => {
        const newFields = fields.filter(field => field.id !== id);
        setFields(newFields);
        setError('');
        console.log('Fields after remove:', newFields);
    };

    const handleFieldChange = (index, value) => {
        const newFields = [...fields];
        newFields[index].fieldName = value;
        setFields(newFields);
        console.log('Fields after change:', newFields);
    };

    const handleSave = () => {
        if (error) {
            alert('Please resolve errors before saving.');
        } else {
            const fieldsWithOrder = fields.map((field, index) => ({
                ...field,
                order: index + 1, // Assigning 1-based order
            }));

            console.log('Fields with order:', fieldsWithOrder); // Log the fields with order to the console
            setTableFields(fieldsWithOrder); // Save fields with order
            setSaveButton();
            onclose();
        }
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="notification-page">
                <div className="notification-div">
                    <div className="part-number-popup-text">
                        <span>Item table columns</span>
                        <PopupCloseButton handleClose={onclose} />
                    </div>
                    <div className="part-number-popup-hori" />
                    <div className="po-template-popup-inputs-container">
                        {fields.map((item, index) => (
                            <FieldItem
                                key={item.id}
                                index={index}
                                field={item}
                                moveField={moveField}
                                handleFieldChange={handleFieldChange}
                                handleRemoveField={handleRemoveField}
                            />
                        ))}
                        {error && <div style={{ color: 'red' }}>{error}</div>}
                        <div className="part-number-popup-add-more" onClick={handleAddMore}>
                            <img src={`${ASSET_PREFIX_URL}template-select-add.svg`} alt="Add more" />
                            <span>Add more</span>
                        </div>
                    </div>
                    <div className="part-number-popup-save-btn">
                        <button onClick={handleSave}>Save</button>
                    </div>
                </div>
            </div>
        </DndProvider>
    );
};

const FieldItem = ({ field, index, moveField, handleFieldChange, handleRemoveField }) => {
    const [{ isDragging }, drag] = useDrag({
        type: 'FIELD',
        item: { index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, drop] = useDrop({
        accept: 'FIELD',
        hover: (item) => {
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            // Move the dragged field to the new position
            moveField(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    useEffect(() => {
        console.log('Rendering Field:', field.fieldName);
    }, [field]);

    const opacity = isDragging ? 0.5 : 1;
    const isNonEditable = order.includes(field.inputType);

    return (
        <div ref={(node) => drag(drop(node))} style={{ opacity }} className='po-table-fields-div'>
            <div className="part-num-bottom-box">
                <Dots />
            </div>
            <div className='po-table-fields-input-cont'>
                <div className='po-table-fields-input'>
                    <input
                        type='text'
                        value={field.fieldName}
                        onChange={(e) => handleFieldChange(index, e.target.value)} // Update field name
                        disabled={field.is_default || isNonEditable}
                    />
                    {(!field.is_default && !isNonEditable) && (
                        <img
                            style={{ cursor: 'pointer' }}
                            src={`${ASSET_PREFIX_URL}attri-delete.png`}
                            alt='delete'
                            onClick={() => handleRemoveField(field.id)}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default PoTableFields;
