import React, { useState, useEffect } from "react";
import '../DepartmentPages/DepartmentForm.css'
import axios from "axios";
import Cookies from "js-cookie";
import Select from "react-select";
import {
    ASSET_PREFIX_URL,
    BASE_URL,
    BUCKET,
    CATEGORY_UPLOAD_DESIGNS,
    HEADERS
} from "../../constants/config";
import { simpleUpload, multiUpload } from "../../constants/helper"
import PopupCloseButton from "../Common/PopupCloseButton/PopupCloseButton";
import NameProfile from "../Common/CommonJsx/NameProfile";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AttachmentsFunction from "../Common/CommonJsx/AttachmentsFunction";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';



function DesignCheckoutPopUp({ row, onClose, onClosePopUp }) {
    const [minorVersion, setMinorVersion] = useState(row.latest_version.version.minor);
    const [majorVersion, setMajorVersion] = useState(row.latest_version.version.major);
    const [comment, setComment] = useState('');
    const [errors, setErrors] = useState('');
    const handleChangeVersion = (source) => {
        if (source == 'major') {
            setMajorVersion(row.latest_version.version.major + 1);
            setMinorVersion(row.latest_version.version.minor)
        } else {
            setMajorVersion(row.latest_version.version.major);
            setMinorVersion(row.latest_version.version.minor + 1)
        }
    }
    const validateForm = () => {
        if (row.latest_version.version.major == majorVersion && row.latest_version.version.minor == minorVersion) {
            setErrors("Increment either major or minor version");
        }
        return errors == '';
    };

    const submitForm = async (e) => {
        e.preventDefault(); // Prevent page reload on form submission
        setErrors('')
        if (!validateForm()) return; // Stop if validation fails
        // setIsSubmitting(true); // Disable submit button

        const formData = {
            design_id: row._id,
            comment,
            minor_version: minorVersion,
            major_version: majorVersion,
        };

        try {
            const response = await axios.post(`${BASE_URL}/v1/pdm/checkout-design`, formData, {
                headers: HEADERS,
            });
            console.log("Form submitted successfully:", response.data);
            if (!response.data?.meta?.success) {
                toast(response.data?.meta.message);
            } else {
                onClosePopUp('download', 'Checkout was successful!');
            }
            // navigate to details page
            // nav(WORKSPACE(response.data?.data.sequence_id))
        } catch (error) {
            console.error("Error submitting form:", error);
        } finally {
            // setIsSubmitting(false); // Re-enable submit button
        }
    };

    return (
        <>
            <div className='create-part-number-popup-page' >
                <div className='create-part-num-div' >
                    <div className='create-part-num-div-top'>
                        <span>Checkout details for {row.name}</span>
                        <PopupCloseButton handleClose={onClose} />
                    </div>
                    <div className='create-part-num-div-form' style={{ overflowY: 'scroll', height: 'auto' }}>
                        <div className='create-part-num-div-form-label-input' >
                            <span>Checkout Version: v{majorVersion}.{minorVersion}</span>
                        </div>
                        <div className='create-part-num-div-form-label-input' style={{ flexDirection: 'row' }}>
                            <div style={{ flex: 1 }}>
                                <span>Major version*</span>
                                <div className='create-part-num-div-form-input'>
                                    <input
                                        type='text'
                                        value={
                                            majorVersion
                                        }
                                        className='create-part-num-div-counter-input'
                                        style={{ width: '75%' }}
                                        disabled
                                    // onChange={(e) =>
                                    //     handlemeaningChange(
                                    //         index,
                                    //         e.target.value,
                                    //         item.meaning,
                                    //         item.default_value
                                    //     )
                                    // }
                                    />

                                    {/* <img src={`${ASSET_PREFIX_URL}create-count-minus.png`} alt='Minus Icon' /> */}
                                    <img src={`${ASSET_PREFIX_URL}create-count-plus.png`} alt='Plus Icon' onClick={(option) => handleChangeVersion('major')} />
                                </div>
                                {errors !== '' && <div className="errors-msg-div">
                                    <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />
                                    <span className="error-message">{errors}</span>
                                </div>}
                            </div>
                            <div style={{ flex: 1 }}>
                                <span>Minor version*</span>
                                <div className='create-part-num-div-form-input'>
                                    <input
                                        type='text'
                                        value={
                                            minorVersion
                                        }
                                        className='create-part-num-div-counter-input'
                                        style={{ width: '75%' }}
                                        disabled
                                    // onChange={(e) =>
                                    //     handlemeaningChange(
                                    //         index,
                                    //         e.target.value,
                                    //         item.meaning,
                                    //         item.default_value
                                    //     )
                                    // }
                                    />

                                    {/* <img src={`${ASSET_PREFIX_URL}create-count-minus.png`} alt='Minus Icon' /> */}
                                    <img src={`${ASSET_PREFIX_URL}create-count-plus.png`} alt='Plus Icon' onClick={(option) => handleChangeVersion('minor')} />
                                </div>

                            </div>
                        </div>
                        <div className="create-part-num-div-form-label-input" style={{ marginTop: '1%', flexDirection: 'column' }}>
                            <span>Add comment: </span>
                            <div className="create-part-num-div-form-input" >
                                <textarea
                                    // style={{ width: '40%' }}
                                    type="text"
                                    placeholder="Add comment (optional)"
                                    value={comment} onChange={(e) => setComment(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='create-part-num-div-btns'>
                        <button onClick={submitForm}>Checkout</button>
                    </div>
                </div>

            </div>
        </>
    )
}
export default DesignCheckoutPopUp;
