import React,{useState} from 'react'
import './Template.css'
import { ASSET_PREFIX_URL } from "../../../constants/config";
import { useParams } from 'react-router-dom';

function TeamTemplate({onUpdate,data,onClose}) {
    const [fieldName, setFieldName] = useState(data.fieldName);
    const [is_mandatory, setis_mandatory] = useState(data.is_mandatory);
const {templateId}=useParams();
    const handleFieldNameChange = (event) => {
        setFieldName(event.target.value);
      };
      const handleCheckboxChange = (event) => {
        // Update the state with the new value of the checkbox
        setis_mandatory(event.target.checked);
      };
      const handleUpdate = () => {
        const updatedData = {
            template_id: templateId,
            inputType: 'team',
            is_default: false,
            is_mandatory,
            fieldName
        };
        onUpdate({...data, ...updatedData})
       
        onClose()
      };
      const handleCancel=()=>{
        onClose()
      }
  return (
    <div className='template-text-box-container'>
            <div className='template-text-box-container-heading'>
                <span>Edit field</span>
            </div>
            <div className='template-text-box-container-mandatory-check'>
                <input type="checkbox" checked={is_mandatory}
        onChange={handleCheckboxChange}/>
                <span>Mandatory Field</span>
            </div>
            <div className='template-text-box-container-texttype'>
                <span>Field Name</span>
                <div className='template-text-box-container-field-input'>
                    <input type='text' placeholder='team field'
                    value={fieldName} onChange={handleFieldNameChange}/>
                </div>
            </div>
            <div className='template-text-box-container-text-switches'>
                <span>Field Input</span>
                <div className='template-text-box-container-date'>
                <img src={`${ASSET_PREFIX_URL}template-group-btn.svg`} alt="" width='24px' height='24px' />
                    <span>Only input of integer value is accepted</span>
                </div>
            </div>
            <div className="collab-members-btns1">
                <div className="collab-btns1">
                    <button className="btn-sv1" onClick={handleUpdate}>
                        Save
                    </button>
                    <button className="btn-cl1" onClick={handleCancel}>Cancel</button>
                </div>
            </div>
        </div>
  )
}

export default TeamTemplate
