import React, { useState, useEffect } from "react";
import "../DepartmentPages/Department.css";
import "./TicketList.css";
import axios from "axios";
import { BASE_URL, PHOTO_LINK, WORKSPACE, HEADERS } from "../../constants/config";
import Cookies from "js-cookie";
import Loading from "../Common/Loading/Loading";
import Pagination from "../Common/CommonJsx/Pagination";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CreateWorkspacePopUp from "../PDM/CreateWorkspacePopUp";
import { toast } from "react-toastify";
import NameProfile from "../Common/CommonJsx/NameProfile";
import TicketTitlePopUp from "../Common/CommonJsx/TitlePopUp";
import { useNavigate } from "react-router-dom";

function WorkspaceList() {
  const [loading, setLoading] = useState(false);
  const [isPopupFormOpen, setIsPopupFormOpen] = useState(false);
  const [workspaces, setWorkspaces] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [showDropdown, setShowDropdown] = useState(false);
  const [ticketPopUp, setTicketPopUp] = useState(false);
  const [ticketTitle, setTicketTitle] = useState("");
  const itemsPerPage = 10;
  const nav = useNavigate();

  const handleDescriptionClick = (event, description) => {
    event.stopPropagation();
    setTicketPopUp(true);
    setTicketTitle(description);
  };

  const HandleTitleClose = () => {
    setTicketPopUp(false);
  };

  useEffect(() => {
    fetchWorkspaces();
  }, [currentPage, itemsPerPage]);

  const fetchWorkspaces = async () => {
    try {
      setLoading(true);
      const wrkSpaceResponse = await axios.get(BASE_URL + "/v1/pdm/get-workspaces", {
        params: {
          page: currentPage,
          limit: itemsPerPage
        },
        headers: HEADERS,
      });
      if (!wrkSpaceResponse.data?.meta?.success) {
        toast.error(wrkSpaceResponse.data?.meta.message)
      } else {
        setWorkspaces(wrkSpaceResponse.data.data.workspaces);
        setTotalPages(wrkSpaceResponse.data.data.pagination.totalPages);
        setCurrentPage(wrkSpaceResponse.data.data.pagination.currentPage);
      }
      // setSelectMember(response.data.data);
    } catch (error) {
      console.error("Error fetching options data:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleForm = () => {
    setIsPopupFormOpen(!isPopupFormOpen);
  };
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const goToDetails = (sequence_id) => {
    nav(WORKSPACE(sequence_id))
  }

  return (
    <>
      <div className="part-number-list-container">
        <div className="part-number-list-title">
          <span>WorkSpaces</span>
          <div className="part-number-list-btns">
            <button
              className="tkt-btn"
              onClick={handleForm}
              data-tour="step-8"
            >
              <AddOutlinedIcon />
              New workspace
            </button>

          </div>
        </div>
        <div>
          <table className="part-number-list-table-container">
            <thead>
              <tr>
              <th className="part-number-list-th1">ID</th>
                <th className="part-number-list-th1">Name</th>
                <th className="part-number-list-th1">Description</th>
                <th className="part-number-list-th1">Visibility</th>
                <th className="part-number-list-th1" >Members </th>
                <th className="part-number-list-th1" >Created By</th>
              </tr>
            </thead>
            <>
              {
                (loading) ?
                  <Loading />
                  :
                  (workspaces?.map((workspace) => (
                    <tr
                      key={workspace._id}
                      style={{ cursor: "pointer" }}
                      onClick={() => goToDetails(workspace.sequence_id)} // Arrow function to avoid immediate invocation
                      >
                      <td>{workspace.sequence_id}</td>
                      <td>{workspace.name}</td>
                      <td>

                        {/* {department.data.defaultFieldsData.ticket_title.value} */}
                        {workspace.description && workspace.description.length > 30 ? (
                          <>
                            {workspace.description.slice(
                              0,
                              24
                            )}
                            ...
                            <button
                              className="view-more-text"
                              onClick={(event) =>
                                handleDescriptionClick(event, workspace.description)
                              }
                            >
                              view
                            </button>
                          </>
                        ) : (
                          workspace.description
                        )}</td>
                      <td>{workspace.visibility}</td>
                      {/* <td>{workspace.team_members[0].fullName}</td> */}
                      <td>
                        <div className="members-photo-details" data-tour="step-10">
                          {workspace.team_members.slice(0, 4).map((item) => (
                            <div
                              key={item._id}
                              className={`member-item`}
                            >

                              <div >
                                {item.photo ? (
                                  <img
                                    src={PHOTO_LINK + item.photo}
                                    alt={item.name}
                                    style={{
                                      border: `3px solid`,
                                      borderRadius: "50%",
                                      width: "26px",
                                      height: "26px",
                                      cursor: "pointer",
                                    }}
                                  />
                                ) :
                                  <div style={{
                                    border: `3px solid white`, borderRadius: '50%', cursor: 'pointer'

                                  }}>
                                    <NameProfile userName={item.fullName} width="26px" fontweight='500' borderRadius='50%' />
                                  </div>

                                }
                              </div>



                              <span className="member-name">{item.fullName}</span>
                            </div>
                          ))}

                          {workspace.team_members.slice(4).length > 0 && (
                            <div className="member-item">
                              <button
                                onClick={toggleDropdown}
                                className="show-more-button"
                              >
                                +{workspace.team_members.slice(4).length}
                              </button>
                              {showDropdown && (
                                <div className="members-drop-div">
                                  {workspace.team_members.slice(4).map((item, id) => (
                                    <div
                                      key={item._id}
                                      className="members-dropdown"
                                    >

                                      <div style={{
                                        border: `3px solid white`,
                                      }}>
                                        <NameProfile userName={item.fullName} memberPhoto={item.photo} width="26px" fontweight='500' />

                                      </div>

                                      <span>{item.fullName}</span>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="part-number-photo">
                        <NameProfile userName={workspace.created_by.fullName} width='26px' memberPhoto={workspace.created_by.photo} fontweight='500' />
                        <span>{workspace.created_by.fullName}</span>
                      </td>

                    </tr>
                  )))
              }
            </>
          </table>

        </div>
        {totalPages > 1 && !loading && (
          <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
        )}
      </div>
      {(ticketPopUp) && (
        <TicketTitlePopUp
          header="Description"
          onClose={HandleTitleClose}
          ticketTitle={ticketTitle}
        />
      )}
      {
        isPopupFormOpen && <CreateWorkspacePopUp onClose={handleForm}/>
      }
    </>
  );
}
export default WorkspaceList;