import React, { useState } from 'react';

function GRNexcellView({ tableData }) {
    const [data, setData] = useState(tableData);

    const handleInputChange = (id, field, value) => {
        const updatedData = data.map(item =>
            item.Sr_No === id ? { ...item, [field]: value } : item
        );
        setData(updatedData);
    };

    return (
        <div className="po-grn-table-container">
            <span style={{marginBottom:'24px'}}>Item table</span>
            <table className="po-grn-table" style={styles.poTable}>
                <thead>
                    <tr>
                        <th style={styles.tableHeader}>Sr. no</th>
                        <th style={styles.tableHeaderDescription}>Part number</th>
                        <th style={styles.tableHeader}>PO Qty</th>
                        <th style={styles.tableHeader}>GRN Qty</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={item.Sr_No}>
                            <td style={styles.tableCell}>{index + 1}</td>
                            <td style={styles.tableCell}>{item.Description}</td>
                            <td style={styles.tableCell}>
                                <input
                                    type="text"
                                    style={styles.input}
                                    value={item.purchase_order_qty}
                                    onChange={(e) => handleInputChange(item.Sr_No, 'purchase_order_qty', e.target.value)}
                                />
                            </td>
                            <td style={styles.tableCell}>
                                <input
                                    type="text"
                                    style={styles.input}
                                    value={item.received_qty}
                                    onChange={(e) => handleInputChange(item.Sr_No, 'received_qty', e.target.value)}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

const styles = {
    poTable: {
        width: '100%',
        borderCollapse: 'collapse',
        marginTop: '20px',
        border: '1px solid #ddd',
    },
    tableHeader: {
        padding: '8px',
        border: '1px solid #ddd',
        backgroundColor: '#f0f0f0',
        textAlign: 'left',
        
    },
    tableHeaderDescription: {
        width: '50%',
        padding: '8px',
        backgroundColor: '#f0f0f0',
        textAlign: 'left',
    },
    tableCell: {
        padding: '8px',
        border: '1px solid #ddd',
        position: 'relative',
    },
    input: {
        width: '60%',
        padding: '8px',
        border: '1px solid #edf2f7',
        borderRadius: '4px',
        background: 'white',
    },
    icon: {
        cursor: 'pointer',
    },
    suggestionsBox: {
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 0,
        backgroundColor: 'white',
        border: '1px solid #ddd',
        zIndex: 1000,
        maxHeight: '150px',
        overflowY: 'auto',
    },
    suggestionItem: {
        padding: '8px',
        cursor: 'pointer',
        borderBottom: '1px solid #ddd',
    },
};

export default GRNexcellView;
