import React, { useRef, useState, useEffect } from 'react';
import JoditEditor from 'jodit-react';

function TermsAndConditions({ setEditiorText, editorText, setEditedValue,label,poType ,setDisableUpdate,editable}) {
    
    const editorRef = useRef(null);
    const initialText = (poType === 'create' || poType === 'assets') 
    ? (label.find(item => item.template_type === 'tandc')?.value || '') 
    : editorText;
  
    const [changedText, setChangedText] = useState(initialText);

    const handleEditorChange = (content) => {
        if (content !== editorText) {
            // If content has changed, update changedText state
            setChangedText(content);
            setEditiorText(content); // Optionally, update the parent state as well
        } else {
            // If content is the same as editorText, reset changedText state
            setChangedText(null);
        }
        setDisableUpdate(true);
    };

    useEffect(() => {
        // Set edited value based on whether there are changes or not
        if (changedText !== null) {
            setEditedValue(changedText);
        } else {
            setEditedValue(editorText);
        }
    }, [changedText, editorText, setEditedValue,setDisableUpdate]);

    return (
        <div>
            <span>Terms and Conditions</span>
            
            <div>
            {(poType === 'view' || poType === 'assets-view') && !editable ? (
                   <div 
                   dangerouslySetInnerHTML={{ __html: editorText }} 
                   style={{ width: '100%', border: '1px solid #edf2f7', padding: '10px' }}
               />
                ) : (
                <JoditEditor onChange={handleEditorChange} value={changedText !== null ? changedText : editorText} ref={editorRef} />
                )}
            </div>
        </div>
    );
}

export default TermsAndConditions;
