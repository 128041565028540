import React, { useEffect, useState } from "react";
import "./TicketTemplate.css";
import { toast } from "react-toastify";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { BASE_URL, ASSET_PREFIX_URL } from "../../constants/config";
import SearchIcon from "@mui/icons-material/Search";



function TemplateContainer({ type, fetchDepartList }) {
  const [close, setClose] = useState(false);
  const [departmentNames, setDepartmentNames] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  const [searchClicked, setSearchClicked] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [template, setTemplate] = useState("");
  const [templateError, setTemplateError] = useState("");
  const [departmentError, setDepartmentError] = useState("");


  const nav = useNavigate();

 

  const handleClose = () => {
    setClose(true);
  };

  const handleCancel = () => {
    setSelectedNames([]); // Clear selectedCollaborators state to deselect all checkboxes
    // Uncheck all checkboxes
    const checkboxes = document.querySelectorAll(
      ".collab-mem-list input[type='checkbox']"
    );
    checkboxes.forEach((checkbox) => (checkbox.checked = false));
  };

  useEffect(() => {
    if(type === 'template'){
      fetchDepartments();
    }
    
  }, [type]);

  const fetchDepartments = async () => {
    try {
      const headers = {
        "x-auth-token": localStorage.getItem("token"),
      };
      const response = await axios.get(BASE_URL + "/v1/template/get-dept", { headers });
      const departmentList = response.data.data.departments;
      setDepartmentNames(departmentList);
    } catch (error) {
      console.error("Error fetching options data:", error);
    }
  };

  const handleStartConfiguring = async () => {
    // Reset error states before performing validation
    setTemplateError("");
    setDepartmentError("");
    if (type === 'template') {
      if (template.trim() === "") {
        setTemplateError("Please enter a valid template name.");
        return;
      }

      if (!selectedNames.departmentName || !selectedNames.departmentId) {
        setDepartmentError("Please select a department.");
        return;
      }
    } else {
      if (template.trim() === "") {
        setTemplateError("Please enter a valid template name.");
        return;
      }

    }


    try {
      const headers = {
        "x-auth-token": localStorage.getItem("token"),
      };
      let response
      if (type === 'template') {
        response = await axios.post(
          BASE_URL + "/v1/template/create-template",
          {
            templateName: template,
            departmentNames: selectedNames.departmentName,
            department_id: selectedNames.departmentId
          }, { headers }
        );
      } else if (type === 'ec-template') {
        response = await axios.post(
          BASE_URL + "/v1/ec-template/create-ec-template",
          {
            templateName: template,
            // departmentNames: selectedNames.departmentName,
            // department_id: selectedNames.departmentId
          }, { headers }
        );
      }
      else if (type === 'supply-template') {
        response = await axios.post(
          BASE_URL + "/v1/supply-chain/create-template",
          {
            templateName: template,
            // departmentNames: selectedNames.departmentName,
            // department_id: selectedNames.departmentId
          }, { headers }
        );
      }
      else if (type === 'po-template') {
        response = await axios.post(
          BASE_URL + "/v1/purchase-order/create-template",
          {
            templateName: template,
            // departmentNames: selectedNames.departmentName,
            // department_id: selectedNames.departmentId
          }, { headers }
        );
      } else if (type === 'assets-template') {
        response = await axios.post(
          BASE_URL + "/v1/asset/create-template",
          {
            templateName: template,
            // departmentNames: selectedNames.departmentName,
            // department_id: selectedNames.departmentId
          }, { headers }
        );
      } else if (type === 'pc-template') {
        response = await axios.post(
          BASE_URL + "/v1/prod-change/create-pc-temp-name",
          {
            templateName: template,
            // departmentNames: selectedNames.departmentName,
            // department_id: selectedNames.departmentId
          }, { headers }
        );
      }else if (type === 'warehouse') {
        response = await axios.post(
          BASE_URL + "/v1/warehouse/create-template",
          {
            templateName: template,
            // departmentNames: selectedNames.departmentName,
            // department_id: selectedNames.departmentId
          }, { headers }
        );
      }else if (type === 'location') {
        response = await axios.post(
          BASE_URL + "/v1/location/create-template",
          {
            templateName: template,
            // departmentNames: selectedNames.departmentName,
            // department_id: selectedNames.departmentId
          }, { headers }
        );
      }




      let { message, success } = response.data.meta;

      if (success) {
        if (type === 'template' || type === 'assets-template' || type === 'ec-template' || type === 'supply-template' ||
          type === 'pc-template' || type === 'warehouse'||type === 'location'
        ) {
          fetchDepartList()
          setClose(true)
        }  else if (type === 'po-template') {
          nav(`/templates/purchase-order-template-fields/${response.data.data.template_id}`)


        }

      } else {
        
        toast.error(message);
      }
    } catch (error) {
      // toast.error('template should be unique');
      console.log(error);
    }

  };

  const handleCheckboxChange = (event, department) => {
    if (event.target.checked) {
      setSelectedNames({
        departmentName: department.departmentName,
        departmentId: department.entity_id,
      });

    } else {
      setSelectedNames({}); // Clear the selected department
    }
  };
  const handleSave = () => {
    setSearchInput(
      selectedNames.departmentName
    );
    setSearchClicked(false); // Close the search dropdown after saving
  };

  function hasCheckedCheckbox() {
    return departmentNames.some((department) =>
      selectedNames.departmentName === department.departmentName &&
      selectedNames.departmentId === department.entity_id
    );
  }
  return (

    <div
      className="template-container"
      style={{ display: close ? "none" : "block" }}
    >
      <div className="tick-tmpt">
        <div className="create-template-head">
          <span>Create new
            {type === 'template' && ' template'}
            {type === 'ec-template' && ' EC template'}
            {type === 'supply-template' && ' Supply template'}
            {type === 'po-template' && ' PO template'}
            {type === 'assets-template' && ' Assets template'}
            {type === 'pc-template' && ' PC template'}
            {type === 'warehouse' && ' Warehouse template'}
            {type === 'location' && ' Location template'}
          </span>
          <CloseIcon onClick={handleClose} />
        </div>
        <div className="template-input-container">
          <div className="test-template">
            <span>Template name</span>
            <input
              placeholder="Test Template"
              type="text"
              value={template}
              onChange={(e) => setTemplate(e.target.value)}
            />
            {templateError && (
              <span className="template-error-msg">
                <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />
                {templateError}</span>
            )}
          </div>
          {type === 'template' && <div className="test-assign">
            <span>Assign to</span>
            <div className="entire-division">
              <div className="collab-search"
              // onClick={handleSearchClick}
              >
                <SearchIcon style={{ color: "#001325" }} />
                <input
                  type="text"
                  placeholder="Search Departments"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </div>
              
              {departmentError && (
                <span className="template-error-msg">
                  <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />
                  {departmentError}
                </span>
              )}
              {searchClicked && (
                <div className="search-collab-members">
                  <div className="collab-members-conatiner">
                    {/* Map through the department names to render checkboxes */}
                    {departmentNames.map((department) => (
                      <div className="collab-mem-list" key={department.departmentName}>
                        <input
                          type="checkbox"
                          onChange={(event) => handleCheckboxChange(event, department)}
                          checked={
                            selectedNames.departmentName === department.departmentName &&
                            selectedNames.departmentId === department.entity_id
                          }
                        />
                        <span>{department.departmentName}</span>
                      </div>
                    ))}
                  </div>
                  <div className="collab-members-btns">
                    <div className="collab-btns">
                      {hasCheckedCheckbox() ? <button className="btn-sv-checked" onClick={handleSave}>
                        Save
                      </button> : <button className="btn-sv" onClick={handleSave}>
                        Save
                      </button>}

                      <button className="btn-cl" onClick={handleCancel}>
                        Cancel
                      </button>
                    </div>
                  </div>

                </div>
              )}
            </div>
          </div>}

        </div>

        <div className="template-btns">
          {type === 'template' ? (
            searchInput === '' || template === '' ? (
              <button className="start-config" style={{ border: '1px solid #cabdfc' }} onClick={handleStartConfiguring}>
                Start configuration
              </button>
            ) : (
              <button className="start-config" style={{ background: '#610BEE' }} onClick={handleStartConfiguring}>
                Start configuration
              </button>
            )
          ) : (
            template === '' ? (
              <button className="start-config" style={{ border: '1px solid #cabdfc' }} onClick={handleStartConfiguring}>
                Start configuration
              </button>
            ) : (
              <button className="start-config" style={{ background: '#610BEE' }} onClick={handleStartConfiguring}>
                Start configuration
              </button>
            )
          )}



          <button className="start-cancel" onClick={handleClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>



  );
}

export default TemplateContainer;
