import React from 'react'
import PopupCloseButton from '../Common/PopupCloseButton/PopupCloseButton'
import { PARTVIEW } from '../../constants/config'

function ProductionErrorParts({errorParts,handleclose}) {

    return (
        <div className="notification-page">
            <div className="notification-div">
                <div className="border-bot-partsadd">
                    <div className="part-number-popup-text">
                        <span>parts have a quantity greater than inventory</span>
                    </div>
                    <PopupCloseButton
                        handleClose={handleclose}
                    />
                </div>
                <div className="part-number-popup-border"></div>

                <table className="parts-table-errors" style={{ width: '100%', padding: '0px' }}>
                    <thead>
                        <tr>

                            <th>Part Number</th>
                            <th>Quantity</th>
                            <th>Onsite inventory</th>
                        </tr>
                    </thead>
                    <tbody >
                        {errorParts.map((item, index) => (
                            <tr key={index}>

                                <td >
                                    <a href={PARTVIEW(item._id)} target='_blank' className='link-to-view-ec'>{item._id}</a></td>

                                <td >
                                    {item.quantity}
                                </td>
                                <td>{item.onsite_inventory}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ProductionErrorParts