import React from 'react';

function TermsAndConditions({ editorText }) {
  return (
    <div className='po-tandc-page'>
      <div className='po-tandc-page-div'>
        <div className='po-tandc-page-matter'>
          <span className='po-tandc-page-head'>TERMS AND CONDITIONS</span>
          <div className='horizontal-line-po'></div>
          <div dangerouslySetInnerHTML={{ __html: editorText }} />
        </div>
      </div>
      <div className='po-tandc-page-msg'>
        <span>-- This is system generated PO. Signature not required --</span>
      </div>
    </div>
  );
}

export default TermsAndConditions;
