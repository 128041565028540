import React, { useState } from "react";
import Select from "react-select";
import axios from "axios";
import { BASE_URL } from "../../constants/config";
import PopupCloseButton from '../Common/PopupCloseButton/PopupCloseButton';
import { toast } from "react-toastify";

function CreateMbom({ handleClose, setUpdatedData }) {
  const [options, setOptions] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [selectedOption, setSelectedOption] = useState(null);
  const [productName, setProductName] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // New state to manage loading

  // Fetch options based on the current page
  const fetchOptions = async (page) => {
    if (!hasMore || loading) return; // Prevent multiple fetches when already loading or no more data

    setLoading(true); // Set loading state to true

    try {
      const headers = {
        'x-auth-token': localStorage.getItem("token")
      };

      const response = await axios.get(`${BASE_URL}/v1/manufac-bom/get-root-parts`, {
        params: {
          page: page,
          size: 10
        },
        headers: headers,
      });

      const data = response.data.data.root_part_numbers;

      const transformedData = data.map(part_number => ({
        part_number,
        label: part_number,
        value: part_number,
      }));

      setOptions(prevOptions => {
        if (page === 1) {
          return transformedData;
        }
        return [...prevOptions, ...transformedData];
      });

      setHasMore(data.length > 0); // If data length is 0, there is no more data to fetch
    } catch (error) {
      console.error("Error fetching options data:", error);
    }

    setLoading(false); // Set loading state to false once the fetch is done
  };

  const handleOnChange = (selected) => {
    setSelectedOption(selected);
    setError('');
  };

  // Handle changes to the product name to ensure only valid characters are accepted
  const handleProductNameChange = (e) => {
    const value = e.target.value.replace(/[^a-zA-Z0-9-_ ]/g, ''); // Allow letters, numbers, spaces, hyphens, and underscores
    setProductName(value);
  };

  const handleSave = async () => {
    if (!productName || !selectedOption) {
      setError("Product name and part number are required.");
      return;
    }

    try {
      const headers = {
        'x-auth-token': localStorage.getItem("token")
      };
      const payload = {
        product_name: productName,
        part_number: selectedOption.part_number
      };

      const response = await axios.post(`${BASE_URL}/v1/manufac-bom/create-product`, payload, { headers });
      if (response.data.meta.success === false) {
        toast.error(response.data.meta.message);
      } else {
        setUpdatedData(payload);
        handleClose();
      }
    } catch (error) {
      console.error("Error saving MBOM:", error);
      setError("Failed to save MBOM. Please try again.");
    }
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      overflowY: "auto",
      maxHeight: "200px",
    }),
    menuList: (provided) => ({
      ...provided,
      "::-webkit-scrollbar": "auto",
      "::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "8px",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? "transparent" : "white",
      color: state.isSelected ? "black" : "inherit",
    }),
  };

  const handleScroll = (event) => {
    const bottom = event.target.scrollHeight === event.target.scrollTop + event.target.clientHeight;
    if (bottom && hasMore) {
      setPage(prevPage => {
        const nextPage = prevPage + 1;
        fetchOptions(nextPage);
        return nextPage;
      });
    }
  };

  const handleFocus = () => {
    if (options.length === 0) {
      fetchOptions(1); // Load options when the input is focused
    }
  };

  return (
    <div className='create-part-number-popup-page'>
      <div className="tick-tmpt" style={{ width: '17%' }}>
        <div className="create-template-head">
          <span>Create MBOM</span>
          <PopupCloseButton handleClose={handleClose} />
        </div>
        <div className="test-template">
          <span>Product name</span>
          <input
            placeholder="Enter product name"
            value={productName}
            onChange={handleProductNameChange}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', }}>
          <span>Select part number</span>
          <Select
            id="mySelect2"
            options={options}
            menuPlacement="auto"
            placeholder='Select part number'
            styles={customStyles}
            getOptionLabel={(option) => (
              <div className="select-photo">
                {option.part_number}
              </div>
            )}
            onChange={handleOnChange}
            onMenuScrollToBottom={handleScroll}
            value={selectedOption}
            onFocus={handleFocus} // Trigger fetching options when the input is focused
          />
        </div>

        {error && <div className="error-message">{error}</div>}

        <div className="collab-members-btns" style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', padding: '0px' }}>
          <div className="collab-btns"> 
            <button
              className={`${(productName && selectedOption) ? 'btn-sv2' : 'btn-sv1'} `}
              onClick={handleSave}
              disabled={!productName && !selectedOption}
            >
              Save
            </button>

            <button className="btn-cl" onClick={() => handleClose()}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateMbom;
