import React, { useEffect, useState, useRef } from 'react';
import { ASSET_PREFIX_URL, BASE_URL } from '../../constants/config';
import axios from 'axios';
import PopupCloseButton from "../Common/PopupCloseButton/PopupCloseButton";
import Cookies from "js-cookie";
import Loading from '../Common/Loading/Loading';
import { debounce ,debounceScroll} from '../../constants/helper';
import SearchFilter from '../Common/CommonJsx/SearchFilter';

function StructuringEcParts({ handlePrevStepToParts, handleCreate, typeEC }) {
    const [partOptions, setPartOptions] = useState([]);
    const [selectedPart, setSelectedPart] = useState(null);
    const [search, setSearch] = useState('');
    const [error, setError] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [fetching, setFetching] = useState(false);
    const LIMIT = 20;
    const containerRef = useRef(null);

    useEffect(() => {
        setPartOptions([]);
    }, [search]);

    const handleSearch = (e) => {
        let value = e.target.value;
        setSearch(value);
        setActivePage(1);
    };

    useEffect(() => {
        if (search !== '') {
         
            debounce(getParts)()
        } else {
            getParts();
        }
    }, [activePage, search]);

    

    const handleScroll = debounceScroll(() => {
        const container = containerRef.current;
        if (!hasMore || fetching) {
            return;
        }
        if (container.scrollTop + container.clientHeight >= container.scrollHeight - 20) {
            setActivePage(prevPage => prevPage + 1);
        }
    }, 500);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => {
                container.removeEventListener('scroll', handleScroll);
            };
        }
    }, [containerRef, handleScroll]);

    const getParts = async () => {
        try {
            setFetching(true);
            const headers = {
                "x-auth-token": localStorage.getItem("token"),
            };
    
            const response = await axios.get(
                `${BASE_URL}/v1/structure-ec/get-parent-part-ec`,
                {
                    params: { search: search, page: activePage, size: LIMIT },
                    headers: headers,
                }
            );
    
            const partNumberList = response.data.data.part_number_list;
    
            if (partNumberList && partNumberList.length > 0) {
                const combinedResults = partNumberList || [];
                setPartOptions((prev) => {
                    return [...new Set([...prev, ...combinedResults])];
                });
                setHasMore(combinedResults.length === LIMIT);
            } else {
                setHasMore(false);
            }
            setFetching(false);
        } catch (error) {
            console.error("Error fetching parts:", error);
            setFetching(false);
        }
    };
    

    const toggleSelectPart = (part) => {
       
            if (selectedPart && selectedPart.part_number === part.part_number) {
                setSelectedPart(null);
            } else {
                setSelectedPart(part);
            }
        
    };

    const createEC = (event) => {
        if (selectedPart) {
            // const selectedPartsArray = [{
            //     part_id: selectedPart._id,
            //     part_number: selectedPart.part_number,
            //     quantity: selectedPart.quantity,
            //     part_title: selectedPart.part_title,
            // }];
            // const selectedPartsJson = JSON.stringify(selectedPartsArray);
            handleCreate(event, typeEC, selectedPart.part_number);
        } else {
            setError('Please select a part.');
        }
    };

  

    return (
        <div className="ec-part-page">
            <div className="ec-ticket-home">
                <div className="ex-ticket-title">
                    <span className="ec-title">Add parent part</span>
                    <PopupCloseButton handleClose={handlePrevStepToParts} />
                </div>

                <div className="dept-ticket-steps">
                    <span>step 2 of 2</span>
                </div>
                <img src={`${ASSET_PREFIX_URL}ticket-step2.svg`} alt="" width="100%" />

                <SearchFilter searchTerm={search} handleSearchChange={handleSearch} listType='part number...'/>
                {error !== '' && <span className='error-message'>{error}</span>}
                <div className='part-div-infinite' onScroll={handleScroll} ref={containerRef}>
                    <table className="department-table-part-data">
                        <thead>
                            <tr>
                                <th className='part-num'>Part number</th>
                                <th className='part-tit'>Part title</th>
                                
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {partOptions.map((item) => (
                                <tr className="tr-hover" key={item._id}>
                                    <td>{item.part_number}</td>
                                    <td>{item.part_title}</td>
                                    
                                    <td>
                                        {selectedPart && selectedPart.part_number === item.part_number ?
                                            <img
                                                width='30px' height='30px'
                                                src={`${ASSET_PREFIX_URL}attri-delete.png`}
                                                alt='delete'
                                                onClick={() => toggleSelectPart(item)}
                                            /> :
                                            <img
                                                width='30px' height='30px'
                                                src={`${ASSET_PREFIX_URL}attribute-addmore.png`}
                                                alt='select'
                                                onClick={() => toggleSelectPart(item)}
                                            />}
                                    </td>
                                </tr>
                            ))}
                            {fetching && <tr><td colSpan="4"><Loading /></td></tr>}
                        </tbody>
                    </table>
                </div>

                <div className="depart-form-btns">
                    <button className="dept-form-cancel" onClick={handlePrevStepToParts}>
                        Go back
                    </button>
                    <button className="dept-form-submit" onClick={(event) => createEC(event)}>
                        Create
                    </button>
                </div>
            </div>
        </div>
    );
}

export default StructuringEcParts;
