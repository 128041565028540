import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL, PHOTO_LINK, WORKSPACE, ASSET_PREFIX_URL, HEADERS } from "../../constants/config";
import { formatDateToIST } from "../../constants/helper";


import Cookies from "js-cookie";
import Loading from "../Common/Loading/Loading";
import Pagination from "../Common/CommonJsx/Pagination";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CreateWorkspacePopUp from "../PDM/CreateWorkspacePopUp";
import { toast } from "react-toastify";
import NameProfile from "../Common/CommonJsx/NameProfile";
import TicketTitlePopUp from "../Common/CommonJsx/TitlePopUp";
import { useNavigate } from "react-router-dom";
import "./PDM.css";
import Select from "react-select";
import DesignCheckoutPopUp from "./DesignCheckoutPopUp";
import DesignCheckinPopUp from "./DesignCheckinPopUp";
import DownloadFilesPopUp from "./DownloadFilesPopUp";
import SearchFilter from "../Common/CommonJsx/SearchFilter";
import TuneOutlined from "@mui/icons-material/TuneOutlined";
import Tooltip from '@mui/material/Tooltip';



function WorkspaceActivityLog({ workspace_sequence_id, design_id }) {
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [logs, setLogs] = useState([]);
    const [currentModal, setCurrentModal] = useState(null); // Track which modal to show
    const [currentRow, setCurrentRow] = useState(null); // Track the selected row
    const [ticketPopUp, setTicketPopUp] = useState(false);
    const [ticketTitle, setTicketTitle] = useState("");
    const [searchTerm, setSearchTerm] = useState(""); // Search filter state
    const [resetButton, setResetButton] = useState(false); // Reset button visibility
    const itemsPerPage = 10;
    useEffect(() => {
        fetchLogs();
    }, [currentPage, itemsPerPage, searchTerm]);
    // Dynamically generate options based on row status
    const getOptions = (status) => {
        // if (status == 'checked_in') {
        //     return [
        //         { value: 'checkout', label: 'Checkout' },
        //         { value: 'download', label: 'Download Files' },
        //     ];
        // } else {
        //     return [
        //         { value: 'checkin', label: 'Checkin' },
        //         { value: 'download', label: 'Download Files' }
        //     ];
        // }
        return [
            { value: 'download', label: 'Download Files',  path: `${ASSET_PREFIX_URL}ic-download.svg` },
        ];

    };
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setResetButton(true);
    };

    const handleReset = () => {
        setSearchTerm("");
        setResetButton(false);
        setCurrentPage(1);
    };

    const handleCommentClick = (event, description) => {
        event.stopPropagation();
        setTicketPopUp(true);
        setTicketTitle(description);
    };
    const HandleTitleClose = () => {
        setTicketPopUp(false);
    };
    const onClosePopUp = (modal, message) => {
        console.log(modal, 'modal')
        if (modal) {
            setCurrentModal(modal);
            toast(message)
        } else {
            setCurrentRow(null);
            setCurrentModal(null);
        }
        setSelectedOptions({});
    }

    const onClose = () => {
        setCurrentModal(null);
        setCurrentRow(null);
        setSelectedOptions({});
    }
    // Handle selection change for each row
    const handleChange = (selectedOption, row) => {
        setSelectedOptions((prevSelectedOptions) => ({
            ...prevSelectedOptions,
            [row._id]: selectedOption,
        }));
        // Determine which modal to show based on the selected option
        switch (selectedOption.value) {
            case 'checkout':
                setCurrentModal('checkout');
                break;
            case 'checkin':
                setCurrentModal('checkin');
                break;
            case 'download':
                setCurrentModal('download');
                break;
            default:
                setCurrentModal('checkout');
        }
        // Set the selected row for the modal
        setCurrentRow(row);
    };
    const fetchLogs = async () => {
        try {
            setLoading(true);
            const params = {
                page: currentPage,
                limit: itemsPerPage,
                workspace_sequence_id,
                search: searchTerm,
              };
              
              // Add design_id only if searchTerm is not provided
              if (!searchTerm) {
                params.design_id = design_id;
              }
            const logResponse = await axios.get(BASE_URL + "/v1/pdm//get-activity-log", {
                params,
                headers: HEADERS,
            });
            if (!logResponse.data?.meta?.success) {
                toast.error(logResponse.data?.meta.message)
            } else {
                setLogs(logResponse.data.data.logs);
                setTotalPages(logResponse.data.data.pagination.totalPages);
                setCurrentPage(logResponse.data.data.pagination.currentPage);
            }
            // setSelectMember(response.data.data);
        } catch (error) {
            console.error("Error fetching options data:", error);
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            <div className="workspace-file-list-container" style={{ marginTop: '0px !important' }}>
                <div className="tck-fltr">
                    <SearchFilter
                        searchTerm={searchTerm}
                        handleSearchChange={handleSearchChange}
                        listType="name" />
                    {resetButton && (
                        <button className="reset-btn" onClick={handleReset} data-tour="step-11">
                            reset <TuneOutlined />
                        </button>
                    )}
                </div>
                <div>
                    <table className="part-number-list-table-container">
                        <thead>
                            <tr>
                                <th className="part-number-list-th1">Name</th>
                                <th className="part-number-list-th1">Version</th>
                                {/* <th className="part-number-list-th1">Status</th> */}
                                <th className="part-number-list-th1">Checkout/Checkin By</th>
                                <th className="part-number-list-th1" >Checkout Comment </th>
                                <th className="part-number-list-th1" >Checkin Comment </th>
                                {/* <th className="part-number-list-th1">Checkin By</th> */}
                                <th className="part-number-list-th1" >Action </th>
                            </tr>
                        </thead>
                        <>
                            {
                                (loading) ?
                                    <Loading />
                                    :
                                    (logs?.map((log) => (
                                        <tr
                                            key={log._id}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <td>{log.design.name}</td>
                                            <td>{log.version.major}.{log.version.minor}</td>
                                            {/* <td>{log.status}</td> */}
                                            <td className="part-number-photo">
                                                {
                                                    (log.checked_out_by_details) ?
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}><NameProfile userName={log.checked_out_by_details.fullName} width='26px' memberPhoto={log.checked_out_by_details.photo} fontweight='500' />
                                                            <span>{log.checked_out_by_details.fullName}</span></div> :
                                                        <div></div>
                                                }

                                            </td>
                                            {/* <td>{log.checkout_comment ? log.checkout_comment : ''}</td> */}
                                            <td>

                                                {/* {department.data.defaultFieldsData.ticket_title.value} */}
                                                {log.checkout_comment && log.checkout_comment.length > 30 ? (
                                                    <>
                                                        {log.checkout_comment.slice(
                                                            0,
                                                            24
                                                        )}
                                                        ...
                                                        <button
                                                            className="view-more-text"
                                                            onClick={(event) =>
                                                                handleCommentClick(event, log.checkout_comment)
                                                            }
                                                        >
                                                            view
                                                        </button>
                                                    </>
                                                ) : (
                                                    (log.checkout_comment) ? log.checkout_comment : ''
                                                )}
                                            </td>
                                            <td>

                                                {/* {department.data.defaultFieldsData.ticket_title.value} */}
                                                {log.checkin_comment && log.checkin_comment.length > 30 ? (
                                                    <>
                                                        {log.checkin_comment.slice(
                                                            0,
                                                            24
                                                        )}
                                                        ...
                                                        <button
                                                            className="view-more-text"
                                                            onClick={(event) =>
                                                                handleCommentClick(event, log.checkin_comment)
                                                            }
                                                        >
                                                            view
                                                        </button>
                                                    </>
                                                ) : (
                                                    (log.checkin_comment) ? log.checkin_comment : ''
                                                )}
                                            </td>
                                            {/* <td>{log.checkin_comment ? log.checkin_comment : ''}</td> */}
                                            {/* <td>{formatDateToIST(log.checked_out_at)}</td>
                                            <td className="part-number-photo">
                                                {
                                                    (log.checked_in_by_details) ?
                                                        <div><NameProfile userName={log.checked_in_by_details.fullName} width='26px' memberPhoto={log.checked_in_by_details.photo} fontweight='500' />
                                                            <span>{log.checked_in_by_details.fullName}</span></div> :
                                                        <div></div>
                                                }

                                            </td>
                                            <td>{formatDateToIST(log.checked_in_at)}</td> */}
                                            <td>
                                            {getOptions(log.status).map((option) => (
                                                    <Tooltip key={option.value} title={option.label} arrow>
                                                        <img
                                                            style={{
                                                                width: '24px',
                                                                height: '24px',
                                                                cursor: 'pointer',
                                                                marginRight: '10px',
                                                                padding:'8px !important',
                                                                opacity: 0.8,
                                                                transition: 'opacity 0.3s', // Smooth transition
                                                            }}
                                                            onMouseOver={(e) => (e.currentTarget.style.opacity = 1)} // Full opacity on hover
                                                            onMouseOut={(e) => (e.currentTarget.style.opacity = 0.8)} // Reset opacity on mouse out
                                                            src={option.path}
                                                            alt={option.label}
                                                            onClick={(e) => handleChange(option, log)}
                                                        />
                                                    </Tooltip>
                                                ))}
                                                {/* <Select
                                                    value={selectedOptions[log._id] || null}
                                                    options={getOptions(log.status)} // Generate options based on row status
                                                    onChange={(option) => handleChange(option, log)}
                                                    placeholder="Select an action"
                                                /> */}
                                            </td>
                                        </tr>
                                    )))
                            }

                        </>
                    </table>

                </div>
                {totalPages > 1 && !loading && (
                    <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
                )}
            </div>
            {/* {
                (currentModal === 'checkout') && <DesignCheckoutPopUp row={currentRow} onClose={onClose} onClosePopUp={onClosePopUp}  />
            }
            {
                (currentModal === 'checkin') && <DesignCheckinPopUp row={currentRow} onClose={onClose} />
            }*/}
            {
                (currentModal === 'download')
                &&
                <DownloadFilesPopUp
                    row={{
                        major_version: currentRow.version.major,
                        minor_version: currentRow.version.minor,
                        design_files: currentRow.design_files,
                        supporting_files: currentRow.supporting_files,
                        name: currentRow.design.name
                    }}
                    onClose={onClose} />
            }
            {(ticketPopUp) && (
                <TicketTitlePopUp
                    header="Comment"
                    onClose={HandleTitleClose}
                    ticketTitle={ticketTitle}
                />
            )}
        </>
    );
}
export default WorkspaceActivityLog;