import React, { useState } from 'react'
import './Privacy.css'
import { ASSET_PREFIX_URL } from "../../../constants/config";
import ChangePassPopUp from '../UsersPage/PopUp/ChangePassPopUp'

function Privacy({toastStyle}) {
    const[changepassword,setChangePassword]=useState(false)

    const handleChangePassword =()=>{
        setChangePassword(!changepassword)
    }
  return (
    <>
     <div className='privacy-page'>
        <div className='privacy-password'>
            <div className='privacy-img-cont'>
            <img src={`${ASSET_PREFIX_URL}password-lock.svg`} alt=''/>
            <div className='privacy-content'>
                <span className='privacy-content-head'>Password </span>
                <span className='privacy-content-desc'>Change your account password.</span>
            </div>
            </div>

            <button onClick={handleChangePassword}>
            Change Password
            </button>
        </div>
        {/* <div className='privacy-delete'>
            <div className='privacy-img-cont'>
            <img src={`${ASSET_PREFIX_URL}org-delete.svg`} alt=''/>
            <div className='privacy-content'>
                <span className='privacy-content-head'>Delete organisation </span>
                <span className='privacy-content-desc'>You will begin the process of deleting your account. You can recover your
                     account if it<br/> was accidentally or wrongfully deleted.</span>
            </div>
            </div>

            <button>
            Delete
            </button>
        </div> */}
    </div>
    {changepassword && <ChangePassPopUp toastStyle={toastStyle}/>}
    </>

  )
}

export default Privacy
