import React, { useEffect, useState } from 'react';
import { BASE_URL, GRNPARTSVIEW, HEADERS, LOCATIONVIEW, PARTNUMBER, PARTVIEW, POVIEW } from "../../constants/config";
import axios from "axios";
import Loading from '../Common/Loading/Loading';
import PartNumberView from '../PartNumbersAndAssetsNumbers/PartNumberView';
import Pagination from '../Common/CommonJsx/Pagination';

function WarehouseParts({ sequence_id }) {
  const [partList, setPartsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); // Default to 1
  const [loading, setLoading] = useState(false);
  const [addedGrnIds, setAddedGrnIds] = useState(new Set()); // Track unique GRN IDs
  useEffect(() => {
    getPartsList();

  }, [currentPage]);
  // const handleScroll = () => {
  //   const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
  //   if (scrollTop + clientHeight >= scrollHeight - 50 && !loading) {
  //     setCurrentPage((prevPage) => prevPage + 1); // Increment the page number
  //   }
  // };

  const getPartsList = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/v1/warehouse/get-grn-parts`, {
        params: {
          warehouse_sequence_id: sequence_id,
          page: currentPage,
        },
        headers: HEADERS,
      });
      console.log(response)


      setPartsList(response.data.data.part_list);
      const paginationDetails = response.data.data.pagination;
      setTotalPages(paginationDetails.total_pages);
      setLoading(false); // Done loading
    } catch (error) {
      console.log(error);
      setLoading(false); // Reset loading in case of error
    }
  };
  return (
    <div className="department-table">
       
        <table className="department-table-data" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th style={{ width: "15%" }}>Part name</th>
              <th style={{ width: "15%" }}>Quantity</th>
              <th style={{ width: "20%" }}>Location name</th>
            </tr>
          </thead>
          {loading ? <Loading /> :
          <tbody>
            {partList && partList.map((item, index) => (
              <tr
                key={index}
              >
                <td><a style={{ color: 'blue' }} href={PARTVIEW(item.part_number)} target='_blank'>{item.part_number}</a></td>
                <td>{item.quantity}</td>
                <td><a style={{ color: 'blue' }} href={LOCATIONVIEW(item.location_sequence_id)} target='_blank'>{item.location_sequence_id}</a></td>

              </tr>
            ))}
          </tbody>
}
        </table>
        <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifuContent: 'center' }}>
          {(totalPages > 1 && !loading) && <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />}
        </div>
      

    </div>
  )
}

export default WarehouseParts