import React, { useEffect, useState } from 'react';
import { BASE_URL, GRNPARTSVIEW, HEADERS, LOCATIONVIEW, POVIEW } from "../../constants/config";
import axios from "axios";
import Loading from '../Common/Loading/Loading';
import Pagination from './../Common/CommonJsx/Pagination';

function WarehouseGRN({ sequence_id }) {
  const [grnList, setGrnList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); // Default to 1
  const [loading, setLoading] = useState(false);
  const [addedGrnIds, setAddedGrnIds] = useState(new Set()); // Track unique GRN IDs

  useEffect(() => {
    getGrnList();
  }, [currentPage]);

  const getGrnList = async () => {
   
    setLoading(true); // Set loading before fetching
    try {
      const response = await axios.get(`${BASE_URL}/v1/warehouse/get-grn-details`, {
        params: {
          warehouse_sequence_id: sequence_id,
          page: currentPage,
        },
        headers: HEADERS,
      });

     

      const paginationDetails = response.data.data.pagination;
      setGrnList(response.data.data.grn_details);
      setTotalPages(paginationDetails.total_pages);
      setLoading(false); // Done loading
    } catch (error) {
      console.log(error);
      setLoading(false); // Reset loading in case of error
    }
  };

  

  return (
    <div className="department-table">
      
        <table className="department-table-data" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "15%" }}>GRN ID</th>
            <th style={{ width: "15%" }}>PO number</th>
            <th style={{ width: "15%" }}>Location name</th>
          </tr>
        </thead>
        {loading ? <Loading/> : 
        <tbody>
          {grnList.map((item, index) => (
            <tr key={index}>
              <td><a style={{color:'blue'}} href={GRNPARTSVIEW(item.grn_sequence_id)} target='_blank'>{item.grn_sequence_id}</a></td>
              <td><a style={{color:'blue'}} href={POVIEW(item.purchase_order_number)} target='_blank'>{item.purchase_order_number}</a></td>
              <td ><a style={{color:'blue'}} href={LOCATIONVIEW(item.location_sequence_id)}> {item.location_sequence_id} </a></td>
            </tr>
          ))}
        </tbody>
        }
      </table>
      <div style={{display:'flex',width:'100%',alignItems:'center',justifuContent:'center'}}>
      {(totalPages > 1 && !loading) && <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} /> }
      </div>
      
      
      
      
    </div>
  );
}

export default WarehouseGRN;
