import React from 'react';

// Utility function to format the count
function formatCount(count,partName) {
  if (count < 1000) {
    return count;
  }
  if (count < 1000000) {
    return (count / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
  }
  return (count / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
}

function PartNumberCountFormat({ count }) {
  return (
    <>
    {formatCount(count)}
    </>
  );
}

export default PartNumberCountFormat;
