import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { ASSET_PREFIX_URL, LOGIN } from "../../../constants/config";
import HomeLanding from "./HomeLanding";
import HomeNavs from "./HomeNavs";
import "./HomeTopNav.css";
import { DASHBOARD } from './../../../constants/config';

function HomeTopNav() {
  const [openMenu, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const memberDetails = localStorage.getItem('token');
    if (memberDetails) {
      navigate(DASHBOARD); // Redirect to the org-home route
    } else {
      navigate('/'); // Redirect to login if no member details
    }
  }, [navigate]);
  const navigateTo =() =>{
    const memberDetails = localStorage.getItem('memberDetails');
    if (memberDetails) {
      navigate('/hr/org-home'); // Redirect to the org-home route
    } else {
      navigate('/'); // Redirect to login if no member details
    }
  }
  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  return (
    <div className="home-page-1">
      <div className="home-page-container">
        <div className="home-page-top">
          <img src={`${ASSET_PREFIX_URL}marathon-logo.png`} alt="" />
          <div className="home-page-navs">
            {/* list */}
            <a href="#team-rich">Team Rich</a>
            <a href="#capabilities">Capabilities</a>
            <a href="#compliance">Compliance</a>
            <a href="#security">Security</a>
          </div>
          <div className="home-pg-btns">
            {/* buttons */}
            <button className="try-demo">Try Demo</button>
            <button className="home-login" onClick={()=>navigate(LOGIN)}>Login</button>
          </div>
          <div className="home-pg-menu" onClick={handleMenuOpen}>
            <img src={`${ASSET_PREFIX_URL}menu-icon.webp`} alt="" />
          </div>
        </div>
        {openMenu && <HomeNavs onclose={handleCloseMenu} />}
        <HomeLanding />
      </div>
    </div>
  );
}

export default HomeTopNav;
