import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Marathon from './Components/Marathon';
import './App.css';
import {ErrorContext} from './ErrorContext'

function App() {
    const [error, setError] = useState(null);
    useEffect(() => {
        axios.interceptors.response.use(
            response => response,
            err => {
               
                if (err.response.data.meta.code == 403 && err.response.data.meta.message == 'Access denied') {
                    setError('403 Forbidden');
                }
                return Promise.reject(err);
            }
        );
    }, []);
    return (
        <ErrorContext.Provider value={error}>
            <div className="App">
              <Marathon/>
            </div>
        </ErrorContext.Provider>
  );
}

export default App;
