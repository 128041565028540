import React from "react";
import "./TicketPages.css";
import { ASSET_PREFIX_URL } from "../../constants/config";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

function TicketHome() {
  const nav = useNavigate()
  const handleBack = () => {
    nav(-1)
  }
  return (
    <div className="tch-hm-main" style={{marginTop:'80px'}}>
       <div className="ticket-view-right">
          <ArrowBackIcon onClick={handleBack} style={{ cursor: 'pointer' }} />
          <span>Programs / </span>
          <span style={{ color: "#610BEE" }}>sales</span>
        </div>
      <div className="tkt-prgs">
        <span>Ticket Progress</span>
      </div>
      {/* <div className="sprt-thm">
        <div className="tck-home-search">
          <SearchIcon style={{ color: "#001325" }} />
          <input type="text" placeholder="Search" />
        </div>
        <div className="avatars">
        <img src={avatar} alt="avatar" />
        <img src={avatar} alt="avatar" />
        <img src={avatar} alt="avatar" />
        <img src={avatar} alt="avatar" />
        </div>
        <button className="issue">Only my issue</button>
      </div> */}
      {/* <div className="tck-status">
        <div>OPEN</div>
        <div>ON HOLD</div>
        <div>CLOSE</div>
        <div>CANCELED</div>
      </div> */}
      <div className="tct-hm-btn" >
        <img src={`${ASSET_PREFIX_URL}tickethome.svg`} alt="ticketempty" />
        <span>No ticket has been created yet!</span>
        <button>
          <AddOutlinedIcon />
          New Ticket
        </button>
      </div>
    </div>
  );
}

export default TicketHome;
