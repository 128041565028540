import React from "react";
import "./Capabilities.css"
import { ASSET_PREFIX_URL } from "../../../constants/config";

function Capabilities() {
  return (
    <div className="capabilities-page" id='capabilities'>
      <div className="capabilities">
        <span className="capabilities-head"> Capabilities</span>
        <span className="capabilities-desc">
          One flexible platform for end-to-end action
        </span>
      </div>
      <div className="capability-ticket-template">
        <div className="capability-ticket-template-content">
          <span className="capability-ticket-template-head">
            Custom Ticket Templates
          </span>
          <span className="capability-ticket-template-desc">
            Create team-specific ticket templates, such as design, quality, and
            supply chain
          </span>
        </div>
        <div className="capability-ticket-template-img">
          <img src={`${ASSET_PREFIX_URL}desktop-template.webp`} alt="" />
        </div>
      </div>
      <div className="capability-assigne">
        <div className="capability-assigne-content">
          <span className="capability-assigne-head">
            Add Assignee, Watchers
          </span>
          <span className="capability-assigne-desc">
            Use ticket list views to get a bird’s eye view for project summaries
            and run meetings
          </span>
        </div>
        <div className="capability-assigne-img">
          <img src={`${ASSET_PREFIX_URL}desktop-ticket-assignee.webp`} alt="" />
        </div>
      </div>
      <div className="capability-teams">
        <div className="capability-teams-content">
          <span className="capability-teams-head">Tickets with Teams</span>
          <span className="capability-teams-desc">
            Generate and track dynamic MarathonOS tickets to track tasks and
            projects
          </span>
        </div>
        <div className="capability-teams-img">
          <img src={`${ASSET_PREFIX_URL}desktop-tickets.webp`} alt="" />
        </div>
      </div>
      <div className="capability-org">
        <div className="capability-org-content">
          <span className="capability-org-head">Organisation chart </span>
          <span className="capability-org-desc">
          A single, dynamic window to manage and view your company’s org chart .
          </span>
        </div>
        <div className="capability-org-img">
          <img src={`${ASSET_PREFIX_URL}org-in+teams.webp`} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Capabilities;
