import React from 'react'
import '../../Components/SetupPassword/SetupPassword.css'
import Cookies from 'js-cookie'
import { useLocation } from "react-router-dom";
import { BASE_URL, ASSET_PREFIX_URL } from "../../constants/config";
import Logo from '../Common/Logo'
import axios from 'axios'

function ResendInvite() {
  //const nav = useNavigate();
  const location = useLocation();
  const handleResendInvite = async () => {

    try {
      // const token = localStorage.getItem("token");
      const searchParams = new URLSearchParams(location.search);
      var token = searchParams.get("token");
      Cookies.set("token");
     
      if(token){
        const response = await axios.get(
          BASE_URL + "/v1/member/resent-invite",
          {
            headers: {
              "x-auth-token": `${token}`,
            },
          }
        );
        let { message, success } = response.data.meta;
        if (success) {
          alert(message);
          //nav("/setup-password");
        } else {
          alert("Something went wrong");
        }
      }

    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div  style={{paddingBottom:'100px'}} className='set-up-pass'>
        <div className='left-container'>
            <div> <Logo/></div>
           <div><img src={`${ASSET_PREFIX_URL}exprire.svg`} alt='expired'/></div>
           <h3>This link is no longer active</h3>
           <p>Hi, there your login has expired, because you haven’t used it. Link expires in every 24 hours and can only be used once.</p>

           <div>
            <button onClick = {handleResendInvite} className='nxt-btn'>Request Another Link</button>

               </div>
        </div>
        <div className='right-container' >
            <img src={`${ASSET_PREFIX_URL}setpass-triangle.svg`} alt=''/>
        </div>
    </div>
  )
}

export default ResendInvite
