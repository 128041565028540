import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { ASSET_PREFIX_URL, BASE_URL, HEADERS, WORKSPACE_VISIBILITY_OPTIONS } from "../../constants/config";
import { toast } from "react-toastify";
import Select from 'react-select';
import NameProfile from "../Common/CommonJsx/NameProfile";
import { formatDateToIST } from "../../constants/helper";
import Loading from "../Common/Loading/Loading";

function WorkspaceDetails({ workspace_sequence_id }) {
    const [workspaceDetails, setWorkspaceDetails] = useState({});
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);
    const [enabled, setEnabled] = useState({});
    const [fetch, setFetch] = useState(false);
    const nameRef = useRef(null); // Step 1: Create a ref
    const descriptionRef = useRef(null); // Step 1: Create a ref

    useEffect(() => {
        fetchWorkspaceDetails();
    }, [fetch]);

    const fetchWorkspaceDetails = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${BASE_URL}/v1/pdm//get-workspace-detail`,
                {
                    params: {
                        workspace_sequence_id
                    },
                    headers: HEADERS,
                }
            );
            if (!response.data?.meta?.success) {
                toast.error(response.data?.meta.message)
            } else {
                setWorkspaceDetails(response.data?.data.workspace);
                setFormData(response.data?.data.workspace)
            }
        } catch (error) {
            console.error("Error fetching options data:", error);
        } finally {
            setLoading(false);
        }
    }

    const handleSaveChanges = async (key) => {
        setLoading(true);
        let value = '';
        if (key == 'name') {
            value = nameRef.current.value;
        } else if (key == 'description') {
            value = descriptionRef.current.value;
        }
        try {
            const response = await axios.post(`${BASE_URL}/v1/pdm/update-workspace-details`, {key, value, workspace_sequence_id}, {
                headers: HEADERS,
            });
            console.log("Form submitted successfully:", response.data);
            if (!response.data?.meta?.success) {
                toast(response.data?.meta.message);
            } else {
                setFetch((prev) => !prev);
                setEnabled({});
            }
        } catch (error) {
            console.error("Error fetching options data:", error);
        } finally {
            setLoading(false);
        }
    }
    const handleEdit = (field) => {
        setEnabled({ [field]: true }); // Enable only the specified field
        console.log(enabled);
    };
    const handleCancelEdit = () => {
        setEnabled({});
    }

    const handleChange = (fieldName, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: value,
          }));
    }

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: "292px",
            backgroundColor: "white",
            border: "1px solid #edf2f7",
            boxShadow: "none",
            color: 'black',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
        }),
        option: (provided, state) => ({
            ...provided,
            background: "white",
            color: "black",
            cursor: "pointer",
            ":hover": {
                background: "#610BEE",
                color: "white",
            },
        }),
        singleValue: (provided, state) => ({
            ...provided,
            backgroundColor: "transparent",
            color: "black",
            padding: "5px 10px",
            borderRadius: "4px",
            fontSize: "14px",
        }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: "14px",
            color: "black",
        }),
        menu: (provided) => ({
            ...provided,
            width: "292px",
            backgroundColor: "white",
            color: "black",
        }),
        menuList: (provided) => ({
            ...provided,
            backgroundColor: "white",
        }),
    };

    return (
        <>
            <div className='supplier-page-view' style={{ marginTop: '0px', height: '100vh' }}>
                <div className='supplier-page-bottom-page'>
                    <>
                        {
                            (loading) ?
                                <Loading />
                                :
                                <div className='supplier-page-default-fields'>
                                    <div className='supplier-mandatory-field-container'>
                                        <span className='supply-custom-label-span'>Name:</span>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
                                            <input
                                                ref={nameRef}
                                                type='text'
                                                disabled={!enabled.name}
                                                // placeholder={field.placeholder || ''}
                                                value={formData.name}
                                            onChange={(e) => handleChange('name', e.target.value)}
                                            />
                                            {
                                                (workspaceDetails.is_editable && !enabled.name) && <img
                                                    onClick={() => handleEdit('name')}
                                                    src={`${ASSET_PREFIX_URL}edit-ticket.png`}
                                                    alt="Edit"
                                                    width="24px"
                                                    height="24px"
                                                    style={{ cursor: 'pointer' }}
                                                />

                                            }
                                            {
                                                (enabled.name) && <div style={{ display: 'flex', gap: '5px' }}><img
                                                    onClick={() => handleSaveChanges('name')}
                                                    src={`${ASSET_PREFIX_URL}save-details.png`}
                                                    alt=""
                                                    width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                                    <img
                                                        onClick={handleCancelEdit}
                                                        src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} /></div>
                                            }
                                        </div>
                                    </div>

                                    <div className='supplier-mandatory-field-container'>
                                        <span className='supply-custom-label-span'>Visibility:</span>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
                                            <Select
                                                styles={customStyles}
                                                disabled={true}
                                                options={WORKSPACE_VISIBILITY_OPTIONS}
                                                value={WORKSPACE_VISIBILITY_OPTIONS.find(
                                                    (option) => option.value === workspaceDetails.visibility
                                                )}
                                            // onChange={(selectedOption) => handleChange(fieldKey, selectedOption.value)}
                                            />
                                            {
                                                (workspaceDetails.is_editable) && <img
                                                    // onClick={() => handleEdit(fieldKey)}
                                                    src={`${ASSET_PREFIX_URL}edit-ticket.png`}
                                                    alt="Edit"
                                                    width="24px"
                                                    height="24px"
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            }
                                            {/* <img
                                    // onClick={() => handleSaveChanges(fieldKey)} 
                                    src={`${ASSET_PREFIX_URL}save-details.png`}
                                    alt=""
                                    width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                <img
                                    // onClick={handleCancelEdit} 
                                    src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} /> */}
                                        </div>
                                    </div>

                                    <div className='supplier-mandatory-field-container'>
                                        <span className='supply-custom-label-span'>Created By:</span>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                            <NameProfile userName={workspaceDetails.created_by?.fullName} width='32px' memberPhoto={workspaceDetails.created_by?.photo} fontweight='800' />
                                            <span>{workspaceDetails.created_by?.fullName}</span></div>
                                        <span className='supply-custom-label-span' style={{ marginTop: '2%' }}>Created On:</span>
                                        <span>{formatDateToIST(workspaceDetails.createdAt)}</span>
                                    </div>
                                    <div className='supplier-mandatory-field-container'>
                                        <span className='supply-custom-label-span'>Description:</span>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
                                            <textarea
                                                style={{ height: '10vh' }}
                                                className='supply-textarea'
                                                // placeholder={field.placeholder || ''}
                                                ref={descriptionRef}
                                                disabled={!enabled.description}
                                                // placeholder={field.placeholder || ''}
                                                value={formData.description}
                                                onChange={(e) => handleChange('description', e.target.value)}
                                            // onChange={(e) => handleChange(fieldKey, e.target.value)}
                                            />
                                            {
                                                (workspaceDetails.is_editable &&  !enabled.description) && <img
                                                    onClick={() => handleEdit('description')}
                                                    src={`${ASSET_PREFIX_URL}edit-ticket.png`}
                                                    alt="Edit"
                                                    width="24px"
                                                    height="24px"
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            }
                                            {
                                                (enabled.description) && <div style={{ display: 'flex', gap: '5px' }}><img
                                                    onClick={() => handleSaveChanges('description')}
                                                    src={`${ASSET_PREFIX_URL}save-details.png`}
                                                    alt=""
                                                    width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                                    <img
                                                        onClick={handleCancelEdit}
                                                        src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} /></div>
                                            }
                                            {/* <img
                                    // onClick={() => handleSaveChanges(fieldKey)} 
                                    src={`${ASSET_PREFIX_URL}save-details.png`}
                                    alt=""
                                    width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                <img
                                    // onClick={handleCancelEdit} 
                                    src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} /> */}
                                        </div>
                                    </div>
                                    {
                                        (workspaceDetails.visibility == 'private') && <div className='supplier-mandatory-field-container'>
                                            <div style={{ flexDirection: 'row' }}>
                                                <span className='supply-custom-label-span'>Team Members:</span>
                                                <span style={{ marginLeft: '27%' }}>
                                                    {
                                                        (workspaceDetails.is_editable) && <img
                                                            // onClick={() => handleEdit(fieldKey)}
                                                            src={`${ASSET_PREFIX_URL}edit-ticket.png`}
                                                            alt="Edit"
                                                            width="24px"
                                                            height="24px"
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    }
                                                </span>
                                            </div>
                                            {workspaceDetails.team_members?.map((member) => (
                                                <div
                                                    key={member._id} // Always provide a unique key when rendering lists
                                                    style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                                                >
                                                    <NameProfile
                                                        userName={member.fullName}
                                                        width='32px'
                                                        memberPhoto={member.photo}
                                                        fontweight='800'
                                                    />
                                                    <span>{member.fullName}</span>
                                                </div>
                                            ))}

                                        </div>
                                    }

                                    {/* <div className='supplier-mandatory-field-container'>
                                        <span className='supply-custom-label-span'>Created On:</span>
                                        <span>{formatDateToIST(workspaceDetails.createdAt)}</span>
                                    </div> */}

                                </div>
                        }
                    </>
                </div>
            </div>
        </>
    );
}
export default WorkspaceDetails;