import React from 'react'
import { ASSET_PREFIX_URL } from '../../constants/config'
import RightContainer from '../Common/RightContainer'
import Logo from '../Common/Logo'
import './WelcomeAboard.css'
// import LeftContainer from '../Common/LeftContainer'

function WelcomeAboard() {
    return (
        <div className='welcome-page' style={{display:'flex'}}>
           
                <div className="left-container">
                    <div>
                        <Logo />
                    </div>
                    <div className='welcome-title'>
                        <img src={`${ASSET_PREFIX_URL}hi_logo.svg`} alt=''/>
                        <span>Welcome aboard!</span>
                    </div>
                    <div className='welcome-desc'>
                        <span>We are thrilled to welcome you to our team and look</span>
                        <span>forward to the impact you'll make with Marathon!</span>
                    </div>
                    <div className="nxt-btn-wel">
                        <button >
                            continue
                        </button>

                    </div>
                </div>

               
           
            <RightContainer/>
        </div>
    )
}

export default WelcomeAboard