import React from 'react';

function InnovoiceTable({ tableViewData }) {
  if (!tableViewData || tableViewData.length === 0) return null;

  // Calculate totals dynamically based on 'Total cost' column
  let totalBeforeTax = 0;
  tableViewData.forEach(item => {
    totalBeforeTax += parseFloat(item['total_cost'].value) || 0;
  });

  const gstRate = 18; // GST percentage
  const gstAmount = (totalBeforeTax * gstRate) / 100;
  const totalAfterTax = totalBeforeTax + gstAmount;

  // Find the index of the 'Total cost' column
  const totalCostIndex = Object.keys(tableViewData[0]).indexOf('total_cost');

  return (
    <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
      <thead>
        <tr style={{ backgroundColor: '#f0f0f0', textAlign: 'left' }}>
          {Object.keys(tableViewData[0]).map((key, index) => (
            <th key={index} style={{ padding: '8px', border: '1px solid #ddd' }}>
              {key.toUpperCase().replace(/_/g, ' ')} {/* Replace underscores with spaces */}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableViewData.map((item, index) => (
          <tr key={index}>
            {Object.values(item).map((value, idx) => (
              <td key={idx} style={{ padding: '8px', border: '1px solid #ddd' }}>
                {typeof value === 'object' ? value.value : value}
              </td>
            ))}
          </tr>
        ))}
        <tr style={{ backgroundColor: '#f2f2f2' }}>
          {Array(Object.keys(tableViewData[0]).length).fill('').map((_, idx) => (
            idx === totalCostIndex ? (
              <td key={idx} style={{ padding: '8px', border: '1px solid #ddd' }}>Total ₹ {totalBeforeTax.toFixed(2)}</td>
            ) : (
              <td key={idx} style={{ padding: '8px', border: '1px solid #ddd' }}></td>
            )
          ))}
        </tr>
        {/* <tr>
          {Array(Object.keys(tableViewData[0]).length).fill('').map((_, idx) => (
            idx === totalCostIndex ? (
              <td key={idx} style={{ padding: '8px', border: '1px solid #ddd' }}>₹ {gstAmount.toFixed(2)}</td>
            ) : (
              <td key={idx} style={{ padding: '8px', border: '1px solid #ddd' }}></td>
            )
          ))}
        </tr>
        <tr style={{ backgroundColor: '#d8f5d8' }}>
          {Array(Object.keys(tableViewData[0]).length).fill('').map((_, idx) => (
            idx === totalCostIndex ? (
              <td key={idx} style={{ padding: '8px', border: '1px solid #ddd' }}>Total ₹ {totalAfterTax.toFixed(2)}</td>
            ) : (
              <td key={idx} style={{ padding: '8px', border: '1px solid #ddd' }}></td>
            )
          ))}
        </tr> */}
      </tbody>
    </table>
  );
}

export default InnovoiceTable;
