import React, { Suspense, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Canvas, useThree, useFrame } from '@react-three/fiber';
import { useGLTF, OrbitControls, Environment } from '@react-three/drei';
import * as THREE from 'three';

// Utility function for logging
const log = (message, object) => {
    console.log(message, object);
    if (object instanceof Error) {
        console.error(object);
    }
};

// Camera controls component for rotating and zooming
function CameraController({ zoomIn, zoomOut, rotate }) {
    const { camera } = useThree();
    const distance = camera.position.length(); // Distance from the camera to the target (0, 0, 0)
  
    useEffect(() => {
      const rotationStep = Math.PI / 12; // Rotation step size
      const zoomStep = 0.5; // Zoom step size
  
      // Handle Zoom In/Out
      if (zoomIn) {
        camera.position.add(camera.getWorldDirection(new THREE.Vector3()).multiplyScalar(zoomStep)); // Zoom in
      }
      if (zoomOut) {
        camera.position.add(camera.getWorldDirection(new THREE.Vector3()).multiplyScalar(-zoomStep)); // Zoom out
      }
  
      // Get current spherical coordinates
      const spherical = new THREE.Spherical();
      spherical.setFromVector3(camera.position);
  
      // Adjust spherical coordinates for rotation
      if (rotate === 'up') spherical.phi = Math.max(0.1, spherical.phi - rotationStep); // Prevent flipping over at phi = 0
      if (rotate === 'down') spherical.phi = Math.min(Math.PI - 0.1, spherical.phi + rotationStep); // Prevent flipping over at phi = π
      if (rotate === 'left') spherical.theta -= rotationStep; // Rotate left
      if (rotate === 'right') spherical.theta += rotationStep; // Rotate right
  
      // Convert back to Cartesian coordinates and update camera position
      camera.position.setFromSpherical(spherical);
      camera.lookAt(0, 0, 0); // Ensure camera looks at the center of the scene
  
    }, [zoomIn, zoomOut, rotate, camera]);
  
    return null; // No UI component
  }
  
  

// Component that updates camera based on selected view
function CameraControls({ currentView }) {
    const { camera, invalidate } = useThree(); // Access the camera and trigger re-render

    useEffect(() => {
        const distance = 5; // Adjust the zoom level based on your model's size

        if (!camera) return; // Ensure camera is ready

        // Update camera position based on the selected view
        switch (currentView) {
            case 'FRONT':
                camera.position.set(0, 0, distance);
                break;
            case 'BACK':
                camera.position.set(0, 0, -distance);
                break;
            case 'LEFT':
                camera.position.set(-distance, 0, 0);
                break;
            case 'RIGHT':
                camera.position.set(distance, 0, 0);
                break;
            case 'TOP':
                camera.position.set(0, distance, 0);
                break;
            case 'BOTTOM':
                camera.position.set(0, -distance, 0);
                break;
            case 'ISOMETRIC':
                camera.position.set(distance, distance, distance);
                break;
            default:
                camera.position.set(0, 0, distance); // Default to front view
        }

        camera.lookAt(0, 0, 0); // Ensure the camera looks at the center of the model
        invalidate(); // Trigger a re-render in the Canvas
    }, [currentView, camera, invalidate]);

    return null; // No UI needed for this component
}

function Model({ url, explosionFactor, positions, onSceneLoaded, setParts }) {
    const { scene } = useGLTF(url, undefined, (error) => log('Error loading model:', error));
    const modelRef = useRef();
    const { camera } = useThree();

    useEffect(() => {
        if (scene) {
            log('Model loaded successfully. Scene:', scene);
            onSceneLoaded(scene); // Send scene back to PartDesign

            // Center and scale the model
            const box = new THREE.Box3().setFromObject(scene);
            const center = box.getCenter(new THREE.Vector3());
            const size = box.getSize(new THREE.Vector3());
            const maxDim = Math.max(size.x, size.y, size.z);
            const scale = 2 / maxDim;
            scene.scale.setScalar(scale);
            scene.position.sub(center.multiplyScalar(scale));
            if (camera) {
                // Position camera
                camera.position.set(0, 0, 5);
                camera.lookAt(0, 0, 0);
            }
            const parts = [];
            scene.traverse((child) => {
              if (child.isMesh) {
                parts.push({
                  id: child.uuid,
                  name: child.name || 'Unnamed Part',
                  mesh: child,
                  visible: true,
                  originalMaterial: child.material, // Store original material
                });
              }
            });
            setParts(parts); // Store the parts in state for the assembly tree
        }
    }, [scene, camera, setParts]);

    // Smoothly interpolate between original and exploded positions on each frame
    useFrame(() => {
        if (!positions || positions.length === 0) return; // Ensure positions exist
        positions.forEach(({ child, originalPosition, explodedPosition }) => {
            if (!child || !originalPosition || !explodedPosition) return; // Ensure all data exists
            // Interpolate between original and exploded positions based on explosion factor
            const targetPosition = new THREE.Vector3().lerpVectors(
                originalPosition,
                explodedPosition,
                explosionFactor // This value controls the extent of the explosion
            );
            child.position.lerp(targetPosition, 0.5); // Smoothly transition toward the target position
        });
    });

    return scene ? <primitive object={scene} ref={modelRef} /> : null;
}

export default function PartDesign() {
    const [explosionFactor, setExplosionFactor] = useState(0.0000); // State for explosion factor
    const [positions, setPositions] = useState([]);
    const [loadedScene, setLoadedScene] = useState(null); // Store scene reference here
    const [currentView, setCurrentView] = useState('FRONT'); // Keep track of the current view
    const [parts, setParts] = useState([]); // Store parts for the assembly tree
    const { filekey } = useParams();
    const baseUrl = 'https://member-images.s3.ap-south-1.amazonaws.com/';
    const url = `${baseUrl}${filekey}`;
    const [zoomIn, setZoomIn] = useState(false);
    const [zoomOut, setZoomOut] = useState(false);
    const [rotateDirection, setRotateDirection] = useState('');
  
    // Handle zoom and rotation controls
    const handleZoomIn = () => setZoomIn(true);
    const handleZoomOut = () => setZoomOut(true);
    const handleRotate = (direction) => setRotateDirection(direction);
    // Define a common button style for the controls
    const buttonStyle = {
        backgroundColor: 'transparent',
        border: 'none',
        color: 'white',
        fontSize: '20px',
        cursor: 'pointer',
        padding: '5px',
    };

    // Reset zoom/rotate triggers
    useEffect(() => {
        if (zoomIn || zoomOut || rotateDirection) {
        // Reset zoom/rotate triggers after they are handled by the CameraController
        const timeout = setTimeout(() => {
            setZoomIn(false);
            setZoomOut(false);
            setRotateDirection('');
        }, 100); // Reset after 100ms to allow smooth updates

        return () => clearTimeout(timeout); // Cleanup timeout
        }
    }, [zoomIn, zoomOut, rotateDirection]);


    // Handle part visibility toggle
    const handlePartVisibilityChange = (id) => {
        setParts((prevParts) =>
        prevParts.map((part) =>
            part.id === id ? { ...part, visible: !part.visible } : part
        )
        );
        const updatedPart = parts.find((part) => part.id === id);
        if (updatedPart) {
        updatedPart.mesh.visible = !updatedPart.mesh.visible; // Toggle visibility in the 3D scene
        }
    };
    // Handle part hover to highlight the part
    const handlePartHover = (id, isHovering) => {
        const part = parts.find((part) => part.id === id);
        if (part) {
        if (isHovering) {
            part.mesh.material = new THREE.MeshBasicMaterial({
            color: 'yellow', // Highlight with yellow
            });
        } else {
            part.mesh.material = part.originalMaterial; // Restore original material
        }
        }
    };

    // Function to compute exploded positions dynamically based on the model structure
    const computeExplodedPositions = (scene) => {
        if (!scene) return; // Ensure scene is loaded
        const originalPositions = [];
        const modelBox = new THREE.Box3().setFromObject(scene);
        const modelCenter = modelBox.getCenter(new THREE.Vector3()); // Get the center of the model

        scene.traverse((child) => {
            if (child.isMesh) {
                const childBox = new THREE.Box3().setFromObject(child);
                const childCenter = childBox.getCenter(new THREE.Vector3());
                const direction = childCenter.clone().sub(modelCenter).normalize(); // Get direction from model center to child

                // Store original position (child's bounding box center)
                const originalPosition = child.position.clone();
                
                // Calculate the exploded position once based on a maximum explosion factor
                const explodedPosition = originalPosition.clone().add(direction.multiplyScalar(1)); // Exploded position at max explosion

                originalPositions.push({
                    child,
                    originalPosition: originalPosition, // Store original position
                    explodedPosition: explodedPosition  // Store exploded position
                });
            }
        });
        setPositions(originalPositions); // Save the original and exploded positions
    };

    // Reset functionality to return the model to its original position
    const handleResetClick = () => {
        setExplosionFactor(0.0000); // Reset explosion factor to initial value
        // if (loadedScene) {
        //     computeExplodedPositions(loadedScene); // Reset positions
        // }
    };

    // Callback to receive the loaded scene from the Model component
    const handleSceneLoaded = (scene) => {
        setLoadedScene(scene); // Store scene reference when the model loads
        computeExplodedPositions(scene); // Initially compute positions based on the model structure
    };

    // Handle slider change and immediately recompute exploded positions
    const handleSliderChange = (event) => {
        const newExplosionFactor = parseFloat(event.target.value);
        setExplosionFactor(newExplosionFactor); // Update explosion factor based on slider position
    };

    // Handle the increment and decrement of explosion factor via buttons
    const incrementExplosionFactor = () => {
        setExplosionFactor((prev) => {
            const newFactor = Math.min(prev + 0.0002, 1); // Cap the maximum value at 1
            return newFactor;
        });
    };

    const decrementExplosionFactor = () => {
        setExplosionFactor((prev) => {
            const newFactor = Math.max(prev - 0.0002, 0.0000); // Set minimum value at 0.0001
            return newFactor;
        });
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <div style={{ display: 'flex', flex: 1 }}>
                <div style={{ flex: 2, border: '1px solid black' }}>
                    <Canvas>
                        <ambientLight />
                        <Environment preset="studio" />
                        <Suspense fallback={<mesh><boxGeometry args={[1, 1, 1]} /><meshStandardMaterial color="red" /></mesh>}>
                            <Model url={url} explosionFactor={explosionFactor} positions={positions} onSceneLoaded={handleSceneLoaded}  setParts={setParts} />
                        </Suspense>
                        <CameraControls currentView={currentView}/>
                          {/* Camera Controller */}
                        <CameraController zoomIn={zoomIn} zoomOut={zoomOut} rotate={rotateDirection} />
                        <OrbitControls />
                    </Canvas>
                </div>
                {/* Camera Control UI */}
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '10px',
                transform: 'translateY(-50%)',
                backgroundColor: '#e74c3c',
                padding: '20px',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: 'white',
            }}>
                {/* Rotation Controls */}
                <div style={{ marginBottom: '20px' }}>
                <button onClick={() => handleRotate('up')} style={buttonStyle}>▲</button>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                <button onClick={() => handleRotate('left')} style={buttonStyle}>◀</button>
                <button onClick={() => handleRotate('right')} style={buttonStyle}>▶</button>
                </div>
                <div style={{ marginBottom: '20px' }}>
                <button onClick={() => handleRotate('down')} style={buttonStyle}>▼</button>
                </div>

                {/* Zoom Controls */}
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '60px' }}>
                <button onClick={handleZoomOut} style={buttonStyle}>-</button>
                <button onClick={handleZoomIn} style={buttonStyle}>+</button>
                </div>
            </div>
            {/* Assembly Tree */}
            <div style={{ flex: 1, padding: '10px', backgroundColor: '#2a2a2a', color: '#fff' }}>
            <div style={{ padding: '10px', backgroundColor: '#e74c3c', color: '#fff' }}>
                <span style={{ fontWeight: 'bold' }}>Assembly Tree</span>
            </div>
            <div style={{ maxHeight: '400px', overflowY: 'scroll', padding: '10px', backgroundColor: '#333' }}>
                {parts.map((part) => (
                <div
                    key={part.id}
                    style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '5px',
                    borderBottom: '1px solid #444',
                    }}
                    onMouseEnter={() => handlePartHover(part.id, true)} // Highlight on hover
                    onMouseLeave={() => handlePartHover(part.id, false)} // Remove highlight on hover end
                >
                    <input
                    type="checkbox"
                    checked={part.visible}
                    onChange={() => handlePartVisibilityChange(part.id)}
                    style={{ marginRight: '10px' }}
                    />
                    <span>{part.name}</span>
                </div>
                ))}
            </div>
            </div>
        </div>
            
            <div style={{ padding: '10px', border: '1px solid black', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                 {/* Views Buttons */}
                 <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                    <button onClick={() => setCurrentView('FRONT')}>FRONT</button>
                    <button onClick={() => setCurrentView('BACK')}>BACK</button>
                    <button onClick={() => setCurrentView('LEFT')}>LEFT</button>
                    <button onClick={() => setCurrentView('RIGHT')}>RIGHT</button>
                    <button onClick={() => setCurrentView('TOP')}>TOP</button>
                    <button onClick={() => setCurrentView('BOTTOM')}>BOTTOM</button>
                    <button onClick={() => setCurrentView('ISOMETRIC')}>ISOMETRIC</button>
                </div>

                {/* Slider to control explosion factor */}
                <label htmlFor="explosionFactor">Explosion Factor: {explosionFactor.toFixed(4)}</label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* Minus button */}
                    <button onClick={decrementExplosionFactor} style={{ marginRight: '10px' }}>
                        -
                    </button>
                    <input
                        type="range"
                        id="explosionFactor"
                        min="0.0000"
                        max="1"
                        step="0.0002"
                        value={explosionFactor}
                        onChange={handleSliderChange}
                        style={{ width: '300px' }}
                    />
                    {/* Plus button */}
                    <button onClick={incrementExplosionFactor} style={{ marginLeft: '10px' }}>
                        +
                    </button>
                </div>
                {/* Reset button */}
                <button onClick={handleResetClick} style={{ marginTop: '10px' }}>
                    Reset
                </button>
            </div>
        </div>
    );
}
