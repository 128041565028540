
import React, { useState } from 'react'
import CloseIcon from "@mui/icons-material/Close";
import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL, HEADERS } from '../../constants/config';
// import { BASE_URL } from '../../../constants/config';


function DeletePartPopUp({ part_number, onclose, sequence_id, action, parent_part_number, type, setUpdatedData,setAction }) {
    console.log(part_number, sequence_id, action, parent_part_number, type)
    const handleDelete = async () => {
        try {
            let url, actioType, sequenceId;
            if (type === 'ebom') {
                url = BASE_URL + "/v1/structure-ec/update-ebom";
                actioType = 'action';
                sequenceId = 'ec_sequence_id';
            } else if (type === 'mbom') {
                url = BASE_URL + "/v1/manufac-bom/update-mbom";
                actioType = 'type';
                sequenceId = 'sequence_id';
            } else if (type === 'parts') {
                url = BASE_URL + "/v1/part-nomen/update-child-parts";
                actioType = 'action';
                sequenceId = 'root_part_number';
            }

            const response = await axios.post(url, {
                [sequenceId]: sequence_id,
                [actioType]: action,
                part_number,
                parent_part_number,
               
            }, {
                headers: HEADERS
            });

            console.log(response); // Check what the response is
            setUpdatedData(part_number)
            setAction(false)
            onclose()

        } catch (error) {
            console.error("Error:", error); // Log the error for debugging
            if (error.response) {
                console.error("API Response Error:", error.response.data);
            }
        }
    };


    const handleClose = () => {
        onclose()
    }
    return (
        <div className='default-popup'>
            <div className='default-popup-container'>
                <div className='closing-div'>
                    <div className='default-closing' onClick={handleClose}>
                        <CloseIcon />
                    </div>
                </div>

                <div className='default-message'>
                    <span>Would you like to remove the <span style={{ color: '#610bee' }}>
                        {part_number}</span> from the {type === 'ebom' && 'EBOM'}{type === 'mbom' && 'MBOM'}
                        {type === 'parts' && 'BOM'} ?</span>


                </div>
                <div className='default-btns'>
                    <button onClick={handleClose}>No</button>
                    <button onClick={handleDelete} style={{ background: '#610bee', color: 'white' }}>Yes</button>
                </div>
            </div>
        </div>
    )
}

export default DeletePartPopUp