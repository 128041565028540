import React, { useState, useEffect } from "react";
import '../DepartmentPages/DepartmentForm.css'
import axios from "axios";
import Cookies from "js-cookie";
import Select from "react-select";
import {
    ASSET_PREFIX_URL,
    BASE_URL,
    BUCKET,
    CATEGORY_UPLOAD_DESIGNS, HEADERS
} from "../../constants/config";
import { simpleUpload, multiUpload } from "../../constants/helper"
import PopupCloseButton from "../Common/PopupCloseButton/PopupCloseButton";
import NameProfile from "../Common/CommonJsx/NameProfile";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AttachmentsFunction from "../Common/CommonJsx/AttachmentsFunction";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';



function CreateDesignPopUp({ onClose, workspace_sequence_id }) {
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [optionalUploadedFiles, setUploadedOptionalFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isOptionalLoading, setOptionalIsLoading] = useState(false);
    const [uploadError, setUploadError] = useState('');
    const [uploadOptionalError, setOptionalUploadError] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [fieldErrors, setFieldErrors] = useState({});
    const allowedExtensionsStp = ['stp', 'step']; // Add your allowed extensions
    const revisionOptions = [
        { value: "major", label: "Major" },
        { value: "minor", label: "Minor" },
    ];
    const nav = useNavigate();

    const handleFileDrop = async (event) => {
        event.preventDefault();
        // setDragging(false);

        const files = event.dataTransfer.files;
        if (files.length > 0) {
            const file = files[0];
            handleFile(file);
        }
    };
    const handleDragOver = (event) => {
        event.preventDefault();
        // setDragging(true);
    };
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        handleFile(file);
    };

    const upload = () => {
        document.getElementById("fileupld_stp").click();
    };
    const handleFile = async (file) => {
        setUploadError('');
        const fileSizeMB = file.size / (1024 * 1024);  // size in MB
        const fileExtension = file.name.split('.').pop().toLowerCase(); // Get the file extension
        if (fileSizeMB > 5120) {
            setUploadError('File size cannot be more than 5 GB');  // Notify the user
            return;  // Exit the function
        } else if (!allowedExtensionsStp.includes(fileExtension)) {
            setUploadError('Only step (.stp, .step) files are allowed');  // Notify the user
            return;
        }
        //fetch pre signed URL
        setIsLoading(true);
        try {
            const preSignedURL = await axios.post(
                `${BASE_URL}/v1/member/get-presigned-url`,
                { bucket_name: BUCKET, file: file.name, category: CATEGORY_UPLOAD_DESIGNS, filesize: fileSizeMB },
                { headers: HEADERS }
            );
            if (preSignedURL.data.meta.code === 200 && preSignedURL.data.meta.message === 'SUCCESS' && preSignedURL.data.data.url) {
                //upload to s3
                // await axios.put(preSignedURL.data.data.url, file, {
                //     headers: {
                //         'Content-Type': file.type
                //     }
                // });
                if (preSignedURL.data.data.is_mutipart) {
                    await multiUpload(preSignedURL.data.data, file, HEADERS);
                } else {
                    await simpleUpload(preSignedURL.data.data, file);
                }

                setUploadedFiles(prevUploadedFiles => [...prevUploadedFiles, { name: file.name, key: preSignedURL.data.data.key }]);
            } else {
                alert('Error generating signed URL')
            }
        } catch (e) {

            setUploadError('Error in uploading file');
        } finally {
            // Set isLoading back to false after upload (whether success or failure)
            setIsLoading(false);
        }
    }
    const handleOptionalFileDrop = async (event) => {
        event.preventDefault();
        // setDragging(false);

        const files = event.dataTransfer.files;
        if (files.length > 0) {
            const file = files[0];
            handleOptionalFile(file);
        }
    };
    const handleOptionalDragOver = (event) => {
        event.preventDefault();
        // setDragging(true);
    };
    const handleOptionalFileUpload = (event) => {
        const file = event.target.files[0];
        handleOptionalFile(file);
    };

    const uploadOptional = () => {
        document.getElementById("fileupld_supporting").click();
    };
    const handleOptionalFile = async (file) => {
        setOptionalUploadError('');
        const fileSizeMB = file.size / (1024 * 1024);  // size in MB
        const fileExtension = file.name.split('.').pop().toLowerCase(); // Get the file extension
        if (fileSizeMB > 1024) {
            setOptionalUploadError('File size cannot be more than 1 GB');  // Notify the user
            return;  // Exit the function
        }
        //fetch pre signed URL
        setOptionalIsLoading(true);
        try {
            const preSignedURL = await axios.post(
                `${BASE_URL}/v1/member/get-presigned-url`,
                { bucket_name: BUCKET, file: file.name, category: CATEGORY_UPLOAD_DESIGNS, filesize: fileSizeMB },
                { headers: HEADERS }
            );
            if (preSignedURL.data.meta.code === 200 && preSignedURL.data.meta.message === 'SUCCESS' && preSignedURL.data.data.url) {
                //upload to s3
                // await axios.put(preSignedURL.data.data.url, file, {
                //     headers: {
                //         'Content-Type': file.type
                //     }
                // });
                if (preSignedURL.data.data.is_mutipart) {
                    await multiUpload(preSignedURL.data.data, file, HEADERS);
                } else {
                    await simpleUpload(preSignedURL.data.data, file);
                }

                setUploadedOptionalFiles(prevUploadedFiles => [...prevUploadedFiles, { name: file.name, key: preSignedURL.data.data.key }]);
            } else {
                alert('Error generating signed URL')
            }
        } catch (e) {

            setOptionalUploadError('Error in uploading file');
        } finally {
            // Set isLoading back to false after upload (whether success or failure)
            setOptionalIsLoading(false);
        }
    }

    const validateForm = () => {
        const errors = {};

        // 1. Validate Workspace Name (No special characters)
        const nameRegex = /^[\S]+$/;
        if (!name) {
            errors.name = "Name is required.";
        } else if (!nameRegex.test(name)) {
            errors.name = "Name cannot contain special characters.";
        }
        console.log(uploadedFiles, 'uploadedFiles')
        if (uploadedFiles.length === 0) {
            setUploadError("At least one file should be uploaded.");
        }

        setFieldErrors(errors);
        return Object.keys(errors).length === 0 && uploadError.length == 0;
    };

    const submitForm = async (e) => {
        e.preventDefault(); // Prevent page reload on form submission
        setFieldErrors({});
        setUploadError('');
        if (!validateForm()) return; // Stop if validation fails design_files, supporting_files 
        const formData = {
            name,
            description,
            design_files: uploadedFiles,
            supporting_files: optionalUploadedFiles,
            workspace_sequence_id
        };

        try {
            const response = await axios.post(`${BASE_URL}/v1/pdm/add-design`, formData, {
                headers: HEADERS,
            });
            console.log("Form submitted successfully:", response.data);
            if (!response.data?.meta?.success) {
                toast(response.data?.meta.message);
            } else {
                onClose();
            }
            // navigate to details page
            // nav(WORKSPACE(response.data?.data.sequence_id))
        } catch (error) {
            console.error("Error submitting form:", error);
        }

    }

    return (
        <>
            <div className='create-part-number-popup-page' >
                <div className='create-part-num-div' >
                    <div className='create-part-num-div-top'>
                        <span>Add design</span>
                        <PopupCloseButton handleClose={onClose} />
                    </div>
                    <div className='create-part-num-div-form' style={{ overflowY: 'scroll' }}>
                        <div className="create-part-num-div-form-dynamic">
                            <div className="create-part-num-div-form-label-input">
                                <span>Name*</span>
                                <div className="create-part-num-div-form-input">
                                    <input type="text"
                                        placeholder="Name"
                                        value={name}  // Bind input value to state
                                        onChange={(e) => setName(e.target.value)}  // Update state on input change
                                    />
                                </div>
                                {fieldErrors.name && (
                                    <span className="error-message">
                                        <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                        {fieldErrors.name}
                                    </span>
                                )}
                            </div>
                            <div className="create-part-num-div-form-label-input">
                                <span>Description</span>
                                <div className="create-part-num-div-form-input">
                                    <textarea
                                        type="text"
                                        placeholder="Describe this Design (optional)"
                                        value={description}  // Bind textarea value to state
                                        onChange={(e) => setDescription(e.target.value)}  // Update state on input change
                                    />
                                </div>
                                {fieldErrors.description && (
                                    <span className="error-message">
                                        <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                        {fieldErrors.description}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div>
                            <div className="create-part-num-div-form-label-attahment">
                                <span>ADD .stp or .step file*</span>
                                <div
                                    onDrop={handleFileDrop}
                                    onDragOver={handleDragOver}
                                    className="filedropzone"
                                >
                                    <img src={`${ASSET_PREFIX_URL}uploading-icon.svg`} alt="" />
                                    {isLoading ? <p>Uploading ....</p> :
                                        <p>Drop files to attach or<span onClick={upload}> Browse</span></p>}

                                </div>
                                <input
                                    type="file"
                                    id="fileupld_stp"
                                    style={{ display: "none" }}
                                    onChange={handleFileUpload}
                                    className="btn-upload"
                                    accept=".step, .stp, .STEP"
                                />
                                {uploadError && <div className="error"><span className="error-message"
                                    style={{ color: '#FF9845' }}><ErrorOutlineOutlinedIcon /> {uploadError}</span></div>}
                            </div>
                            <AttachmentsFunction uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} type='form-upload' />
                        </div>
                        <div>
                            <div className="create-part-num-div-form-label-attahment">
                                <span>Add Files (Drawings, Documents, and Spreadsheets) </span>
                                <div
                                    onDrop={handleOptionalFileDrop}
                                    onDragOver={handleOptionalDragOver}
                                    className="filedropzone"
                                >
                                    <img src={`${ASSET_PREFIX_URL}uploading-icon.svg`} alt="" />
                                    {isOptionalLoading ? <p>Uploading ....</p> :
                                        <p>Drop files to attach or<span onClick={uploadOptional}> Browse</span></p>}

                                </div>
                                <input
                                    type="file"
                                    id="fileupld_supporting"
                                    style={{ display: "none" }}
                                    onChange={handleOptionalFileUpload}
                                    className="btn-upload"
                                    multiple
                                />
                                {uploadOptionalError && <div className="error"><span className="error-message"
                                    style={{ color: '#FF9845' }}><ErrorOutlineOutlinedIcon /> {uploadOptionalError}</span></div>}
                            </div>
                            <AttachmentsFunction uploadedFiles={optionalUploadedFiles} setUploadedFiles={setUploadedOptionalFiles} type='form-upload' />
                        </div>

                        {/* {!uploadError && <div className="error">{uploadError}</div>} */}

                    </div>
                    <div className='create-part-num-div-btns'>
                        <button onClick={submitForm} >Add</button>
                    </div>
                </div>

            </div>
        </>

    );

}
export default CreateDesignPopUp;
