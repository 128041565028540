import React, { useState } from "react";
import "./UserPage.css";
// import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Select from "react-select";
import { BASE_URL } from "../../../constants/config";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import NameProfile from "../../Common/CommonJsx/NameProfile";


const options = [
  { value: "User", label: "User" },
  { value: "Admin", label: "Admin" },
];
function Members({ toastStyle, membersData, admin, fetchMembers }) {
  // const [selectedOption, setSelectedOption] = useState(options[0]);
  // const [openDelete, setOpenDelete] = useState(null);
  // const [membersData, setMembersData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  // const [selfEditPopUp,setSelfEditPopUp]=useState(false)
  const [selfEditMessage,setSelfEditMessage]=useState('')

  // const handleOptionChange = (selected) => {
  //   setSelectedOption(selected);
  // };

  // const handleDeleteShow = (item) => {
  //   if (openDelete === item) {
  //     setOpenDelete(null); // Close delete button if it's already open
  //   } else {
  //     setOpenDelete(item); // Open delete button for the clicked member
  //   }
  // };
  // useEffect(() => {
  //   fetchMembers();
  // }, []);

  // const fetchMembers = async () => {
  //   try {
  //     const headers = {
  //       "x-auth-token": localStorage.getItem("token"),
  //     };
  //     const response = await axios.get(BASE_URL + "/v1/setting/get-mem", {
  //       headers: headers,
  //     });
  //     setMembersData(response.data.data.memberList);
  //     setAdmin(response.data.data.is_admin)

  //   } catch (error) {
  //     console.error("Error fetching options data:", error);
  //   }
  // };

  const itemsPerPage = 10;

  const handleNextPage = () => {
    if (currentPage < Math.ceil(membersData.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '105px',
      backgroundColor: 'transparent',
      border: 'none', // Remove the border
      boxShadow: 'none', // Remove any box shadow
    }),

    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none', // Hide the line (border)
    }),

    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? 'transparent' : 'white', // Set the background color
      color: state.isSelected ? 'black' : 'inherit', // Set the text color
      cursor: 'pointer', // Add cursor pointer on hover
      ':hover': {
        background: 'lightgray', // Change the background color on hover
      },
    }),

    // You can define styles for other parts of the Select component here
  };


  const handleChangeAdmin = async (item, selected) => {

    // const data = selected.value === "Admin" ? true : false;

    try {
      const headers = {
        "x-auth-token": localStorage.getItem("token"),
      };
      const data = selected.value === "Admin" ? true : false;
      const response = await axios.post(
        BASE_URL + "/v1/setting/change-designation",
        { member_id: item._id, type: data },
        { headers }
      );
      console.log(response.data.meta.message)
      if(response.data.meta.message !== 'SUCCESS'){
        setSelfEditMessage(response.data.meta.message)
        // setSelfEditPopUp(true)
        // alert(response.data.meta.message)
      }else{
        toast.info(`${item.fullName} has been changed to ${selected.value}`, toastStyle);
        fetchMembers()
      }
      
      

    } catch (error) {
      console.log(error);
    }
  }

  // const handleClose = () =>{
  //   setSelfEditPopUp(!selfEditPopUp)
  // }
  return (
    <>
    <div className="member-details" style={{width:'100%',alignItems:'center',justifyContent:'center'}}>
    <span className="error-message">{selfEditMessage}</span>
    </div>
      
      <div className="member-page-home">
      <div className="member-page">
        {membersData
          .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
          .map((item, i) => (
            <div className="member-details" key={i} >
              <div className="member-profile">
               
                      
                      <NameProfile userName={item.fullName}  memberPhoto={item.photo} width="26px"  fontweight='500' />
                   
                &nbsp;
                <span>{item.fullName}</span>
              </div>
              <div className="member-email">
                <span>{item.email}</span>
              </div>
              <div className="select-admins">
                {
                  admin ? <Select
                    options={options}
                    value={{
                      value: item.is_admin ? "admin" : "user",
                      label: item.is_admin ? "Admin" : "User", // You can customize the labels as needed
                    }}
                    onChange={(selected) => handleChangeAdmin(item, selected)}
                    styles={customStyles}

                  /> : <span style={{ color: "black" }}>
                    {item.is_admin ? "admin" : "user"}
                  </span>
                }
                {/* <span style={{ color: "black" }}>
                  {item.is_admin ? "admin" : "user"}
                </span> */}



              </div>
              {/* <div className="dot-menu">
                <img
                  src={`${ASSET_PREFIX_URL}dots-menu.svg`}
                  onClick={() => handleDeleteShow(i)}
                  style={{ cursor: "pointer" }}
                />
                {openDelete === i && (
                  <button className="member-delete-btn">
                    <DeleteOutlineOutlinedIcon />
                    <span>Remove</span>
                  </button>
                )}
              </div> */}
            </div>
          ))}

        <div className="pagination">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className="prev-button"
          >
            <KeyboardBackspaceIcon />
            prev
          </button>
          {Array.from(
            { length: Math.ceil(membersData.length / itemsPerPage) },
            (_, index) => (
              <button
                key={index}
                onClick={() => setCurrentPage(index + 1)}
                className={`pagination-button ${currentPage === index + 1 ? "active" : ""
                  }`}
              >
                {"0" + (index + 1)}
              </button>
            )
          )}
          <button
            onClick={handleNextPage}
            disabled={
              currentPage === Math.ceil(membersData.length / itemsPerPage)
            }
            className="next-button"
          >
            next
            <KeyboardBackspaceIcon style={{ transform: "rotate(180deg)" }} />
          </button>
        </div>
      </div>
    </div>
    {/* {selfEditPopUp && <NoTemplatePopUp selfEditMessage={selfEditMessage} onclose={handleClose}/>} */}
    </>
  
  );
}

export default Members;
